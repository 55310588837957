import React from 'react';
import styles from './SkinResume.module.scss';
import { SkinData } from './components/SkinData/SkinData';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';

export const SkinResume: React.FC = () => {
    const [user] = useAtom(userAtom);

    return (
        <div className={styles.desktopContainer}>
            <HomepageNavbar />

            <div className={styles.topSection}>
                <div className={styles.menuTopSection}>
                    {/* PLACE HOLDER DONT REMOVE */}
                </div>
                <div className={styles.contentTopSection}>
                    <h3 className={styles.userName}>{user?.name}</h3>
                    <h3 className={styles.userName}>{user?.lastName}</h3>
                </div>
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.menuSection}>
                    <ProfileMenu />
                </div>
                <div className={styles.contentSection}>
                    <SkinData />
                </div>
            </div>
        </div>
    );


};
