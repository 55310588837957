import React from "react";
import styles from "./OrderDisplay.module.scss";
import { IOrder } from "types/TInterfaces";
import { useNavigate } from "react-router-dom";

interface OrderDisplayProps {
    order: IOrder;
}

export const OrderDisplay: React.FC<OrderDisplayProps> = ({ order }) => {
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;

    const onViewClick = (orderId: string) => {
        navigate(`/profile/my-orders/${orderId}`);
    };

    const mobileLayout = (
        <div className={styles.orderContainer}>
            {/* Product Images Section */}
            <div className={styles.productImages}>
                {order.products.map((product, index) => (
                    <img
                        key={index}
                        src={product.product?.imgUrl}
                        alt={product.product?.title}
                        className={styles.productImage}
                    />
                ))}
            </div>

            {/* Order Info Section */}
            <div className={styles.orderInfo}>
                <div className={styles.infoLeft}>
                    <span className={styles.label} style={{ color: 'black' }}>
                        {order.statusHistory && order.statusHistory.length > 0
                            ? order.statusHistory[order.statusHistory.length - 1].status
                            : 'No Status'} |
                    </span>
                    <span className={styles.label} style={{ marginLeft: '5px' }}>
                        {order.products.length} items
                    </span>
                </div>
                <div className={styles.infoRight}>
                    <span className={styles.label}>
                        {new Intl.DateTimeFormat('en-GB').format(new Date(order.createdAt))}
                    </span>
                    <div className={styles.priceAndButton}>
                        <span className={styles.label}>€{order.totalPrice}</span>
                        <button
                            className={styles.viewBtn}
                            onClick={() => onViewClick(order.logisticOrderId as string)}
                        >
                            View
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

    const desktopLayout = (
        <div className={styles.orderContainer}>
            {/* Order Info Section */}
            <div className={styles.orderInfo}>
                <div className={styles.infoItem}>
                    <span className={styles.label} style={{ color: 'black' }}>
                        {order.statusHistory && order.statusHistory.length > 0
                            ? order.statusHistory[order.statusHistory.length - 1].status
                            : 'No Status'} |
                    </span>
                    <span className={styles.label} style={{ marginLeft: '5px' }}>
                        {order.products.length} items
                    </span>
                </div>
                <div className={styles.infoItem}>
                    <div className={styles.infoItem}>
                        <span className={styles.label}>
                            {new Intl.DateTimeFormat('en-GB').format(new Date(order.createdAt))}
                        </span>
                    </div>
                </div>
                <div className={styles.infoItem}>
                    <span className={styles.label} style={{ marginLeft: 'auto' }}></span> €{order.totalPrice}
                </div>
                <div className={styles.infoItem}>
                    <button
                        className={styles.viewBtn}
                        onClick={() => onViewClick(order.logisticOrderId as string)}
                    >
                        View
                    </button>
                </div>
            </div>

            {/* Product Images Section */}
            <div className={styles.productImages}>
                {order.products.map((product, index) => (
                    <img
                        key={index}
                        src={product.product?.imgUrl}
                        alt={product.product?.title}
                        className={styles.productImage}
                    />
                ))}
            </div>
        </div>
    );
    return isMobile ? mobileLayout : desktopLayout;

};
