import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';
import { FreeMode } from 'swiper/modules';
import './activeRelatedProducts.scss';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';

interface ActiveRelatedProductsProps {
    relatedProducts: Array<{ _id: string; imgUrl?: string; title?: string; brand?: string }>;
}

export const ActiveRelatedProducts: React.FC<ActiveRelatedProductsProps> = ({
    relatedProducts,
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [activeSlide, setActiveSlide] = useState(1);
    const productsWithImage = relatedProducts.filter(product => product.imgUrl);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSlideChange = (swiper: any) => {
        setActiveSlide(swiper.activeIndex + 1);
    };

    const handleNumberClick = (index: number) => {
        const swiperElement = document.querySelector('.related-products-swiper') as any;
        if (swiperElement?.swiper) {
            swiperElement.swiper.slideTo(index - 1);
        }
    };

    return (
        <div className="active-related-products">
            <Swiper
                spaceBetween={10}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 10
                    }
                }}
                loop={true}
                modules={[FreeMode]}
                className="related-products-swiper"
                onSlideChange={handleSlideChange}
            >
                {productsWithImage.map((item, index) => (
                    <SwiperSlide key={index} className="related-product-slide">
                        <div className="related-product-card">
                            <Link to={`/product/${item._id}`} className="product-link">
                                <div className="image-container">
                                    <img
                                        src={item.imgUrl}
                                        alt={item.title}
                                        className="product-image"
                                    />
                                </div>
                            </Link>
                            <div className="product-info">
                                <p className="product-brand">{item.brand}</p>
                                <p className="product-title">{item.title}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {isMobile && (
                <div className="mobile-indicator">
                    <ScrollNumberIndicator
                        count={productsWithImage.length}
                        activeNumber={activeSlide}
                        fontSize={12}
                        color="black"
                        onNumberClick={handleNumberClick}
                    />
                </div>
            )}
        </div>
    );
};




