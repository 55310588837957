import React, { useState } from 'react';
import userService from '../../../../../service/UserService';
import { userAtom } from '../../../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from 'pages/ProfilePage/ProfileMenu/ProfileMenu';
import styles from './ChangePassword.module.scss';

export const ChangePassword: React.FC = () => {
    const isMobile = window.innerWidth < 768;
    const [user, setUser] = useAtom(userAtom);
    const [changeClicked, setChangeClicked] = useState(false);
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [showPasswords, setShowPasswords] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });
    const [error, setError] = useState<string | null>(null);
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswordData((prev) => ({
            ...prev,
            [name]: value,
        }));

        if (name === 'newPassword') {
            const newPasswordErrors = validatePasswordStrength(value);
            setPasswordErrors(newPasswordErrors);
        }
    };

    const togglePasswordVisibility = (field: 'currentPassword' | 'newPassword' | 'confirmPassword') => {
        setShowPasswords((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const isFormValid = () => {
        return (
            passwordData.currentPassword &&
            passwordData.newPassword &&
            passwordData.confirmPassword
        );
    };

    const clearFields = () => {
        setPasswordData({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
        setPasswordErrors([]);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleForgotPassword = () => {
        navigate('/login/forgot-password');
    }

    const validatePasswordStrength = (password: string) => {
        const errors: string[] = [];
        if (password.length < 8) errors.push("Minimum 8 characters.");
        if (!/[A-Z]/.test(password)) errors.push("At least one uppercase letter.");
        if (!/[a-z]/.test(password)) errors.push("At least one lowercase letter.");
        if (!/[0-9]/.test(password)) errors.push("At least one number.");
        return errors;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!user) {
            setError('User not found. Please log in again.');
            return;
        }

        setPasswordErrors([]);

        const newPasswordErrors = validatePasswordStrength(passwordData.newPassword);
        setPasswordErrors((prev) => [...prev, ...newPasswordErrors]);

        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setPasswordErrors((prev) => [...prev, "New password and confirmation do not match."]);
        }

        if (newPasswordErrors.length > 0 || passwordData.newPassword !== passwordData.confirmPassword) {
            return;
        }

        if (isFormValid()) {
            const result = await userService.modifyPassword(
                user,
                setUser,
                passwordData.currentPassword,
                passwordData.newPassword
            );

            if (result.success) {
                clearFields();
                navigate(-1);
            } else {
                setError(result.message);
            }
        } else {
            setError('Please fill all fields and make sure new passwords match.');
        }
    };

    const handleChangeClick = () => {
        setChangeClicked(true);
    }

    const handleCancel = () => {
        navigate('/profile')
    }

    const mobileLayout = (
        <div className={styles.mobileContainer}>
            <HomepageNavbar />

            {changeClicked ? (
                <>
                    <div className={styles.mobileTitlesSection}>
                        <h3 className={styles.mobileTitle}>Secutrity</h3>
                        <h3 className={styles.mobileSubTitle}>Modify password</h3>
                    </div>
                    <form className={styles.passwordForm} onSubmit={handleSubmit}>
                        <div className={styles.passwordField}>
                            <input
                                type={showPasswords.currentPassword ? 'text' : 'password'}
                                name="currentPassword"
                                placeholder="CURRENT PASSWORD"
                                value={passwordData.currentPassword}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className={styles.toggleVisibility}
                                onClick={() => togglePasswordVisibility('currentPassword')}
                            >
                                {showPasswords.currentPassword ? 'HIDE' : 'SEE'}
                            </button>
                        </div>
                        <div className={styles.passwordField}>
                            <input
                                type={showPasswords.newPassword ? 'text' : 'password'}
                                name="newPassword"
                                placeholder="NEW PASSWORD"
                                value={passwordData.newPassword}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className={styles.toggleVisibility}
                                onClick={() => togglePasswordVisibility('newPassword')}
                            >
                                {showPasswords.newPassword ? 'HIDE' : 'SEE'}
                            </button>
                        </div>
                        <div className={styles.passwordField}>
                            <input
                                type={showPasswords.confirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                placeholder="CONFIRM NEW PASSWORD"
                                value={passwordData.confirmPassword}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className={styles.toggleVisibility}
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                            >
                                {showPasswords.confirmPassword ? 'HIDE' : 'SEE'}
                            </button>
                        </div>
                        {passwordErrors.length > 0 && (
                            <div className={styles.errorMessage}>
                                {passwordErrors.map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))}
                            </div>
                        )}
                        <div className={styles.passwordAdvice}>
                            <h5>ADVICES</h5>
                            <span>MIN. 8 CHARACTERS</span>
                            <span>ONE UPPERCASE LETTER</span>
                            <span>ONE LOWERCASE LETTER</span>
                            <span>ONE NUMBER</span>
                        </div>
                        {error && <div className={styles.errorMessage}>{error}</div>}
                        <div className={styles.btnsContainer}>
                            <button
                                type="submit"
                                className={styles.submitButton}
                                disabled={!isFormValid()}
                            >
                                SAVE PASSWORD
                            </button>
                            <button type="button" className={styles.cancelBtn} onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <>
                    <div className={styles.mobileHeaderSection}>
                        <h3 className={styles.mobileTitle}>Secutrity</h3>
                        <button className={styles.mobileChangeBtn} onClick={handleChangeClick}>
                            Change
                        </button>
                    </div>

                    <div>
                        <h3 className={styles.mobileSubTitle}>Password</h3>
                        <p className={styles.mobileText}>********************</p>
                    </div>
                    <div className={styles.mobileForgotPasswordContainer}>
                        <button className={styles.forgotPasswordBtn} onClick={handleForgotPassword}>Forgot your password ?</button>
                    </div>
                </>
            )}
        </div>
    );

    const desktopLayout = (
        <div className={styles.desktopContainer}>
            <HomepageNavbar />

            <div className={styles.topSection}>
                <div className={styles.menuTopSection}>
                    {/* PLACE HOLDER DONT REMOVE */}
                </div>
                <div className={styles.contentTopSection}>
                    <h3 className={styles.userName}>{user?.name}</h3>
                    <h3 className={styles.userName}>{user?.lastName}</h3>
                </div>
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.menuSection}>
                    <ProfileMenu />
                </div>
                <div className={styles.contentSection}>
                    <button className={styles.passwordBackButton} onClick={handleBack}>{'< BACK'}</button>

                    <div className={styles.formHeader}>
                        <h5>MODIFY PASSWORD</h5>
                        <button className={styles.forgotPasswordBtn} onClick={handleForgotPassword}>Forgot your password ?</button>
                    </div>
                    <form className={styles.passwordForm} onSubmit={handleSubmit}>
                        <div className={styles.passwordField}>
                            <input
                                type={showPasswords.currentPassword ? 'text' : 'password'}
                                name="currentPassword"
                                placeholder="CURRENT PASSWORD"
                                value={passwordData.currentPassword}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className={styles.toggleVisibility}
                                onClick={() => togglePasswordVisibility('currentPassword')}
                            >
                                {showPasswords.currentPassword ? 'HIDE' : 'SEE'}
                            </button>
                        </div>
                        <div className={styles.passwordField}>
                            <input
                                type={showPasswords.newPassword ? 'text' : 'password'}
                                name="newPassword"
                                placeholder="NEW PASSWORD"
                                value={passwordData.newPassword}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className={styles.toggleVisibility}
                                onClick={() => togglePasswordVisibility('newPassword')}
                            >
                                {showPasswords.newPassword ? 'HIDE' : 'SEE'}
                            </button>
                        </div>
                        <div className={styles.passwordField}>
                            <input
                                type={showPasswords.confirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                placeholder="CONFIRM NEW PASSWORD"
                                value={passwordData.confirmPassword}
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className={styles.toggleVisibility}
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                            >
                                {showPasswords.confirmPassword ? 'HIDE' : 'SEE'}
                            </button>
                        </div>
                        {passwordErrors.length > 0 && (
                            <div className={styles.errorMessage}>
                                {passwordErrors.map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))}
                            </div>
                        )}
                        <div className={styles.passwordAdvice}>
                            <h5>ADVICES</h5>
                            <span>MIN. 8 CHARACTERS</span>
                            <span>ONE UPPERCASE LETTER</span>
                            <span>ONE LOWERCASE LETTER</span>
                            <span>ONE NUMBER</span>
                        </div>
                        {error && <div className={styles.errorMessage}>{error}</div>}
                        <button
                            type="submit"
                            className={styles.submitButton}
                            disabled={!isFormValid()}
                        >
                            SAVE PASSWORD
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );

    return isMobile ? mobileLayout : desktopLayout;
};
