import React from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../../../config';
import styles from './ContactBox.module.scss';

export const ContactBox: React.FC = () => {
    const navigate = useNavigate();

    const handleEmail = (subject: string) => {
        const email = config.REACT_APP_SUPPORT_EMAIL;
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
        window.location.href = mailtoLink;
    };

    const handleRead = () => {
        navigate('/profile/settings/terms-of-use')
    }

    return (
        <div className={styles.contactBox}>
            <div className={styles.contentContainer}>
                <div className={styles.contactItem}>
                    <h5 className={styles.itemTitle}>TERMS & CONDITIONS</h5>
                    <button className={styles.btnAction} onClick={handleRead}>READ</button>
                </div>
                <div className={styles.dividerInBox} />

                <div className={styles.contactItem}>
                    <h5 className={styles.itemTitle}>HELP & SUPPORT</h5>
                    <button className={styles.btnAction} onClick={() => handleEmail("Help & Support")}>
                        CONTACT US
                    </button>
                </div>
                <div className={styles.dividerInBox} />

                <div className={styles.contactItem}>
                    <h5 className={styles.itemTitle}>REPORT A BUG</h5>
                    <button className={styles.btnAction} onClick={() => handleEmail("Bug Report")}>
                        CONTACT US
                    </button>
                </div>
            </div>
        </div>
    );
};
