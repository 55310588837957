import React from "react";
import styles from "./OrderInfo.module.scss";
import { IOrder } from "types/TInterfaces";
import { ProductLoader } from "pages/ProductDetails/ProductLoader";


interface OrderInfoProps {
    order: IOrder;
}

export const OrderInfo: React.FC<OrderInfoProps> = ({ order }) => {
    const isMobile = window.innerWidth <= 768;

    if (!order) {
        return <ProductLoader />;
    }

    return (
        <div className={styles.orderInfoContainer}>
            <h2 className={styles.title}>NO. {order.orderNo}</h2>

            <div className={styles.orderInfo}>
                <div className={styles.left}>
                    <span className={styles.label}>Deliver to</span>
                    <p>{order.delivery.firstName} {order.delivery.lastName}</p>
                    <p>{order.delivery.streetNumber} {order.delivery.street}</p>
                    <p>{order.delivery.postalCode} {order.delivery.city}, {order.delivery.country}</p>
                </div>

                <div className={styles.middle}>
                    <span className={styles.label}>Payment Method</span>
                    <p>
                        {order.paymentDetails?.last4 && order.paymentDetails?.last4 !== ''
                            ? `${order.paymentDetails?.cardType} ending with ${order.paymentDetails?.last4}`
                            : order.paymentDetails?.cardType}
                    </p>
                </div>

                {!isMobile && (
                    <div className={styles.right}>
                        <span className={styles.label}>{new Intl.DateTimeFormat('en-GB').format(new Date(order.createdAt))}</span>
                    </div>)}
            </div>
            {isMobile && (<span className={styles.mobileDate}>{new Intl.DateTimeFormat('en-GB').format(new Date(order.createdAt))}</span>)}
        </div>
    );
};
