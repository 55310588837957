import React from "react";
import styles from "./OrderTimeline.module.scss";
import blackDot from "../../../../../assets/icons/timelineDotBlack.svg";
import beigeDot from "../../../../../assets/icons/timelineDotBeige.svg";

interface OrderTimelineProps {
    step: string;
}

export const OrderTimeline: React.FC<OrderTimelineProps> = ({ step }) => {
    const getDotImage = (currentStep: string, stepPosition: number) => {
        if (stepPosition <= getStepIndex(currentStep)) {
            return blackDot;
        }
        return beigeDot;
    };

    const getStepIndex = (currentStep: string) => {
        const steps = ["RECEIVED", "PROCESSED", "SHIPPED", "DELIVERED"];
        return steps.indexOf(currentStep);
    };

    const getLineClass = (currentStep: string, linePosition: number) => {
        const stepIndex = getStepIndex(currentStep);

        if (linePosition < stepIndex) {
            return styles.fullBlackLine;
        } else if (linePosition === stepIndex) {
            return styles.firstThirdBlackLine;
        }
        return styles.fullBeigeLine;
    };

    return (
        <div className={styles.timelineContainer}>
            <div className={styles.section}>
                <img src={getDotImage(step, 0)} alt="trackDot" className={styles.image} />
            </div>
            <div className={`${styles.line} ${getLineClass(step, 0)}`}></div>

            <div className={styles.section}>
                <img src={getDotImage(step, 1)} alt="trackDot" className={styles.image} />
            </div>
            <div className={`${styles.line} ${getLineClass(step, 1)}`}></div>

            <div className={styles.section}>
                <img src={getDotImage(step, 2)} alt="trackDot" className={styles.image} />
            </div>
            <div className={`${styles.line} ${getLineClass(step, 2)}`}></div>

            <div className={styles.section}>
                <img src={getDotImage(step, 3)} alt="trackDot" className={styles.image} />
            </div>
        </div>
    );
};
