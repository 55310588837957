import React from 'react';
import './style.scss';
import Slider from 'react-slick';

interface ScrollNumberIndicatorProps {
  count?: number;
  fontSize?: number;
  color?: 'white' | 'black';
  activeNumber?: number;
  direction?: 'horizontal' | 'vertical';
  onNumberClick?: (index: number) => void;
}

export const ScrollNumberIndicator: React.FC<ScrollNumberIndicatorProps> = ({
  count,
  fontSize = 12,
  color = 'white',
  activeNumber = 1,
  direction = 'horizontal',
  onNumberClick,
}) => {
  return (
    <div className={`scrollIndicators ${direction}`}>
      {Array.from({ length: count || 0 }).map((_, index) => (
        <p
          className='scrollNumberIndicator'
          key={index}
          style={{
            fontSize: `${fontSize}px`,
            color: color === 'white' ? '#fff' : '#000',
            filter: index + 1 === activeNumber ? 'none' : 'blur(0.7px)',
            cursor: 'pointer'
          }}
          onClick={() => onNumberClick && onNumberClick(index + 1)}
        >
          {index + 1 < 10 ? `0${index + 1}` : index + 1}
        </p>
      ))}
    </div>
  );
};
