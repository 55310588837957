import './style.scss';
import { useNavigate } from 'react-router-dom';
import HeaderImg from 'assets/textFont/saferIngredients.svg';
import WhiteDot from 'assets/icons/whiteDot.svg';
import Image from 'assets/images/86.jpg';

export const SaferIngredients = () => {
  const navigate = useNavigate();

  const handleShopClick = () => {
    navigate('/shop');
  };

  return (
    <div className="SaferIngredientsContainer">
      <div style={{ paddingLeft: 20, paddingTop: 60 }}>
        <p className="SaferIngredientsheaderDescription">Revolutionize your retail experience</p>
        <p style={{ lineHeight: 1, marginTop: 30 }} className="SaferIngredientsheaderHeader">
          oli not only offers products with
        </p>
        <img src={HeaderImg} alt="Safer Ingredients Header" className="SaferIngredientsHeaderSVG" />
        <div className="containerBottom">
          <button className="SaferIngredientsButton" onClick={handleShopClick}>
            shop products
          </button>
          <div
            className="SaferIngredientsDot"
            style={{
              backgroundImage: `url(${WhiteDot})`,
              marginBottom: 10,
              height: 8.34,
              width: 9.02,
              objectFit: 'contain',
              marginRight: 1,
              marginTop: -1,
            }}
          />
          <p className="SaferIngredientsDescription">
            but also organizes them based on your unique skin profile. You can easily compare recommendations and explore alternatives
          </p>
        </div>
      </div>
      <div className="SaferIngredientsContainerIMG" style={{ backgroundImage: `url(${Image})` }} />
    </div>
  );
};
