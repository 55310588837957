import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import advicesImg from '../../../assets/icons/117.svg'
import Dot from '../../../assets/icons/blackDot.svg';

export const OliAdviceSection = () => {
    const [days] = useState(['S', 'M', 'T', 'W', 'T', 'F', 'S']); // Days of the week
    const [currentDayIndex, setCurrentDayIndex] = useState(new Date().getDay()); // Current day index

    useEffect(() => {
        const currentDay = new Date().getDay(); // getDay() returns 0 for Sunday, 1 for Monday, etc.
        setCurrentDayIndex(currentDay);
    }, []);

    return (
        <div className={styles.oliAdviceSection}>
            <div className={styles.dailycareContainer}>
                <span className={styles.dailycareTitle}>DAILY SKINCARE </span>
            </div>
            <div className={styles.advicesImgContainer}>
                <img src={advicesImg} alt="advicesImg" className={styles.advicesImg} />
            </div>
            <div className={styles.dayContainer}>
                {days.map((day, index) => (
                    <div key={index} className={styles.dayWrapper}>
                        <span className={index === currentDayIndex ? styles.currentDay : styles.normalDay}>
                            {day}
                        </span>
                        {index === currentDayIndex && <img src={Dot} alt="dot" className={styles.dot} />}
                    </div>
                ))}
            </div>
            <div className={styles.textAdviceContainer}>
                <span className={styles.textAdvice}>
                    No matter what your skin needs are, the first
                    non-negotiable step in your routine should be cleansing.                </span>
            </div>
        </div>
    );
};