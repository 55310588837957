import React, { useState, useRef } from 'react';
import styles from './style.module.scss';
import HeaderBackgroundImage from 'assets/images/formBackground.png';
import MainText from 'assets/textFont/novemberFavorite.svg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProductAdvice } from 'shared/elements/ProductAdvice';
import { Link, useLocation } from 'react-router-dom';
import newBrand from '../../../assets/brands/Hyeja/heyja_desktop.png';
import newBrandTitle from '../../../assets/brands/Hyeja/hyeja_title.png';
import newBrandMobile from '../../../assets/brands/Hyeja/hyeja_mobile.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { config } from 'config';

export const ShopHeadSection = () => {
  const isShopPageHeader = useLocation().pathname.includes('/shop');
  const isMobile = window.innerWidth < 768;
  const [activeSlide, setActiveSlide] = useState(1);
  const swiperInstance = useRef<any>(null); // Ref for Swiper instance

  const handleButtonClick = (slideNumber: number) => {
    if (swiperInstance.current) {
      swiperInstance.current.slideTo(slideNumber - 1); // Swiper uses 0-indexed slides
    }
    setActiveSlide(slideNumber);
  };

  return (
    <>
      <HomepageNavbar />

      <Swiper
        onSwiper={(swiper: any) => {
          swiperInstance.current = swiper; // Capture the Swiper instance
        }}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(swiper: any) => setActiveSlide(swiper.activeIndex + 1)}
      >
        <div className={styles.shopHeaderButtonContainer}>
          <button
            className={`${styles.shopHeaderButton} ${activeSlide === 1 ? '' : styles.blurred}`}
            onClick={() => handleButtonClick(1)}
          >
            01
          </button>
          <button
            className={`${styles.shopHeaderButton} ${activeSlide === 2 ? '' : styles.blurred}`}
            onClick={() => handleButtonClick(2)}
          >
            02
          </button>
        </div>
        <SwiperSlide
          style={{
            backgroundImage: `url(${HeaderBackgroundImage})`,
            backgroundSize: 'cover',
          }}
        >
          <div className={isShopPageHeader ? styles.shopHeaderBlockPage : styles.shopHeaderBlock}>
            <div className={styles.shopPageHeaderBlockContent}>
              <div className={styles.shopPageLogoContainer}>
                <p className={styles.shopPageHeaderOlisLab}>Oli's Lab</p>
              </div>
              <div className={styles.shopHeaderCenterBlock}>
                <div className={styles.shopMainText} style={{ backgroundImage: `url(${MainText})` }} />
              </div>
              <div className={styles.shopHeaderRightTextContainer}>
                <p className={styles.shopHeaderRightText}>Discover more</p>
              </div>
              <div className={styles.shopHeaderBottomAreaProductAdvice}>
                <ProductAdvice />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: `url(${isMobile ? newBrandMobile : newBrand})`,
            backgroundSize: 'cover',
          }}
        >
          <div className={isShopPageHeader ? styles.shopHeaderBlockPage : styles.shopHeaderBlock}>
            <div className={styles.brandTitleContainer}>
              <Link to={`/brand/${config.REACT_APP_BRAND_NAME}`}>
                <img src={newBrandTitle} alt="Hyeja" className={styles.brandTitle} />
              </Link>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
