import React from 'react';
import inviteFriendsBg from '../../../../../assets/images/InviteFriendsBg.jpeg'
import styles from './InviteFriends.module.scss'; // Create this file for styling

export const InviteFriends: React.FC = () => {
    return (
        <div className={styles.inviteFriendsContainer}>
            <img src={inviteFriendsBg} alt="Invite Friends" className={styles.inviteImage} />
            <h4 className={styles.inviteTitle}>Invite Your Friends</h4>
            <p className={styles.inviteText}>Lorem ipsum dolores magna dolores magna dolores magna</p>
            <button className={styles.inviteButton}>send invitation</button>
        </div>
    );
};