import React, { useState, useEffect, useCallback, useRef } from 'react';
import './style.scss';
import CountBackground from 'assets/icons/cartCountBackground.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { userAtom } from '../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { handleLogOut } from '../utils/authUtils';
import WhiteLogo from 'assets/icons/whiteLogo.svg';
import Cart from '../../cart/cart';
import { IProductInCart } from '../../../types/TInterfaces';
import { loadLocalCart } from '../../../service/localCartService';
import { cartItemsAtom } from '../../../stores/UserProfile';
import Menu from '../Menu/Menu';

export const HomepageNavbar = () => {
  const [user, setUser] = useAtom(userAtom);
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);
  const [menuVisible, setMenuVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const cartRef = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const isProductPage = location.pathname.includes('/product');
  const isCheckoutPage = location.pathname.includes('/checkout');
  const isLoginPage = location.pathname.includes('/login');
  const isBestsellersPage = location.pathname.includes('/bestsellers');
  const isSignUpPage = location.pathname.includes('/signup');
  const isSignUpForm = location.pathname.includes('/spf-form/');
  const isSpfForm = location.pathname.includes('/spf-form');
  const isFeedPage = location.pathname.includes('/feed');
  const isShopPage = location.pathname.includes('/shop');
  const isProfilePage = location.pathname.includes('/profile');
  const isDossierPage = location.pathname.includes('/feed');
  const isArticlePage = location.pathname.includes('/feed/article');
  const isHomePage = location.pathname === '/';
  const [isAnonymousUser, setIsAnonymousUser] = useState(!user || Object.keys(user).length === 0)
  const [lastScrollPos, setLastScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > lastScrollPos && currentScrollPos > 50) {
        setIsNavbarVisible(false);
      } else if (currentScrollPos < lastScrollPos) {
        setIsNavbarVisible(true);
      }

      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPos]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsAnonymousUser(!user || Object.keys(user).length === 0)
  }, [user])

  useEffect(() => {
    if (user) {
      setCartItems(user.cart || []);
    } else {
      setCartItems(loadLocalCart());
    }
  }, [user, setCartItems]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cartVisible) {
        const isClickOnOverlay = (event.target as Element).classList.contains('cart-overlay');
        if (cartRef.current && !cartRef.current.contains(event.target as Node) || isClickOnOverlay) {
          setCartVisible(false);
        }
      }
      if (menuVisible) {
        const isClickOnOverlay = (event.target as Element).classList.contains('menu-overlay');
        if (menuRef.current && !menuRef.current.contains(event.target as Node) || isClickOnOverlay) {
          setMenuVisible(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible, cartVisible, menuRef, cartRef]);

  const logout = () => handleLogOut(setUser, navigate);
  const toggleCart = () => setCartVisible(!cartVisible);
  const updateCartItems = useCallback((newCartItems: IProductInCart[] | ((prevItems: IProductInCart[]) => IProductInCart[])) => {
    setCartItems(newCartItems);
  }, []);
  const cartCount = cartItems.reduce((total, item) => total + item.quantity, 0);



  return (
    <>
      <div className={`navbar ${isNavbarVisible ? 'visible' : 'hidden'}`}>
        <div
          className="navbar-left-logo"
          style={{ visibility: isHomePage && !isMobile ? 'hidden' : 'visible' }}
        >
          <Link to="/">
            <img className="navbar-logo" src={WhiteLogo} alt="White Logo" />
          </Link>
          {!isLoginPage && !isShopPage && !isProfilePage && !isHomePage && !isSignUpPage && !isBestsellersPage && ((isSpfForm && isSignUpForm) || isProductPage || isCheckoutPage || isDossierPage || isArticlePage || isFeedPage) && (
            <button onClick={() => navigate(-1)} className="back-button-home">
              &lt; BACK
            </button>
          )}
        </div>
        <span onClick={() => setMenuVisible(!menuVisible)} className="navbar-item-menu" style={{ marginRight: "10%" }}>MENU</span>

        <nav className="navbar-right">
          {!isMobile && isHomePage && (
            <Link to={'/shop'} className="navbar-item">SHOP</Link>
          )}

          {user ? (
            !isMobile && !isLoginPage && !isSignUpPage && !isSpfForm && !isCheckoutPage && (
              <Link to="#" onClick={logout} className="navbar-item">LOG OUT</Link>
            )
          ) : (
            !isMobile && !isLoginPage && !isSignUpPage && !isSpfForm && !isCheckoutPage && (
              <Link to={'/login'} className="navbar-item">SIGN UP</Link>
            )
          )}

          {isSignUpForm ? (
            null
          ) : (
            ((isMobile && isCheckoutPage) || (!isCheckoutPage)) && (
              <span onClick={toggleCart} className="navbar-item cart-container">
                CART
                <span className="cart-count" style={{ backgroundImage: `url(${CountBackground})` }}>
                  {cartCount}
                </span>
              </span>
            )
          )}
        </nav>
      </div >

      <div ref={cartRef}>
        <Cart isOpen={cartVisible} onClose={() => setCartVisible(false)} cartItems={cartItems} setCartItems={updateCartItems} />
      </div>

      <div ref={menuRef}>
        <Menu isVisible={menuVisible} toggleVisibility={() => setMenuVisible(!menuVisible)} />
      </div>
    </>
  );
};