// src/routes/Routes.tsx
import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Routes, Route } from 'react-router-dom';
import { Homepage } from '../pages/homepage/homepage';
import { ShopPage } from 'pages/ShopPage/ShopPage';
import { SignUpPage } from 'pages/SignUpPage/SignUpPage';
import { HotBrands } from 'pages/HotBrands/HotBrands';
import { LogInPage } from 'pages/LogInPage/LogInPage';
import { CheckoutPage } from 'pages/checkout/checkout';
import { ShopBestsellers } from 'pages/ShopBestsellers/ShopBestsellers';
import { ProductDetail } from 'pages/ProductDetails/ProductDetails';
import { Feed } from 'pages/Feed/Feed';
import { ArticleDetail } from 'pages/Feed/ArticleDetail/ArticleDetail';
import { DossiersList } from 'pages/Feed/DossiersList/DossiersList';
import { ActivesList } from 'pages/Feed/ActivesList/ActivesList';
import { OneActive } from 'pages/Feed/OneActive/OneActive';
import { SkinTypeFormStack } from '../pages/Form/Form';
import { ProfileSettings } from 'pages/ProfilePage/ProfileSettings/ProfileSettings';
import { AddressForm } from 'pages/ProfilePage/ProfileSettings/components/AddressForm/AddressForm';
import { ChangePassword } from 'pages/ProfilePage/ProfileSettings/components/ChangePassword/ChangePassword';
import { SkinResume } from 'pages/ProfilePage/SkinResume/SkinResume';
import globalService from '../service/GlobalService';
import { Brands } from 'components/brands/brands';
import { userAtom } from 'stores/UserProfile';
import ScrollToTop from 'components/navbars/utils/ScrollToTop';
import { TermsOfUse } from 'pages/ProfilePage/ProfileSettings/components/TermsOfUse/TermsOfUse';
import { AccountForm } from 'pages/ProfilePage/ProfileSettings/components/AccountForm/AccountForm';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import PaymentSuccess from 'pages/checkout/paymentSuccess';
import { ForgetPassword } from 'pages/ForgetPassword/ForgetPassword'
import { ResetPassword } from 'pages/ForgetPassword/ResetPassword'
import { ResetSuccessful } from 'pages/ForgetPassword/ResetSuccessful';
import { MyOrders } from 'pages/ProfilePage/MyOrders/MyOrders';
import { OrderPage } from 'pages/ProfilePage/MyOrders/components/OrderPage/OrderPage';
import useResizeObserver from '../hooks/resizeObserver';
import { ProfileHomePage } from 'pages/ProfilePage/ProfileHomePage/ProfileHomePage';
import { LoginUserHomePage } from '../pages/loginUserHomePage/loginUserHomePage';
import { config } from '../config';
import { ComingSoonPage } from '../pages/ComingSoonPage/ComingSoonPage'
import HomepageLoader from 'components/animation/homepageloader';


const AppRoutes: React.FC = () => {
  const [resizeRef, dimensions] = useResizeObserver();

  const [user, setUser] = useAtom(userAtom);

  useEffect(() => {
    globalService.loadUserFromStorage(setUser);
  }, [setUser]);

  if (config.REACT_APP_COMING_SOON === 'true') {
    return (
      <Routes>
        <Route path="/" element={<ComingSoonPage />} />
      </Routes>
    );
  } else {
    return (
      <div ref={resizeRef} style={{ width: '100%', height: '100%' }}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={user ? <LoginUserHomePage /> : < Homepage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/hot-brands" element={<HotBrands />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LogInPage />} />
          <Route path="login/forgot-password" element={<ForgetPassword />} />
          <Route path="/change_pw/:token" element={<ResetPassword />} />
          <Route path="/reset-done" element={<ResetSuccessful />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/bestsellers" element={<ShopBestsellers />} />
          <Route path="/brand/:brandName" element={<Brands />} />
          <Route path="/product/:productId" element={<ProductDetail />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/*" element={<ErrorPage message="Page not found" />} />
          <Route path="/feed/article/:articleId" element={<ArticleDetail />} />
          <Route path="/feed/dossier/:dossierId" element={<DossiersList />} />
          <Route path="/feed/actives" element={<ActivesList />} />
          <Route path="/feed/actives/:activeId" element={<OneActive />} />
          <Route path="/spf-form/*" element={<SkinTypeFormStack />} />
          <Route path="/profile" element={<ProfileHomePage />} />
          <Route path="/profile/settings" element={<ProfileSettings />} />
          <Route path="/profile/settings/add-address" element={<AddressForm />} />
          <Route path="/profile/settings/edit-account" element={<AccountForm />} />
          <Route path="/profile/settings/add-address/:addressId" element={<AddressForm />} />
          <Route path="/profile/settings/change-password" element={<ChangePassword />} />
          <Route path="/profile/settings/terms-of-use" element={<TermsOfUse />} />
          <Route path="/profile/my-orders" element={<MyOrders />} />
          <Route path="/profile/my-orders/:id" element={<OrderPage />} />
          <Route path="/order/:id" element={<OrderPage />} />
          <Route path="/justdropped" element={<ErrorPage message='Coming soon! This feature is currently in development and will be available shortly' />} />
          <Route path="/recommendations" element={<ErrorPage message="Coming soon! This feature is currently in development and will be available shortly" />} />
          <Route path="/success" element={<PaymentSuccess />} />
        </Routes>
      </div>
    );
  }
};

export default AppRoutes;