import api from './apiConfig';
import globalService from 'service/GlobalService';
import { IAnonymousUser, IUser } from 'types/TInterfaces';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import axios from 'axios';
import { config } from 'config';

function separateStreetNumberAndName(address: string): [string | null, string] {
    const regex = /^(\d+)\s+(.*)$/;
    const match = address.match(regex);

    if (match) {
        const streetNumber = match[1];
        const streetName = match[2];
        return [streetNumber, streetName];
    }

    return [null, address];
}


export default class PaymentsApi {

    static getConfig(token: string) {
        return { headers: { 'Authorization': `Bearer ${token}` } }
    }

    static async getToken() {
        const token = await globalService.getAccessToken()
        if (!token) { console.log('error in token'); return }
        return token
    }

    static async handlePayment(totalPrice: number, user: IUser | IAnonymousUser, checkoutData: any, selectedRelayPoint: any) {
        try {
            const stripe = await loadStripe(config.REACT_APP_STRIPE_SECRET ?? '');

            // if (selectedRelayPoint && typeof selectedRelayPoint === 'object') {
            //     checkoutData.delivery.city = selectedRelayPoint.Ville;
            //     checkoutData.delivery.country = selectedRelayPoint.Pays;
            //     checkoutData.delivery.postalCode = '';
            //     [checkoutData.delivery.streetNumber, checkoutData.delivery.street] = separateStreetNumberAndName(selectedRelayPoint.LgAdr3);
            //     checkoutData.shipping.shippingMethod = 'mondialrelay-relaisl';
            // }

            const body = {
                price: totalPrice,
                checkoutData: checkoutData,
                user: user,
            };

            const response = await axios.post(
                `${config.REACT_APP_API_URL}/payments/create-checkout-session`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const session = response.data;

            if (!session || !session.sessionId) {
                throw new Error('Session ID is missing in the API response');
            }

            const result = await stripe?.redirectToCheckout({ sessionId: session.sessionId });
            if (result?.error) {
                console.error('Stripe redirectToCheckout error:', result.error.message);
                return { success: false, message: result.error.message };
            }

            return { success: true };
        } catch (error: any) {
            console.error('Error handling payment:', error.message);
            return { success: false, message: error.message || 'Unknown error' };
        }
    }
}

