import styles from './style.module.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import authApi from 'service/API/authApi';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';

export const ResetPassword = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [tokenError, setTokenError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const [validationState, setValidationState] = useState({
        minLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasNumber: false,
    });

    useEffect(() => {
        if (token) {
            const verifyToken = async () => {
                try {
                    const response = await authApi.checkIsValidLink(token);

                    if ('success' in response && !response.success) {
                        setTokenError(response.message);
                    } else if ('email' in response && !response.isValid) {
                        setTokenError('Invalid or expired token.');
                    }
                } catch (err: any) {
                    setTokenError('Something went wrong. Please try again.');
                }
            };

            verifyToken();
        } else {
            setTokenError('Token error.');
        }
    }, [token]);


    const resetError = (currentError: string | null, updateError: (value: string | null) => void) => {
        if (currentError) {
            updateError(null);
        }
    };

    const validatePassword = (password: string) => {
        setValidationState({
            minLength: password.length >= 8,
            hasUppercase: /[A-Z]/.test(password),
            hasLowercase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
        });
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        resetError(error, setError);
        validatePassword(newPassword);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        resetError(error, setError);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (token) {
            const { success, message } = await authApi.updateNewPassword(token, password);

            if (success) {
                setError(null);
                navigate('/reset-done');
            } else {
                setError('Something went wrong with reseting your password. Please try again later');
            }
        }

        else {
            setTokenError('Token is missing');
        }
    };


    const inputErrorClass = error ? 'error' : '';

    return (
        <>
            <div className={styles.forgetPasswordHeaderBlock} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
                <HomepageNavbar />
                <div className={styles.forgetPasswordContainer}>
                    <div className={styles.forgetPasswordFormContainer}>
                        <img className={styles.olisLogo} alt="Group" src={blackLogo} />

                        {tokenError ? (
                            <h1 className={styles.forgetPasswordTitle}>SOMETHING WENT WRONG</h1>
                        ) : (
                            <>
                                <h1 className={styles.forgetPasswordTitle}>RESET PASSWORD</h1>
                                <form onSubmit={handleSubmit}>
                                    <div className={styles.forgetPasswordAdvice}>
                                        <h5>ADVICES</h5>
                                        <span className={!validationState.minLength ? styles.errorMessage : styles.adviceMessage}>
                                            MIN. 8 CHARACTERS
                                        </span>
                                        <span className={!validationState.hasUppercase ? styles.errorMessage : styles.adviceMessage}>
                                            ONE UPPERCASE LETTER
                                        </span>
                                        <span className={!validationState.hasLowercase ? styles.errorMessage : styles.adviceMessage}>
                                            ONE LOWERCASE LETTER
                                        </span>
                                        <span className={!validationState.hasNumber ? styles.errorMessage : styles.adviceMessage}>
                                            ONE NUMBER
                                        </span>
                                    </div>
                                    <div className={styles.enterNewPasswordInputGroup}>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            placeholder="NEW PASSWORD"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            className={`${styles.forgetPasswordInput} ${inputErrorClass}`}
                                            required
                                        />
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className={`${styles.forgetPasswordShowBtn} ${inputErrorClass}`}
                                        >
                                            {showPassword ? 'HIDE' : 'SEE'}
                                        </button>
                                    </div>
                                    <div className={styles.confirmNewPasswordInputGroup}>
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            id="confirmPassword"
                                            placeholder="CONFIRM PASSWORD"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            className={`${styles.forgetPasswordInput} ${inputErrorClass}`}
                                            required
                                        />
                                        <button
                                            type="button"
                                            onClick={toggleConfirmPasswordVisibility}
                                            className={`${styles.forgetPasswordShowBtn} ${inputErrorClass}`}
                                        >
                                            {showConfirmPassword ? 'HIDE' : 'SEE'}
                                        </button>

                                    </div>


                                    {error && <div className={styles.errorMessage}>{error}</div>}
                                    <button
                                        type="submit"
                                        className={styles.forgetPasswordButton}
                                        disabled={!password || !confirmPassword || Object.values(validationState).some((isValid) => !isValid)}
                                    >
                                        RESET PASSWORD
                                    </button>
                                </form>
                            </>
                        )}

                        <p className={styles.forgetPasswordText}>
                            Need an account?{' '}
                            <a href="/signup" className={styles.forgetPasswordLink}>
                                Sign up
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
