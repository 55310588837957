import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import userService from 'service/UserService';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import blackLogo from '../../../assets/icons/blackLogo.svg';
import { StepIndicator } from 'components/counter/counter'; // Import the StepIndicator
import styles from '../style.module.scss';
import zero from '../../../assets/numbers/10.png';
import one from '../../../assets/numbers/01.png';
import two from '../../../assets/numbers/02.png';
import three from '../../../assets/numbers/03.png';
import four from '../../../assets/numbers/04.png';

export const SPF_Resume: React.FC = () => {
  const [skinProfile] = useAtom(skinProfileAtom);
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();

  const saveProfile = async () => {
    if (!user || !user._id) {
      console.error('User is not logged in');
      return;
    }

    try {
      user && skinProfile && (await userService.updateUserSPF(user, setUser, skinProfile));
      navigate('/spf-form/spf-analyze');
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const handleEdit = (path: string) => {
    navigate(path);
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <StepIndicator currentStep={5} /> {/* Step 5 */}
          <div className={styles.titleContainer}>
            <h1 className={styles.TitlesSpf}>MY SKIN RESUME</h1>
          </div>
          <div className={styles.subtitleContainer}>
            <h5 className={styles.SubtitleSpf}>Please review your details before saving your profile.</h5>
          </div>

          <div className={styles.skinReviewContainer}>
            <div className={styles.skinResumeSection}>
              <div className={styles.skinReviewNumberContainer}>
                <img src={zero} alt="0" className={styles.skinReviewNumber} />
                <img src={one} alt="1" className={styles.skinReviewNumber} />
              </div>
              <div className={styles.skinReviewValueContainer}>
                <p className={styles.skinReviewValueTitle}>SKIN TYPE</p>
                <p className={styles.skinReviewValue}>{skinProfile?.skinType || 'Not specified'}</p>
              </div>
              <div className={styles.skinReviewEditButtonContainer}>
                <button className={styles.skinReviewEditButton} onClick={() => handleEdit('/spf-form/spf-skin-type')}>
                  EDIT
                </button>
              </div>
            </div>
            {/* Concerns */}
            <div className={styles.skinReviewSection}>
              <div className={styles.skinResumeSection}>

                <div className={styles.skinReviewNumberContainer}>
                  <img src={zero} alt="0" className={styles.skinReviewNumber} />
                  <img src={two} alt="1" className={styles.skinReviewNumber} />
                </div>
                <div className={styles.skinReviewValueContainer}>
                  <p className={styles.skinReviewValueTitle}>CONCERNS</p>
                  <p className={styles.skinReviewValue}>{skinProfile?.concerns?.length ? skinProfile.concerns.join(', ') : 'Not specified'}</p>
                </div>
                <div className={styles.skinReviewEditButtonContainer}>
                  <button className={styles.skinReviewEditButton} onClick={() => handleEdit('/spf-form/spf-concerns')}>
                    EDIT
                  </button>
                </div>
              </div>
            </div>
            {/* Risks */}
            <div className={styles.skinReviewSection}>
              <div className={styles.skinResumeSection}>
                <div className={styles.skinReviewNumberContainer}>
                  <img src={zero} alt="0" className={styles.skinReviewNumber} />
                  <img src={three} alt="1" className={styles.skinReviewNumber} />
                </div>
                <div className={styles.skinReviewValueContainer}>
                  <p className={styles.skinReviewValueTitle}>RISKS</p>
                  <p className={styles.skinReviewValue}>{skinProfile?.risks?.length ? skinProfile.risks.join(', ') : 'Not specified'}</p>
                </div>
                <div className={styles.skinReviewEditButtonContainer}>
                  <button className={styles.skinReviewEditButton} onClick={() => handleEdit('/spf-form/spf-risks')}>
                    EDIT
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.skinReviewButtonContainer}>
            <button className={styles.nextButtonForm} style={{ marginTop: '60px' }} onClick={saveProfile}>
              CONFIRM MY DIAGNOSIS
            </button>
          </div>
        </div>
      </div>
    </div >
  );
};
