import { useQuery, UseQueryResult } from '@tanstack/react-query';
import ProductsApi, { Brand } from '../service/API/ProductsApi';

const fetchAllBrands = async (): Promise<Brand[]> => {
    const data = await ProductsApi.getAllBrands();
    if (!data.success) {
        throw new Error(data.message || 'Failed to fetch brands');
    }
    return data.data || [];
};

export const useAllBrands = (): UseQueryResult<Brand[], Error> =>
    useQuery({ queryKey: ['allBrands'], queryFn: fetchAllBrands });