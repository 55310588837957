import "./style.scss";
import { useParams, useNavigate } from "react-router-dom";
import useRandomProducts from "hooks/randomProducts";
import { useEffect, useState } from "react";
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { ShopHeadBestsellers } from "components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers";
import ProductsApi from "service/API/ProductsApi";
import { Link } from "react-router-dom";
import { backgroundBrands } from "assets/brands/backgrounds/backgroundBrands";
import { ProductLoader } from "pages/ProductDetails/ProductLoader";
import single from 'assets/icons/single.svg'
import double from 'assets/icons/double.svg'
import { ShopBrandBestseller } from "components/shopPage/shopBrandBestseller/shopBrandBestseller";
export const Brands = () => {
    const { brandName } = useParams<{ brandName: string }>();
    const navigate = useNavigate();
    const { randomProducts } = useRandomProducts(6);
    const [brandInfo, setBrandInfo] = useState<any[]>([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [showCategories, setShowCategories] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
    const [grid, setGrid] = useState(2);
    const [brandNameImage, setBrandNameImage] = useState<string | null>(null);
    const [hoveredItem, setHoveredItem] = useState<number | null>(null);
    const handleGridChange = (grid: number) => {
        setGrid(grid);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const capitalizedBrandName = brandName ? brandName.charAt(0).toUpperCase() + brandName.slice(1) : '';
        if (capitalizedBrandName) {
            ProductsApi.getProductsByBrand(capitalizedBrandName).then(response => {
                if (response.success && response.data && response.data.length > 0) {
                    setBrandInfo(response.data);
                    // Set unique categories from fetched products
                    const uniqueCategories = [...new Set(response.data.map(product => product.subcategories?.[0]).filter(category => typeof category === 'string'))] as string[];
                    setCategories(uniqueCategories);
                } else {
                    navigate('/error', { state: { message: 'Brand not found' } });
                }
            }).catch(error => {
                navigate('/error', { state: { message: 'Error fetching brand data' } });
            });
        }
    }, [brandName, navigate]);

    const handleCategoryChange = (category: string) => {
        setSelectedCategories((prev) =>
            prev.includes(category) ? prev.filter((c) => c !== category) : [...prev, category]
        );

    };

    useEffect(() => {
        getBackgroundImage();
    }, [brandName]);

    const filteredProducts = brandInfo.filter((product) =>
        selectedCategories.length === 0 || selectedCategories.includes(product.subcategories?.[0] || "")
    );

    const removeCategory = (category: string) => {
        setSelectedCategories((prev) => prev.filter((c) => c !== category));
    };

    const getBackgroundImage = () => {
        // Define default images directly from the backgroundBrands array
        const defaultImages = backgroundBrands.find(brand => brand.name === "Default");

        if (isMobile) {
            // Attempt to find the brand, or fall back to default images if not found
            const brandImages = backgroundBrands.find(brand => brand.name === brandName) || defaultImages;
            if (brandImages) {
                setBackgroundImage(brandImages.mobileImage);
                setBrandNameImage(brandImages.titleImage);
            }
        } else {
            // Attempt to find the brand, or fall back to default images if not found
            const brandImages = backgroundBrands.find(brand => brand.name === brandName) || defaultImages;
            if (brandImages) {
                setBackgroundImage(brandImages.image);
                setBrandNameImage(brandImages.titleImage);
            }
        }
    };


    if (!brandInfo || brandInfo.length === 0) {
        return <div className="brand-loader">
            <ProductLoader />
        </div>
    }


    return (
        <>
            <ShopBrandBestseller backgroundImage={backgroundImage || ''} brandname={brandNameImage || ''} />
            <div className={isMobile ? "mobile-brand-shop-bestsellers-container" : "brand-shop-bestsellers-container"}>
                <div className={isMobile ? "mobile-brand-all-products-section" : "brand-all-products-section"}>
                    <div className="brand-left-column">
                        <div className="brand-header" style={{ fontSize: isMobile ? "15px" : "24px" }}>
                            <h2 className="brand-title">
                                {brandName?.toUpperCase()} PRODUCTS
                            </h2>
                            {isMobile && (
                                <div className="brand-icon-container">
                                    <img src={double} alt="double" onClick={() => handleGridChange(2)} />
                                    <img src={single} alt="single" onClick={() => handleGridChange(1)} />
                                </div>
                            )}
                        </div>
                        <p className={isMobile ? "mobile-all-products-count" : "all-products-count"}>{filteredProducts.length} products</p>

                        <div className="brand-filters">
                            <p onClick={() => setShowCategories(!showCategories)} style={{ cursor: "pointer" }}>
                                CATEGORIES <span>{showCategories ? "−" : "+"}</span>
                            </p>

                            {showCategories && (
                                <ul>
                                    {categories.map((category, index) => (
                                        <li key={index}>
                                            <label className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCategories.includes(category)}
                                                    onChange={() => handleCategoryChange(category)}
                                                />
                                                <span className="checkmark"></span>
                                                {category}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {selectedCategories.map((category) => (
                            <div key={category} className="selected-tag">
                                {category}
                                <span className="remove-tag" onClick={() => removeCategory(category)}>X</span>
                            </div>
                        ))}
                    </div>

                    <div className={isMobile ? `mobile-brand-all-products-grid grid-${grid}` : `brand-all-products-grid grid-${grid}`}>
                        {filteredProducts.map((product, index) => (
                            <Link to={`/product/${product._id}`} key={index}
                                className={isMobile ? `mobile-brand-product-container grid-container-${grid}` : `brand-product-container grid-container-${grid}`}
                                onTouchStart={() => setHoveredItem(index)}
                                onTouchEnd={() => setHoveredItem(null)}
                                onMouseEnter={() => setHoveredItem(index)}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                <p className="bestseller-just-dropped">just dropped</p>
                                <img src={hoveredItem === index && product.textureImgUrl ? product.textureImgUrl : product.imgUrl}
                                    alt={`${product.title} product`}
                                    className={isMobile ? `mobile-brand-product-image grid-image-${grid}` : "brand-product-image grid-image"} />
                                <div className={`product-details details-${grid}`}>
                                    <p className="brand-product-brand">{product.brand}</p>
                                    <p className="brand-product-title">{product.title}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <FooterSection />
        </>
    );
};
