import React from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import blackLogo from '../../../assets/icons/blackLogo.svg';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import profileSelectionIcon from '../../../assets/images/profileSelectionIcon.png';
import styles from '../style.module.scss';

export const SPF_WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useAtom(userAtom);

  const goToForm = () => {
    navigate('/spf-form/spf-gender');
  };

  const maybeLater = () => {
    navigate('/');
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <img className={styles.groupWelcome} alt="Group" src={profileSelectionIcon} />
          <div className={styles.titleWelcomeContainer}>
            <h1 className={styles.titleWelcome}>
              WELCOME TO OLI, <br /> {user?.name?.toUpperCase() || ''}
            </h1>
          </div>
          <div className={styles.subtitleWelcomeContainer}>
            <p className={styles.subtitleWelcome}>YOUR ACCOUNT HAS BEEN CREATED. NOW IT’S TIME TO ANSWER TO A FEW QUESTIONS IN ORDER TO CREATE YOUR PERFECT ROUTINE.</p>
          </div>
          <div className={styles.welcomeButtonContainer}>
            <button className={styles.nextButtonForm} onClick={goToForm}>
              Create my profile
            </button>
          </div>
          <div className={styles.maybeLaterButtonContainer}>
            <button className={styles.maybeLaterButton} onClick={maybeLater}>
              MAYBE LATER
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
