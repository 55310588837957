import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TGender } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import { userAtom } from 'stores/UserProfile';
import blackLogo from '../../../assets/icons/blackLogo.svg';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';

export const SPF_Gender: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState<TGender | null>(null);

  useEffect(() => {
    if (user?.SPF?.gender) {
      setSelectedGender(user.SPF.gender);
    }
  }, [user, skinProfile]);

  const handleGenderSelect = (gender: TGender) => {
    setSelectedGender(gender);
  };

  const goToNext = () => {
    if (selectedGender) {
      setSkinProfile({ ...skinProfile, gender: selectedGender });
      navigate('/spf-form/spf-age');
    }
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <StepIndicator currentStep={1} />
          <div className={styles.titleContainer}>
            <h1 className={styles.TitlesSpf}>HOW YOU DEFINE YOURSELF?</h1>
          </div>
          <div className={styles.subtitleContainer}>
            <h5 className={styles.SubtitleSpf}>Your genre allows us to give you more precise recommendations</h5>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'Female' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('Female')}
              >
                Female
              </button>
            </div>

            <div className={styles.inputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'Male' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('Male')}
              >
                Male
              </button>
            </div>

            <div className={styles.inputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'Non binary' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('Non binary')}
              >
                Non binary
              </button>
            </div>

            <div className={styles.inputGroup}>
              <button
                className={`${styles.inputButtons} ${selectedGender === 'I don’t want to define myself' ? styles.selected : ''}`}
                onClick={() => handleGenderSelect('I don’t want to define myself')}
              >
                I don’t want to define myself
              </button>
            </div>
            <div className={styles.spfbuttonContainer}>
              <button className={styles.nextButtonForm} disabled={!selectedGender} onClick={goToNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
