import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useAllActives } from '../../../hooks/useNotionActives';
import { ActiveArticleCard } from './ActiveArticleCard';
import activesBg from '../../../assets/images/activesListBg.png';
import activesCard from '../../../assets/images/activesCard.png';
import infoCircle from '../../../assets/images/infoCircle2.png';
import './style.scss';

export const ActivesList: React.FC = () => {
    const { allActives } = useAllActives();

    return (
        <div className='actives-container-feed'>
            <HomepageNavbar />
            <div className="actives-list-container">
                <div className="actives-list__image" style={{ backgroundImage: `url(${activesBg})` }}>
                    <div className="actives-list__image-overlay">
                        <h1 className="actives-list__title">ACTIVES LIST</h1>
                        <span className="actives-list__article-count">
                            {allActives?.length} ACTIVES
                        </span>
                    </div>
                    <div className="actives-list__bottom-text">
                        Active ingredients In cosmetics, each ingredient has cosmetic virtues and properties. Nevertheless, some ingredients are more efficient, more effective and more adapted to act on skin or hair problems. These cosmetic ingredients act in a very precise way with targeted actions. This is why we speak of "active ingredients" or "active cosmetics"
                    </div>
                </div>
                <div className="actives-list__articles-content">
                    <div className="actives-list__articles-section">
                        <div className="actives-list__rectangle">
                            <div className="actives-list__rectangle-content">
                                <img src={infoCircle} alt="Description" className="actives-list__rectangle-image" />
                                <div className="actives-list__rectangle-text-container">
                                    <div className="actives-list__rectangle-text" style={{ textTransform: 'uppercase', fontWeight: 700 }}>
                                        Always be careful with active ingredients
                                    </div>
                                    <div className="actives-list__rectangle-text" style={{ fontWeight: 400 }}>
                                        Some of which require precautions and/or certain gestures after use.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actives-list__cards-wrapper">
                        <div className="actives-list__articles-section actives-list__articles-section--no-top-padding">
                            <div className="actives-list__articles-grid">
                                {allActives
                                    ?.filter((active) => active.image)
                                    .map((active) => (
                                        <ActiveArticleCard key={active._id} active={active} />
                                    ))}

                                {/* Display without images */}
                                {allActives
                                    ?.filter((active) => !active.image)
                                    .map((active) => (
                                        <ActiveArticleCard key={active._id} active={active} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




