export const debounce = <T extends (...args: any[]) => any>(func: T, delay: number): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>;

    return (...args: Parameters<T>): void => {
        clearTimeout(timeout);

        // Set a new timeout
        timeout = setTimeout(() => {
            func(...args);
        }, delay);
    };
};