import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import { InviteFriends } from '../InviteFriends/InviteFriend';
import { StartDiagnosis } from '../StartDiagnosis/StartDiagnosis';
import styles from './SkinData.module.scss';
import zero from '../../../../../assets/numbers/10.png'
import one from '../../../../../assets/numbers/01.png'
import two from '../../../../../assets/numbers/02.png'
import three from '../../../../../assets/numbers/03.png'


export const SkinData: React.FC = () => {
    const [user] = useAtom(userAtom);
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;

    const redoDiagnosis = () => {
        navigate('/spf-form');
    };

    return (
        <div className={styles.skinContainer}>
            <div className={styles.skinDataContainer}>
                {user?.SPF?.lastUpdated === null ? (
                    <>
                        {isMobile && (
                            <div className={styles.skinHeaderContainer}>
                                <h3 className={styles.skinHeader}>My skin resume</h3>
                                <Link to="/spf-form" className={styles.headerEditButton}>EDIT</Link>
                            </div>
                        )}
                        <StartDiagnosis />
                    </>
                ) : (
                    <>
                        <div className={styles.skinHeaderContainer}>
                            <h3 className={styles.skinHeader}>My skin resume</h3>
                            <Link to="/spf-form" className={styles.headerEditButton}>EDIT</Link>
                        </div>
                        <hr className={styles.skinUnderline} />

                        <div className={styles.skinInfoBox}>
                            <div className={styles.skinInfoBoxNumber}>
                                <img src={zero} alt="0" />
                                <img src={one} alt="1" style={{ width: '6px' }} />
                            </div>
                            <div className={styles.skinInfoBoxContent}>
                                <span className={styles.skinInfoBoxTitle}>Skin type</span>
                                <p className={styles.skinInfoBoxData}>
                                    {user?.SPF?.skinType || 'Not specified'}
                                </p>
                            </div>
                        </div>

                        <div className={styles.skinInfoBox}>
                            <div className={styles.skinInfoBoxNumber}>
                                <img src={zero} alt="0" />
                                <img src={two} alt="2" style={{ width: '9.8px' }} />
                            </div>
                            <div className={styles.skinInfoBoxContent}>
                                <span className={styles.skinInfoBoxTitle}>Concerns</span>
                                <p className={styles.skinInfoBoxData}>
                                    {user?.SPF?.concerns ? user.SPF.concerns.join(', ') : 'Not specified'}
                                </p>
                            </div>
                        </div>

                        <div className={styles.skinInfoBox} style={{ border: 'none' }}>
                            <div className={styles.skinInfoBoxNumber} style={{ paddingTop: '0px' }}>
                                <img src={zero} alt="0" />
                                <img src={three} alt="3" style={{ width: '9.8px' }} />
                            </div>
                            <div className={styles.skinInfoBoxContent}>
                                <span className={styles.skinInfoBoxTitle}>Personal attention to risks</span>
                                <div className={styles.skinInfoBoxData} style={{ marginTop: '10px' }}>
                                    {user?.SPF?.risks ? user.SPF.risks.map((risk, index) => (
                                        <span key={index} className={styles.riskItem}>{risk}</span>
                                    )) : 'Not specified'}
                                </div>
                            </div>
                        </div>

                        {isMobile && <hr className={styles.skinUnderline} />}


                        <div className={styles.buttonContainer}>
                            <button className={styles.redoButton} onClick={redoDiagnosis}>REDO MY DIAGNOSIS</button>
                        </div>
                    </>
                )}
                <InviteFriends />
            </div>
        </div >
    );
};
