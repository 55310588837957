import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import HeaderBackgroundImage from 'assets/images/headSectionBackground.jpg';
import MainText from 'assets/textFont/RoutineForAllSkinTypes.svg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import Logo from 'assets/icons/whiteLogo.svg';
import ScrollVector from 'assets/icons/scrollVector.svg';
import hyejaDesktop from '../../../assets/brands/Hyeja/heyja_desktop.png';
import hyejaMobile from '../../../assets/brands/Hyeja/hyeja_mobile.png';
import hyejaLogo from '../../../assets/brands/Hyeja/hyeja_title.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';
import { config } from 'config';
import vector from '../../../assets/images/giantVector.png'
import skinroutine1 from '../../../assets/icons/skinRoute.svg'
import morning from '../../../assets/icons/morning.png'
import night from '../../../assets/icons/night.png'
import Dot from '../../../assets/icons/blackDot.svg';

interface HeadSectionProps {
  backgroundImage: string;
  isLoginPage?: boolean;
}

export const HeadSection: React.FC<HeadSectionProps> = ({ backgroundImage, isLoginPage }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [hideLogo, setHideLogo] = useState(false); // State to manage logo visibility
  const [activeSlide, setActiveSlide] = useState(1);
  const swiperInstance = useRef<any>(null); // Ref for Swiper instance
  const isMobile = window.innerWidth < 768;
  const [currentDayIndex, setCurrentDayIndex] = useState(new Date().getDay());
  const [days] = useState(['S', 'M', 'T', 'W', 'T', 'F', 'S']); // Days of the week

  const effectiveBackgroundImage = backgroundImage || HeaderBackgroundImage; // Use provided backgroundImage or default to HeaderBackgroundImage

  useEffect(() => {
    const currentDay = new Date().getDay();
    setCurrentDayIndex(currentDay);
  }, []);

  document.documentElement.style.setProperty('--header-height', isLoginPage ? '94vh' : '100vh');


  const handleScroll = () => {
    const logoElement = document.querySelector('.logo-container') as HTMLElement;
    const headerBlock = document.querySelector('.headerBlockHome') as HTMLElement;

    if (logoElement && headerBlock) {
      const headerBottom = headerBlock.getBoundingClientRect().bottom;

      setHideLogo(headerBottom <= 0); // Hide logo when bottom of the header reaches the top of the viewport

      const logoTop = logoElement.getBoundingClientRect().top;
      setIsSticky(logoTop <= 10); // Make logo sticky
    }
  };

  const handleButtonClick = (slideNumber: number) => {
    if (swiperInstance.current) {
      swiperInstance.current.slideTo(slideNumber - 1); // Swiper uses 0-indexed slides
    }
    setActiveSlide(slideNumber);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderContent = () => {
    if (isLoginPage) {
      return (
        <>
          <div className="eggShapeContainer">
            <img src={vector} alt="Egg Shape" className="eggShape" />
            <img src={morning} alt="Morning" className="morning" />
            <img src={night} alt="Night" className="night" />
            <img src={skinroutine1} alt="My Skin Routine" className="mySkinRoutine" />
            <div className="dayContainer">
              {days.map((day, index) => (
                <div key={index} className="dayWrapper">
                  <span className={index === currentDayIndex ? 'currentDay' : 'normalDay'}>
                    {day}
                  </span>
                  {index === currentDayIndex && <img src={Dot} alt="dot" className="dots" />}
                </div>
              ))}
            </div>
          </div >
        </>
      );
    } else {
      return (
        <div className="headerRight">
          <img src={MainText} alt="Routine For All Skin Types" className="mainText" />
        </div>
      );
    }
  };

  return (
    <>
      <HomepageNavbar />
      {isLoginPage ? (
        <div
          className={`headerBlockHome ${isLoginPage ? 'loginPageStyle' : ''}`}
          style={{
            backgroundImage: `url(${effectiveBackgroundImage})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="headerBlock-content">
            <div className="logo-container">
              {!isLoginPage && (
                <div
                  className={`logo ${hideLogo ? 'hidden' : ''}`}
                  style={{ backgroundImage: `url(${Logo})` }}
                />
              )}
            </div>
            {renderContent()}
          </div>
        </div>
      ) : (
        <Swiper
          onSwiper={(swiper: any) => {
            swiperInstance.current = swiper;
          }}
          spaceBetween={0}
          slidesPerView={1}
          allowSlidePrev={!isLoginPage}
          allowSlideNext={!isLoginPage}
          onSlideChange={(swiper: any) => setActiveSlide(swiper.activeIndex + 1)}
        >
          <div className="headerButtonContainer">
            <button
              className={`headerButton ${activeSlide === 1 ? '' : 'blurred'}`}
              onClick={() => handleButtonClick(1)}
            >
              01
            </button>
            <button
              className={`headerButton ${activeSlide === 2 ? '' : 'blurred'}`}
              onClick={() => handleButtonClick(2)}
            >
              02
            </button>
          </div>

          {/* First Slide */}
          <SwiperSlide
            style={{
              backgroundImage: `url(${effectiveBackgroundImage})`,
              backgroundSize: 'cover',
            }}
          >
            <div className="headerBlockHome">
              <div className="headerBlock-content">
                <div className="logo-container">
                  {!isLoginPage && (
                    <div
                      className={`logo ${hideLogo ? 'hidden' : ''}`}
                      style={{ backgroundImage: `url(${Logo})` }}
                    />
                  )}
                  <div>
                  </div>
                  <p className="headerAdvice">Take better care of yourself</p>
                </div>
                {renderContent()}
                <div>
                  <p className="headerRight-text">Professional Advices</p>
                </div>
              </div>
              {isMobile && (
                <div className="scrollVectorContainer">
                  <img src={ScrollVector} alt="Scroll Vector" className="scrollVector" />
                  <span className="scrollVectorText">Scroll down</span>
                </div>
              )}
            </div>
          </SwiperSlide>

          {/* Second Slide */}
          <SwiperSlide
            style={{
              backgroundImage: `url(${isMobile ? hyejaMobile : hyejaDesktop})`,
              backgroundSize: 'cover',
            }}
          >
            <Link to={`/brand/${config.REACT_APP_BRAND_NAME}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="headerBlockHome clickable">
                <div className="brandHomePageTitle-container">
                  <img src={hyejaLogo} alt="Hyeja Logo" className="brandLogo" />
                </div>
                {isMobile && (
                  <div className="scrollVectorContainer">
                    <img src={ScrollVector} alt="Scroll Vector" className="scrollVector" />
                    <span className="scrollVectorText">Scroll down</span>
                  </div>
                )}
              </div>
            </Link>
          </SwiperSlide>
        </Swiper>
      )}
    </>
  );
}
