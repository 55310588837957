import { useState, useEffect, useCallback } from 'react';
import styles from './checkout.module.scss';
import { IProduct } from 'types/TInterfaces';
import { userAtom } from '../../stores/UserProfile';
import { useAtom } from 'jotai';
import { IProductInCart, ShippingAddress } from '../../types/TInterfaces';
import { DeliveryService } from '../../types/TInterfaces';
import { AddressCard } from './adressCard';
import userService from '../../service/UserService';
import { loadLocalCart, removeFromLocalCart, updateLocalCartItemQuantity } from '../../service/localCartService';
import { IAnonymousUser, IUser } from '../../types/TInterfaces';
import deliveryApi from '../../service/API/deliveryApi';
import { useNavigate } from 'react-router-dom';
import { useElements, CardElement, useStripe } from '@stripe/react-stripe-js';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import checkoutApi from '../../service/API/checkoutApi';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import selectedIcon from '../../assets/images/selectShipping.svg';
import 'react-phone-number-input/style.css';
import { debounce } from 'utils/debounceUtil';
import MapboxApi from 'service/API/mapboxApi';
import { CountryCode } from 'libphonenumber-js';

export const CheckoutPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newAddressErrors, setNewAddressErrors] = useState<{ [key: string]: string }>({});
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [postalCodeError, setPostalCodeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);
  const [newAddress, setNewAddress] = useState<ShippingAddress & { _id?: string }>({
    addressName: '',
    first_name: '',
    last_name: '',
    phone: '',
    street: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
    asDefault: false,
  });

  //logged in user
  const [user, setUser] = useAtom(userAtom);
  const [cartItems, setCartItems] = useState<IProductInCart[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [tempQuantity, setTempQuantity] = useState<number | null>(null);

  // anonymous user
  const [isAnonymousUser, setIsAnonymousUser] = useState(!user || Object.keys(user).length === 0)
  const [userEmail, setUserEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryForPhoneInput, setCountryForPhoneInput] = useState('FR');

  // payment & shipping service + country handler
  const [deliveryCountries, setDeliveryCountries] = useState<Pick<DeliveryService, 'country'>[]>([]);
  const [allShippingDeliveryServices, setAllShippingDeliveryServices] = useState<DeliveryService[]>([]);
  const [filteredShippingServices, setFilteredShippingServices] = useState<DeliveryService[]>([]);
  const [selectedShippingService, setSelectedShippingService] = useState<DeliveryService | null>(null);
  const [selectedCountry, setSelectedCountry] = useState(user?.email ?? '');
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
  const [expandedService, setExpandedService] = useState<DeliveryService | null>(null);
  const [relayPointsData, setRelayPointsData] = useState<any[]>([]);
  const [selectedRelayPoint, setSelectedRelayPoint] = useState(null);

  // selected address for shipping/delivery
  const [shippingAddress, setShippingAddress] = useState<ShippingAddress[]>([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<ShippingAddress>(shippingAddress[selectedAddressIndex]);
  const [errorMessage, setErrorMessage] = useState('');

  // checking if the screen in mobile size
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    setIsAnonymousUser(!user || Object.keys(user).length === 0)
  }, [user])

  useEffect(() => {
    setSelectedAddress(shippingAddress[selectedAddressIndex]);
  }, [selectedAddressIndex, shippingAddress]);

  useEffect(() => {
    if (selectedShippingService?.service === 'Relay/Lockers' && !selectedRelayPoint) {
      setIsCheckoutDisabled(true);
    }

    else {
      setIsCheckoutDisabled(false);
    }
  }, [selectedShippingService, selectedRelayPoint]);

  const extractCountryCode = (input: string): string | null => {
    const regex = /^([A-Z]{2})\s/;
    const match = input.match(regex);
    return match ? match[1] : null;
  };

  const handleServiceClick = async (service: DeliveryService) => {
    if (service.service.toLowerCase().includes('relay')) {
      try {
        const countryCode = extractCountryCode(selectedAddress.country) ?? '';
        const response = await deliveryApi.fetchRelayPoints(selectedAddress.postalCode, countryCode);

        setRelayPointsData(response);
      } catch (error) {
        console.error("Failed to fetch Relay/Lockers data", error);
      }
    } else {
      setSelectedRelayPoint(null);
    }

    setExpandedService(expandedService === service ? null : service);
    setSelectedShippingService(service);
  };

  const checkForErrors = () => {
    const hasValidationErrors = Object.values(validationErrors).some((error) => error !== '');
    const hasEmailError = emailError !== '';
    const hasPhoneError = phoneError !== '';
    const hasPostalCodeError = postalCodeError !== '';

    return hasValidationErrors || hasEmailError || hasPhoneError || hasPostalCodeError;
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!user) {
      console.error('User is not logged in');
      return;
    }

    try {
      const accessToken = localStorage.getItem('accessToken');
      const userWithToken = { ...user, token: accessToken };

      if (isEditing) {
        const addressId = selectedAddress?._id;
        if (!addressId) {
          console.error('No address ID found for editing');
          alert('Failed to edit the address. Please try again.');
          return;
        }

        const result = await userService.updateDeliveryAddress(userWithToken, setUser, newAddress, addressId, navigate);

        setIsEditing(false);
        if (result?.success) {
          const userDataResult = await userService.getUserData(userWithToken, setUser);
          if (!userDataResult.success) {
            console.error('Failed to fetch updated user data');
          }
          setIsEditing(false);
        } else {
          throw new Error(result?.message || 'Failed to update address');
        }
      } else {
        const result = await userService.addDeliveryAddress(userWithToken, setUser, newAddress, navigate);
        if (result.success && result.address) {
          setShippingAddress([...shippingAddress, result.address]);

        } else {
          throw new Error(result.message || 'Failed to add new address');
        }
      }

      setNewAddress({
        addressName: '',
        _id: '',
        first_name: '',
        last_name: '',
        phone: '',
        street: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        country: '',
        asDefault: false,
      });
      setIsAdding(false);

    } catch (error) {
      console.error('Error saving address:', error);
      alert('Failed to save the address. Please try again.');
    }
  };

  // update fileds when edit existing address (fill with user data from db)
  const handleEdit = (index: number) => {
    setIsEditing(true);
    setSelectedAddressIndex(index);
    const addressToEdit = shippingAddress[index];
    setNewAddress({
      addressName: '',
      _id: addressToEdit._id,
      first_name: addressToEdit.first_name || '',
      last_name: addressToEdit.last_name || '',
      phone: addressToEdit.phone || '',
      street: addressToEdit.street,
      streetNumber: addressToEdit.streetNumber,
      postalCode: addressToEdit.postalCode,
      city: addressToEdit.city,
      country: addressToEdit.country,
    });

  };

  // clean ields for add new address
  const handleAddNewAddress = () => {
    setNewAddress({
      addressName: '',
      first_name: '',
      last_name: '',
      phone: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      country: '',
      asDefault: false,
    });
    setIsAdding(true);
    setIsEditing(false);
  };

  // selection from existing address cards (when user have several addresses)
  const handleSelectAddress = (index: number) => {
    setSelectedAddressIndex(index);

    setSelectedCountry(shippingAddress[index].country);
  };

  useEffect(() => {
    if (user) {
      const userCarts = user.cart;
      const userProducts = userCarts.map((item) => item.product);
      if (user.addresses.length > 0) {
        const formattedAddresses = user.addresses.map((address) => ({
          _id: address._id,
          addressName: '',
          first_name: address.firstName || '',
          last_name: address.lastName || '',
          phone: address.phoneNumber?.number || '',
          street: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.postalCode || '',
          city: address.city || '',
          country: address.country || '',
        }));
        setShippingAddress(formattedAddresses);
        setUserEmail(user?.email ?? '');
        setSelectedCountry(formattedAddresses[0].country);
      }
      setProducts(userProducts);
      setCartItems(userCarts);
    } else {

      const localCart = loadLocalCart();
      const userProducts = localCart.map((item) => item.product);
      setProducts(userProducts);
      setCartItems(localCart);
    }
  }, [user]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const { uniqueCountries, allServices } = await deliveryApi.fetchDeliveryServices();
      setDeliveryCountries(uniqueCountries);
      setAllShippingDeliveryServices(allServices);
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const filteredServices = allShippingDeliveryServices.filter(
        (service) => service.country === selectedCountry
      );

      const servicePriority: { [key: string]: number } = {
        Standard: 1,
        Express: 2,
      };

      const sortedServices = filteredServices.sort((a, b) => {
        const aPriority = servicePriority[a.service] || 3;
        const bPriority = servicePriority[b.service] || 3;

        return aPriority - bPriority;
      });

      setFilteredShippingServices(sortedServices);

      if (sortedServices.length > 0) {
        setSelectedShippingService(sortedServices[0]);
      }
    } else {
      setFilteredShippingServices([]);
    }
  }, [selectedCountry, allShippingDeliveryServices]);

  // update country
  const handleCountryChangeForNewAddress = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const country = event.target.value;
    setNewAddress({ ...newAddress, country });
  };

  // all calcucation start
  const getTotalItems = () => cartItems.reduce((total, item) => total + item.quantity, 0);

  const selectedShipmentWithoutTax = (selectedShippingService: DeliveryService | null): number => {
    const taxRate = selectedShippingService?.tax ?? 0;
    const shippingPrice = selectedShippingService?.price ?? 0;

    const taxPrice = shippingPrice / (1 + taxRate / 100);
    return taxPrice

  }

  const calculateTotalAmountIncludingAllTax = useCallback((cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    const { subtotal, productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
    const shippingPrice = (selectedShippingService?.price) ?? 0;
    const totalAmount = subtotal + productTax + shippingPrice;

    return totalAmount.toFixed(2);
  }, []);

  useEffect(() => {
    setTotalPriceWithTax(parseFloat(calculateTotalAmountIncludingAllTax(cartItems, selectedShippingService)));
  }, [cartItems, selectedShippingService, calculateTotalAmountIncludingAllTax]);

  const calculateTotalAmountIncludingTax = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    const { subtotal, productTax } = calculateCartSubtotal(cartItems, selectedShippingService);

    const totalAmount = subtotal + productTax;
    return totalAmount.toFixed(2);
  };

  const calculateCartSubtotal = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): { subtotal: number, productTax: number } => {
    const taxRate = selectedShippingService?.tax ?? 0;
    let subtotal = 0;
    let productTax = 0;
    cartItems.forEach((item: IProductInCart) => {
      if (item.product.price !== undefined) {
        const priceBeforeVAT = item.product.price / (1 + taxRate / 100);
        const tax = item.product.price - priceBeforeVAT;
        subtotal += priceBeforeVAT * item.quantity;
        productTax += tax * item.quantity;
      }
    });

    subtotal = Math.round(subtotal * 100) / 100;
    productTax = Math.round(productTax * 100) / 100;

    subtotal = subtotal > 0.00 ? parseFloat(subtotal.toFixed(2)) : 0.00;
    productTax = productTax > 0.00 ? parseFloat(productTax.toFixed(2)) : 0.00;
    return { subtotal, productTax };
  };

  const calculateShippingCost = (selectedShippingService: DeliveryService | null): { shippingPrice: string, shippingTax: string, totalShippingCost: string } => {
    if (!selectedShippingService || !selectedShippingService.price || !selectedShippingService.tax) {
      return { shippingPrice: '0.00', shippingTax: '0.00', totalShippingCost: '0.00' };
    }

    const taxRate = selectedShippingService.tax / 100;
    const totalShippingCost = selectedShippingService.price;

    const shippingPrice = totalShippingCost / (1 + taxRate);

    const shippingTax = totalShippingCost - shippingPrice;

    return {
      shippingPrice: shippingPrice.toFixed(2),
      shippingTax: shippingTax.toFixed(2),
      totalShippingCost: totalShippingCost.toFixed(2),
    };
  };

  // validate for loggedIn user form
  const isLoggedUserFormValid = () => {
    const requiredFields = ['first_name', 'last_name', 'phone', 'street', 'postalCode', 'city', 'country'];


    const areFieldsFilled = requiredFields.every((field) => newAddress[field as keyof typeof newAddress]);


    const isPhoneValid = validatePhone(newAddress.phone || '');
    const isPostalCodeValid =
      validatePostalCode(newAddress.postalCode || '') && newAddress.postalCode.length <= 12;
    const areLengthsValid =
      (newAddress.first_name || '').length <= 38 &&
      (newAddress.last_name || '').length <= 38 &&
      (newAddress.street || '').length <= 38 &&
      (newAddress.city || '').length <= 38;

    return areFieldsFilled && isPhoneValid && isPostalCodeValid && areLengthsValid;
  };

  const handleCheckout = async () => {

    const countryCode = selectedAddress.country.split(' ')[0];
    let selectedUser: IUser | IAnonymousUser;

    const isFreeShipping = selectedShippingService && totalPriceWithTax >= selectedShippingService.freeshipping;

    const shippingCost = selectedShippingService && !isFreeShipping
      ? calculateShippingCost(selectedShippingService)
      : { shippingPrice: '0.00', shippingTax: '0.00', totalShippingCost: '0.00' };

    // remove shipping service fee if isFreeShipping
    const adjustedTotalPrice = isFreeShipping && selectedShippingService
      ? parseFloat((totalPriceWithTax - selectedShippingService.price).toFixed(2))
      : parseFloat(totalPriceWithTax.toFixed(2));


    let checkoutData = {
      products: cartItems.map((item) => {
        const taxRate = selectedShippingService?.tax ?? 0;
        const unitPrice = item.product.price ? item.product.price / (1 + taxRate / 100) : 0;
        const unitTax = unitPrice * (taxRate / 100);
        return {
          sku: item.product.sku,
          quantity: item.quantity,
          unitPrice: unitPrice.toFixed(2),
          unitTax: unitTax.toFixed(2),
          discount: '0.00', // TODO: get discount from server when we implement voucher system
        };
      }),
      amount: adjustedTotalPrice,
      shipping: selectedShippingService ? {
        shippingPrice: shippingCost.shippingPrice,
        shippingTax: shippingCost.shippingTax,
        totalShippingCost: shippingCost.totalShippingCost,
        shippingMethod: selectedShippingService.service,
      } : null,
      delivery: {
        firstName: selectedAddress.first_name,
        lastName: selectedAddress.last_name,
        phoneNumber: selectedAddress.phone,
        street: selectedAddress.street,
        streetNumber: selectedAddress.streetNumber,
        city: selectedAddress.city,
        postalCode: selectedAddress.postalCode,
        country: countryCode,
        state: '',
      },
      userId: user ? user._id : '',
      userEmail: userEmail,
    };

    if (user && !isAnonymousUser) {
      checkoutData.userId = user._id ?? '';
      selectedUser = user;
    } else {
      const anonymousUser: IAnonymousUser = {
        email: userEmail,
        created_at: new Date(),
        cart: cartItems,
        addresses: {
          firstName: selectedAddress.first_name,
          lastName: selectedAddress.last_name,
          phoneNumber: selectedAddress.phone,
          street: selectedAddress.street,
          streetNumber: selectedAddress.streetNumber,
          city: selectedAddress.city,
          postalCode: selectedAddress.postalCode,
          country: selectedAddress.country,
        },
        anonymous: true,
      };
      checkoutData.userEmail = anonymousUser.email;
      selectedUser = anonymousUser;
    }

    const result = await checkoutApi.handlePayment(adjustedTotalPrice, selectedUser, checkoutData, selectedRelayPoint);

    if (!result?.success) {
      setErrorMessage('we struggled to process your request. Please try again, if the problem persists please contact us');
      navigate('/checkout');
    } else {
      setErrorMessage(''); // Clear any previous errors on success
    }
  };

  // remove item from cart
  const removeItemFromCart = async (id: string) => {
    if (user) {
      const product = cartItems.find(item => item.product._id === id);
      if (!product) {
        console.error("Product not found in cart.");
        return;
      }
      const result = await userService.removeFromCart(user, setUser, product, navigate);

      if (result.success) {
        const updatedCartItems = cartItems.filter((item) => item.product._id !== id);
        updateCart(updatedCartItems);
      } else {
        console.error("Failed to remove product from cart:", result.message);
      }
    } else {
      const updatedCart = removeFromLocalCart(id);
      updateCart(updatedCart);
      const updatedCartProducts = updatedCart.map((item) => item.product);
      setProducts(updatedCartProducts);
    }
  };

  // cart update
  const updateCart = (updatedCartItems: typeof cartItems) => {
    setCartItems(updatedCartItems);
  };

  //update quantity
  const handleEditQuantity = (id: string) => {
    setEditingItemId(id);
    const item = cartItems.find((item) => item.product._id === id);
    if (item) {
      setTempQuantity(item.quantity);
    }
  };

  const changeItemQuantity = (id: string, newQuantity: number) => {
    setTempQuantity(Math.max(1, newQuantity));
  };

  const handleApproveChange = async (id: string) => {
    const product = cartItems.find((item) => item.product._id === id);
    if (!product) return;

    if (user) {
      const updatedCartItems = cartItems.map((item) =>
        item.product._id === id ? { ...item, quantity: tempQuantity! } : item
      );
      updateCart(updatedCartItems);
      setEditingItemId(null);
      const result = await userService.updateCart(user, setUser, product.product, tempQuantity!, navigate);
      if (!result.success) {
        console.error("Failed to update cart on the server:", result.message);
      }
    } else {
      const updatedCart = updateLocalCartItemQuantity(id, tempQuantity!);
      updateCart(updatedCart);
      setEditingItemId(null);
    }
  };

  const renderItemActions = (item: IProductInCart) => (
    editingItemId === item?.product._id ? (
      <div className="quantity-controls">
        <button onClick={() => changeItemQuantity(item?.product._id, tempQuantity! - 1)}>-</button>
        <span>{tempQuantity}</span>
        <button onClick={() => changeItemQuantity(item?.product._id, tempQuantity! + 1)}>+</button>
        <button onClick={() => handleApproveChange(item?.product._id)}>APPROVE</button>
      </div>
    ) : (
      <div className="default-controls">
        <button onClick={() => handleEditQuantity(item?.product._id)} style={{ fontWeight: 700 }}>CHANGE</button>
        <button onClick={() => removeItemFromCart(item?.product._id)}>DELETE</button>
      </div>
    )
  );

  const isFormValid = () => {
    // Check if selectedAddress exists.
    if (!selectedAddress) {
      return false;
    }

    const isShippingValid =
      !!(userEmail &&
        selectedAddress.first_name &&
        selectedAddress.last_name &&
        selectedAddress.phone &&
        selectedAddress.street &&
        selectedAddress.streetNumber &&
        selectedAddress.postalCode &&
        selectedAddress.city &&
        selectedAddress.country);

    return isShippingValid;
  };

  // forms validation email, number + logistic company restrictions
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    return isValidPhoneNumber(phone);
  };

  const validatePostalCode = (postalCode: string) => {
    return /^[0-9]*$/.test(postalCode);
  };

  const validateMaxLength = (
    value: string,
    maxLength: number
  ) => {
    return value.length <= maxLength;
  };

  // validate for annymous form
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    let updatedAddress = { ...selectedAddress }; // Copy the current state to calculate updated values

    if (name === 'email') {
      setUserEmail(value);
      setEmailError(validateEmail(value) ? '' : 'Please enter a valid email address.');
    } else if (name === 'postalCode') {
      if (!validateMaxLength(value, 12)) {
        setPostalCodeError('Postal code must be 12 characters or less.');
      } else {
        updatedAddress = { ...updatedAddress, [name]: value };
        setSelectedAddress(updatedAddress); // Update state
        setPostalCodeError(validatePostalCode(value) ? '' : 'Postal code should only contain numbers.');
      }
    } else if (['first_name', 'last_name', 'street', 'city'].includes(name)) {
      if (!validateMaxLength(value, 38)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `${name.replace('_', ' ')} must be 38 characters or less.`,
        }));
      } else {
        updatedAddress = { ...updatedAddress, [name]: value };
        setSelectedAddress(updatedAddress); // Update state
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      }
    } else {
      updatedAddress = { ...updatedAddress, [name]: value };
      setSelectedAddress(updatedAddress); // Update state

      if (name === 'country') {
        setSelectedCountry(value);
      }
    }

    // Trigger fetches based on the updatedAddress values
    if ((name === 'street' || name === 'streetNumber' || name === 'city') &&
      updatedAddress?.street && updatedAddress?.streetNumber && updatedAddress?.city) {
      debouncedFetchZipCodeAndCountry(
        updatedAddress.street,
        updatedAddress.streetNumber,
        updatedAddress.city
      );
    } else if ((name === 'street' || name === 'city') &&
      updatedAddress.street && updatedAddress.city) {
      debouncedFetchCountry(
        updatedAddress.street,
        updatedAddress.city
      );
    } else if ((name === 'street' || name === 'postalCode') &&
      updatedAddress.street && updatedAddress.postalCode) {
      debouncedFetchCityAndCountry(
        updatedAddress.street,
        updatedAddress.postalCode
      );
    }
  };

  const handlePhoneChange = (value: string | undefined) => {
    const phone = value || '';
    setPhone(phone);
    setSelectedAddress((prevAddress) => ({
      ...prevAddress,
      phone,
    }));

    if (phone && !validatePhone(phone)) {
      setPhoneError('Invalid phone number. Please check the format.');
    } else {
      setPhoneError('');
    }
  };

  const handleNewAddressChange = (field: string, value: string) => {
    let error = '';

    if (field === 'phone' && !validatePhone(value)) {
      error = 'Phone number can only contain numbers and an optional + at the start.';
    } else if (field === 'postalCode') {
      if (!validatePostalCode(value)) {
        error = 'Postal code should only contain numbers.';
      } else if (!validateMaxLength(value, 12)) {
        error = 'Postal code must be 12 characters or less.';
      }
    } else if (['first_name', 'last_name', 'street', 'city'].includes(field)) {
      if (!validateMaxLength(value, 38)) {
        error = `${field.replace('_', ' ')} must be 38 characters or less.`;
      }
    }

    setNewAddressErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));

    let updatedAddress = { ...newAddress, [field]: value };

    setNewAddress(updatedAddress);

    if (
      (field === 'street' || field === 'streetNumber' || field === 'city') &&
      updatedAddress.street && updatedAddress.streetNumber && updatedAddress.city
    ) {
      debouncedFetchZipCodeAndCountry(
        updatedAddress.street,
        updatedAddress.streetNumber,
        updatedAddress.city
      );
    } else if ((field === 'street' || field === 'city') &&
      updatedAddress.street && updatedAddress.city) {
      debouncedFetchCountry(
        updatedAddress.street,
        updatedAddress.city
      );
    } else if ((field === 'street' || field === 'postalCode') &&
      updatedAddress.street && updatedAddress.postalCode) {
      debouncedFetchCityAndCountry(
        updatedAddress.street,
        updatedAddress.postalCode
      );
    }
  };


  const anonymousForm = () => {
    return (
      <>
        <form className={styles.addressForm}>
          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="email">Your Email</label>
            <input
              id="email"
              className={`${styles.formInput} ${styles.emailInput}`}
              type="email"
              name="email"
              value={userEmail}
              onChange={handleInputChange}
              required
            />
            {emailError && <p className={styles.errorMessage}>{emailError}</p>}
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="first_name">First Name</label>
            <input
              id="first_name"
              className={`${styles.formInput} ${styles.firstNameInput}`}
              type="text"
              name="first_name"
              value={selectedAddress?.first_name || ''}
              onChange={handleInputChange}
            />
            {validationErrors.first_name && <p className={styles.errorMessage}>{validationErrors.first_name}</p>}
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="last_name">Last Name</label>
            <input
              id="last_name"
              className={`${styles.formInput} ${styles.lastNameInput}`}
              type="text"
              name="last_name"
              value={selectedAddress?.last_name || ''}
              onChange={handleInputChange}
            />
            {validationErrors.last_name && <p className={styles.errorMessage}>{validationErrors.last_name}</p>}
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="phone">Phone Number</label>
            <PhoneInput
              id="phone"
              className={`${styles.phoneInput}`}
              defaultCountry={countryForPhoneInput as CountryCode}
              international
              value={phone}
              onChange={handlePhoneChange}
            />
            {phoneError && <p className={styles.errorMessage}>{phoneError}</p>}
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="street">Street</label>
            <input
              id="street"
              className={`${styles.formInput} ${styles.streetInput}`}
              type="text"
              name="street"
              value={selectedAddress?.street || ''}
              onChange={handleInputChange}
            />
            {validationErrors.street && <p className={styles.errorMessage}>{validationErrors.street}</p>}
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="streetNumber">Street Number</label>
            <input
              id="streetNumber"
              className={`${styles.formInput} ${styles.streetNumberInput}`}
              type="text"
              name="streetNumber"
              value={selectedAddress?.streetNumber || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="city">State</label>
            <input
              id="city"
              className={`${styles.formInput} ${styles.cityInput}`}
              type="text"
              name="city"
              value={selectedAddress?.city || ''}
              onChange={handleInputChange}
            />
            {validationErrors.city && <p className={styles.errorMessage}>{validationErrors.city}</p>}
          </div>

          <div className={styles.formInputBox}>{renderCountrySelect()}</div>

          <div className={styles.formInputBox}>
            <label className={styles.formInputLabel} htmlFor="postalCode">Zip Code</label>
            <input
              id="postalCode"
              className={`${styles.formInput} ${styles.postalCodeInput}`}
              type="text"
              name="postalCode"
              value={selectedAddress?.postalCode || ''}
              onChange={handleInputChange}
            />
            {postalCodeError && <p className={styles.errorMessage}>{postalCodeError}</p>}
          </div>
        </form >
      </>
    );
  };

  const calculatePrice = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    const { subtotal } = calculateCartSubtotal(cartItems, selectedShippingService);
    return subtotal.toFixed(2);
  }

  const calculateTaxes = (cartItems: IProductInCart[], selectedShippingService: DeliveryService | null): string => {
    let shippingTax = 0;
    const { productTax } = calculateCartSubtotal(cartItems, selectedShippingService);
    if (selectedShippingService?.tax) {
      const taxRate = selectedShippingService?.tax / 100;
      const totalShippingCost = selectedShippingService?.price;
      const shippingPrice = totalShippingCost / (1 + taxRate);
      // free shipping
      if (parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >= selectedShippingService.freeshipping) {
        shippingTax = 0;
      } else {
        shippingTax = Number((totalShippingCost - shippingPrice).toFixed(2));
      }

      return (productTax + shippingTax).toFixed(2);
    }
    return productTax.toFixed(2);


  }

  const renderCountrySelect = () => {
    let handleChange;
    let address;

    if (isAnonymousUser) {
      handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(e);
      address = selectedAddress;
    } else {
      handleChange = handleCountryChangeForNewAddress;
      address = newAddress;
    }

    return (
      <div className={styles.formInputBox}>
        <label className={styles.formInputLabel} htmlFor="country">Country</label>
        <select
          id="country"
          name="country"
          value={address?.country || ''}
          onChange={handleChange}
          className={`${styles.formInput} ${styles.customSelect}`}
        >
          <option value=""></option>
          {deliveryCountries.map((countryObj, index) => (
            <option key={index} value={countryObj.country}>{countryObj.country}</option>
          ))}
        </select>
      </div>
    );
  };

  useEffect(() => {
    if (!stripe || !elements) {
      setIsCheckoutDisabled(true);
      return;
    }
    setIsCheckoutDisabled(false);
  }, [stripe, elements]);

  const mapCountryFromDeliveryCountries = async (countryCode: string | null): Promise<string | null> => {
    if (!countryCode) {
      return null;
    }

    if (deliveryCountries.length === 0) {
      console.warn("Delivery countries are empty, fetching again...");
      const { uniqueCountries } = await deliveryApi.fetchDeliveryServices();
      setDeliveryCountries(uniqueCountries);
    }

    const uppercaseCountryCode = countryCode.toUpperCase();

    const matchedCountry = deliveryCountries.find((country) =>
      country.country.startsWith(uppercaseCountryCode)
    );

    return matchedCountry ? matchedCountry.country : null;
  };

  const debouncedFetchZipCodeAndCountry = useCallback(
    debounce(async (street: string, streetNumber: string, city: string) => {
      if (street && streetNumber && city) {
        try {
          const { country, zipCode } = await MapboxApi.predictCountryAndZipCode(street, streetNumber, city);

          const resolvedCountry = await mapCountryFromDeliveryCountries(country);

          if (phone.length < 5) {
            setCountryForPhoneInput(country?.toUpperCase() || 'FR');
          }

          if (isAnonymousUser) {
            setSelectedAddress((prev) => ({
              ...prev,
              postalCode: zipCode || prev.postalCode,
              country: resolvedCountry || prev.country,
              phone: phone,
            }));
          } else {
            setNewAddress((prev) => ({
              ...prev,
              postalCode: zipCode || prev.postalCode,
              country: resolvedCountry || prev.country,
              phone: phone,
            }));
          }
        } catch (error) {
          console.error("Error fetching address from the provided details:", error);
        }
      }
    }, 1000),
    [deliveryCountries, isAnonymousUser]
  );

  const debouncedFetchCountry = useCallback(
    debounce(async (street: string, city: string) => {
      if (street && city) {
        try {
          const { country } = await MapboxApi.predictCountryCode(street, city);

          const resolvedCountry = await mapCountryFromDeliveryCountries(country);

          if (phone.length < 5) {
            setCountryForPhoneInput(country?.toUpperCase() || 'FR');
          }

          if (isAnonymousUser) {
            setSelectedAddress((prev) => ({
              ...prev,
              country: resolvedCountry || prev.country,
              phone: phone,
            }));
          } else {
            setNewAddress((prev) => ({
              ...prev,
              country: resolvedCountry || prev.country,
              phone: phone,
            }));
          }
        } catch (error) {
          console.error("Error fetching address from the provided details:", error);
        }
      }
    }, 1000),
    [deliveryCountries, isAnonymousUser]
  );

  const debouncedFetchCityAndCountry = useCallback(
    debounce(async (street: string, zipCode: string) => {
      if (street && zipCode) {
        try {
          const { city, countryCode } = await MapboxApi.predictCityAndCountryFromZipCode(street, zipCode);

          const resolvedCountry = await mapCountryFromDeliveryCountries(countryCode);

          if (phone.length < 5) {
            setCountryForPhoneInput(countryCode?.toUpperCase() || 'FR');
          }

          if (isAnonymousUser) {
            setSelectedAddress((prev) => ({
              ...prev,
              city: city || prev.postalCode,
              country: resolvedCountry || prev.country,
              phone: phone,
            }));
          } else {
            setNewAddress((prev) => ({
              ...prev,
              city: city || prev.postalCode,
              country: resolvedCountry || prev.country,
              phone: phone,
            }));
          }
        } catch (error) {
          console.error('Error fetching address from the provided details:', error);
        }
      }
    }, 1000),
    [deliveryCountries]
  );

  return (
    <div className={styles.checkoutWithHeaderContainer}>
      <HomepageNavbar />
      <>
        <div className={styles.checkoutContainer}>
          <div className={styles.checkoutForm}>

            <div className={styles.checkoutPaymentContainer}>
              <h2 className={styles.title}>CHECKOUT & PAYMENT</h2>
              {
                isAnonymousUser ?
                  <div className={styles.shippingSection}>
                    {anonymousForm()}
                  </div> :
                  <div className='addressbook_container'>
                    <div className='addressHeader'>
                      <div className={styles.sectionShippingBox}>
                        <h3 className={styles.sectionTitle}>SHIPPING TO</h3>
                        {!isAdding && !isEditing && (
                          <button className={styles.addAddressButton} onClick={handleAddNewAddress}>
                            + ADDRESS
                          </button>
                        )}
                      </div>
                    </div>
                    <div className='containerForm'>
                      {!isEditing && !isAdding && (
                        <div className={styles.addressCards}>
                          {shippingAddress.length === 0 ? (
                            <p className={styles.noItemsTitle}>NO ADDRESS YET</p>
                          ) : (
                            shippingAddress.map((address: ShippingAddress, index: number) => (
                              <div
                                key={index}
                                className={styles.addressCardWrapper}
                                onClick={() => handleSelectAddress(index)}
                                style={{ cursor: 'pointer' }}
                              >
                                <AddressCard
                                  shippingAddress={address}
                                  isSelected={selectedAddressIndex === index}
                                  onEdit={() => handleEdit(index)}
                                />
                              </div>
                            ))
                          )}
                        </div>
                      )}


                      {isAdding && (
                        <>
                          <div className={styles.formHeader}>
                            <h3 className={styles.formTitle}>ADD ADDRESS</h3>
                            <span
                              className={styles.backText}
                              onClick={() => setIsAdding(false)}
                              style={{ cursor: 'pointer' }}
                            >
                              &lt; Back
                            </span>
                          </div>
                          <form className={styles.addressForm} onSubmit={handleFormSubmit}>


                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="firstName">First Name</label>
                              <input
                                id="firstName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.first_name}
                                onChange={(e) => handleNewAddressChange('first_name', e.target.value)}
                              />
                              {newAddressErrors.first_name && <p className={styles.errorMessage}>{newAddressErrors.first_name}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="lastName">Last Name</label>
                              <input
                                id="lastName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.last_name}
                                onChange={(e) => handleNewAddressChange('last_name', e.target.value)}
                              />
                              {newAddressErrors.last_name && <p className={styles.errorMessage}>{newAddressErrors.last_name}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="phone">Phone number</label>
                              <input
                                id="phone"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.phone}
                                onChange={(e) => handleNewAddressChange('phone', e.target.value)}
                              />
                              {newAddressErrors.phone && <p className={styles.errorMessage}>{newAddressErrors.phone}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="street">Street</label>
                              <input
                                id="street"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.street}
                                onChange={(e) => handleNewAddressChange('street', e.target.value)}
                              />
                              {newAddressErrors.street && <p className={styles.errorMessage}>{newAddressErrors.street}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="streetNumber">Street Number</label>
                              <input
                                id="streetNumber"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.streetNumber}
                                onChange={(e) => handleNewAddressChange('streetNumber', e.target.value)}
                              />
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="city">State</label>
                              <input
                                id="city"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.city}
                                onChange={(e) => handleNewAddressChange('city', e.target.value)}
                              />
                              {newAddressErrors.city && <p className={styles.errorMessage}>{newAddressErrors.city}</p>}
                            </div>

                            {renderCountrySelect()}

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="postalCode">Zip Code</label>
                              <input
                                id="postalCode"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.postalCode}
                                onChange={(e) => handleNewAddressChange('postalCode', e.target.value)}
                              />
                              {newAddressErrors.postalCode && <p className={styles.errorMessage}>{newAddressErrors.postalCode}</p>}
                            </div>

                            <div className="checkbox-wrapper-48">
                              <label className={`${styles.formInputLabel} ${styles.checkboxLabel}`}>
                                <input
                                  type="checkbox"
                                  name="asDefault"
                                  checked={newAddress.asDefault}
                                  onChange={(e) => setNewAddress({ ...newAddress, asDefault: e.target.checked })}
                                />
                                <span style={{ marginLeft: '8px' }}>SAVE AS MY DEFAULT ADDRESS</span>
                              </label>
                            </div>
                            <div className={styles.formButtonContainer}>
                              <button className={styles.formButton} type="submit" disabled={!isLoggedUserFormValid()}>SAVE CHANGES</button>
                            </div>
                          </form>
                        </>
                      )}
                      {isEditing && (
                        <>
                          <div className={styles.formHeader}>
                            <h3 className={styles.formTitle}>Edit Address</h3>
                            <span
                              className={styles.backText}
                              onClick={() => setIsEditing(false)}
                              style={{ cursor: 'pointer' }}
                            >
                              &lt; Back
                            </span>
                          </div>
                          <form className={styles.addressForm} onSubmit={handleFormSubmit}>


                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="firstName">First Name</label>
                              <input
                                id="firstName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.first_name}
                                onChange={(e) => handleNewAddressChange('first_name', e.target.value)}
                              />
                              {newAddressErrors.first_name && <p className={styles.errorMessage}>{newAddressErrors.first_name}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="lastName">Last Name</label>
                              <input
                                id="lastName"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.last_name}
                                onChange={(e) => handleNewAddressChange('last_name', e.target.value)}
                              />
                              {newAddressErrors.last_name && <p className={styles.errorMessage}>{newAddressErrors.last_name}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="phone">Phone</label>
                              <input
                                id="phone"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.phone}
                                onChange={(e) => handleNewAddressChange('phone', e.target.value)}
                              />
                              {newAddressErrors.phone && <p className={styles.errorMessage}>{newAddressErrors.phone}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="street">Street</label>
                              <input
                                id="street"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.street}
                                onChange={(e) => handleNewAddressChange('street', e.target.value)}
                              />
                              {newAddressErrors.street && <p className={styles.errorMessage}>{newAddressErrors.street}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="streetNumber">Street Number</label>
                              <input
                                id="streetNumber"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.streetNumber}
                                onChange={(e) => setNewAddress({ ...newAddress, streetNumber: e.target.value })}
                              />
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="postalCode">Postal Code</label>
                              <input
                                id="postalCode"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.postalCode}
                                onChange={(e) => handleNewAddressChange('postalCode', e.target.value)}
                              />
                              {newAddressErrors.postalCode && <p className={styles.errorMessage}>{newAddressErrors.postalCode}</p>}
                            </div>

                            <div className={styles.formInputBox}>
                              <label className={styles.formInputLabel} htmlFor="city">City</label>
                              <input
                                id="city"
                                className={styles.formInput}
                                type="text"
                                value={newAddress.city}
                                onChange={(e) => handleNewAddressChange('city', e.target.value)}
                              />
                              {newAddressErrors.city && <p className={styles.errorMessage}>{newAddressErrors.city}</p>}
                            </div>

                            {renderCountrySelect()}

                            <button className={styles.formButton} type="submit">SAVE CHANGES</button>
                          </form>
                        </>
                      )}

                    </div>
                  </div>
              }
            </div>

            <div className={styles.shippingSectionService}>
              <div className={styles.sectionShippingBox}>
                <h3 className={styles.sectionTitle}>SHIPPING</h3>
              </div>
              <div className={styles.shippingOptions}>
                {filteredShippingServices.length > 0 ? (
                  <ul className={styles.shippingList}>
                    {filteredShippingServices.map((service, index) => (
                      <li key={index} className={styles.shippingItem}>
                        <label
                          className={styles.labelContainer}
                          onClick={() => handleServiceClick(service)}
                          style={{ cursor: 'pointer' }}
                        >
                          {selectedShippingService === service ? (
                            <div className={styles.selectedIcon}>
                              <img src={selectedIcon} alt="selected" />
                            </div>
                          ) : (
                            <div className={styles.unselectedIcon}>
                              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" fill="rgba(204, 185, 167, 0.5)" />
                              </svg>
                            </div>
                          )}

                          <div>
                            <div className={styles.priceServicePrice}>
                              <span className={styles.servicePrice}>€{service.price}</span> <span> — </span>
                              <span
                                className={`${styles.serviceName} ${selectedShippingService === service ? styles.selectedServiceName : ''}`}
                              >
                                {service.service}
                              </span>
                            </div>
                            <span className={styles.serviceDelivery}>Delivery between {service.timedelivery}</span>
                          </div>
                        </label>

                        {/* Show sub-options for Relay/Lockers */}
                        {expandedService === service && service.service.toLowerCase().includes('relay') && (
                          <div className={styles.subOptions}>
                            <ul className={styles.indentedRelayPoints}>
                              {relayPointsData.map((point, index) => (
                                <li key={index}>
                                  <label
                                    className={styles.labelContainer}
                                    onClick={() => setSelectedRelayPoint(point)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {selectedRelayPoint === point ? (
                                      <div className={styles.selectedIcon} style={{ width: '24px', height: '29px', marginRight: '10px' }}>
                                        <img src={selectedIcon} alt="selected" style={{ width: '20px', height: '20px' }} />
                                      </div>
                                    ) : (
                                      <div className={styles.unselectedIcon}>
                                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="12" cy="12" r="8" fill="rgba(204, 185, 167, 0.5)" />
                                        </svg>
                                      </div>
                                    )}
                                    {` ${point.LgAdr1}, ${point.LgAdr3}, ${point.Ville}, ${point.Pays}`}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles.noItemsTitle}>NO ADDRESS SELECTED</p>
                )}
              </div>
              {isMobile && <button
                className={`${styles['checkout-btn']} ${styles['desktop-checkout-btn']}`}
                onClick={handleCheckout}
                disabled={!isFormValid() || checkForErrors() || isCheckoutDisabled}
              >
                CONFIRM ORDER
              </button>}
              {isMobile && (errorMessage && <p className="error-message" style={{ textAlign: 'center', marginTop: '15px' }}>{errorMessage}</p>)}
            </div>
          </div>

          <div className={styles.orderSummary}>
            <div className={styles.sectionShippingBox}>
              <h3 className={styles.sectionTitle}>PACKAGE ({getTotalItems()})</h3>
            </div>
            <div className={styles.productListScroll}>

              {products.map((product, index) => (
                <div key={index} className={styles.product}>
                  <img src={product.imgUrl} alt={product.title} className={styles.productImage} />
                  <div className={styles.productDetails}>
                    <h5>{product.brand}</h5>
                    <div className={styles.productDesc}>
                      {product ? (
                        <>
                          <p className={styles.productDescTitle}>{product.title}</p>
                          <p className={styles.productDescSize}>{product.quantity}ml</p>
                          {renderItemActions(cartItems[index])}
                        </>
                      ) : (
                        <p>Product not available</p>
                      )}
                    </div>
                  </div>
                  <p className={styles.productPrice}>
                    {cartItems[index]?.quantity && product?.price
                      ? `${cartItems[index].quantity} * €${Number(product.price).toFixed(2)}`
                      : ''}
                  </p>
                </div>
              ))}
            </div>
            {cartItems && cartItems.length > 0 ? (
              <div className={styles.cartFooter}>
                <div className={styles.totalSection}>
                  <div className={styles.sumupDetails}>
                    <div className={styles.totalRow}>
                      <span className={styles.subtotalDiv} style={{ fontSize: '14px' }}><span>SUBTOTAL</span><span> ({getTotalItems()})</span></span>
                      <span className={styles.subtotalDivPrice}>€{calculatePrice(cartItems, selectedShippingService)}</span>
                    </div>
                    <div className={styles.totalRow}>
                      <span>SHIPMENT </span>
                      <span className={styles.subtotalDivPrice}>
                        {selectedShippingService ? (
                          parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >= selectedShippingService.freeshipping ? (
                            <>
                              <span className={styles.strikethroughPrice}>€{selectedShippingService.price.toFixed(2)}</span>
                              <br />
                              <span>€0.00</span>
                            </>
                          ) : (
                            `€${selectedShipmentWithoutTax(selectedShippingService).toFixed(2)}`
                          )
                        ) : (
                          <span>€0.00</span>
                        )}
                      </span>
                    </div>


                    <div className={styles.totalRow}>
                      <span>TAXES</span>
                      <span className={styles.subtotalDivPrice}>€{calculateTaxes(cartItems, selectedShippingService)}</span>
                    </div>
                    <div className={styles.totalRow}>
                      <strong className={styles.totalPrice}>TOTAL</strong>
                      <strong className={styles.totalPrice}>
                        €{selectedShippingService &&
                          parseFloat(calculateTotalAmountIncludingTax(cartItems, selectedShippingService)) >= selectedShippingService.freeshipping
                          ? calculateTotalAmountIncludingTax(cartItems, selectedShippingService)
                          : calculateTotalAmountIncludingAllTax(cartItems, selectedShippingService)
                        }
                      </strong>
                    </div>
                  </div>
                  {!isMobile && <button
                    className={`${styles['checkout-btn']} ${styles['desktop-checkout-btn']}`}
                    onClick={handleCheckout}
                    disabled={!isFormValid() || checkForErrors() || isCheckoutDisabled}
                  >
                    CONFIRM ORDER
                  </button>}
                  {!isMobile && (errorMessage && <p className="error-message" style={{ textAlign: 'center' }}>{errorMessage}</p>)}
                </div>
              </div>
            ) : (
              <p>No Items in Cart</p>
            )}
          </div>
        </div>
      </>
    </div >
  );
};

export default CheckoutPage;