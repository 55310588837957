import React from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import styles from './AccountInfo.module.scss';

export const AccountInfo: React.FC = () => {
    const [user] = useAtom(userAtom);
    const isMobile = window.innerWidth < 768;
    const calculateAge = (birthDateString: string | undefined): string => {
        if (!birthDateString) return 'Not available';

        const birthDate = new Date(birthDateString);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return `${age} yo`;
    };

    return (
        <div className={isMobile ? styles.userHeaderMobile : styles.userHeader}>
            <div className={styles.headerContainer}>
                <div className={styles.titleButtonContainer}>
                    <h3 className={styles.accountInfoTitle}>Account Information</h3>
                    <Link to="edit-account" className={styles.editButton}>EDIT</Link>
                </div>
            </div>
            <div className={styles.userData}>
                <div className={styles.userDetails}>
                    <div className={styles.userNameEmail}>
                        <p className={styles.detailsText}>{user?.name || 'Not available'}</p>
                        <p className={styles.detailsText}>{user?.email || 'Not available'}</p>
                    </div>
                    <div className={styles.userLastnamePhone}>
                        <p className={styles.detailsText}>{user?.lastName || 'Not available'}</p>
                        {/* <p className={styles.detailsText}>{user?.phoneNumber?.toString() || 'Not available'}</p> */}
                    </div>
                    <p className={styles.detailsText}>{calculateAge(user?.SPF?.age)}</p>
                </div>
            </div>
        </div>
    );
};
