import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { ProductsCarousel } from 'components/carousels/ProductsCarousel';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { MobileCarousel } from 'components/carousels/mobileCarousel';

export const SimmilarProducts: React.FC = () => {
  const [activeGroup, setActiveGroup] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const swiperRef = useRef<any>(null);


  const handleNumberClick = (groupIndex: number) => {
    setActiveGroup(groupIndex);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo((groupIndex - 1) * 4);
    }
  };

  const handleSlideChange = (swiper: any) => {
    const groupIndex = Math.floor(swiper.activeIndex / 4) + 1; // Adjust for items per group
    setActiveGroup(groupIndex);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);




  return (
    <>
      {isMobile && (
        <p className="mobile-header">
          SIMILAR PRODUCTS <br /> RECOMMENDED BY OLI'S
        </p>
      )}

      <div className="recommandedContainer" style={{ backgroundColor: '#F7F6E7' }}>
        <div className="BSheaderBlock">
          {!isMobile && (
            <p className="header">SIMILAR PRODUCTS RECOMMENDED BY OLI'S</p>
          )}
        </div>

        {window.innerWidth > 768 ? (
          <ProductsCarousel onSlideChange={handleSlideChange} carouselId={16} ref={swiperRef} />
        ) : (
          <MobileCarousel onSlideChange={handleSlideChange} carouselId={16} />
        )}


        <div className="indicatorContainer">
          <ScrollNumberIndicator
            count={3} // Total number of groups
            activeNumber={activeGroup}
            fontSize={13}
            onNumberClick={handleNumberClick}
            color="black"
          />
        </div>
      </div>
    </>
  );
};
