// ShopHeadBestsellers.js
import styles from './style.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import HeaderBackgroundImage from 'assets/images/101.jpg'; // Default background image

interface ShopHeadBestsellersProps {
  backgroundImage?: string;
  brandsCount?: number;
}

export const ShopHeadBestsellers = ({ backgroundImage = HeaderBackgroundImage, brandsCount }: ShopHeadBestsellersProps) => {
  // Use the provided backgroundImage if available, otherwise use the default
  const effectiveBackgroundImage = backgroundImage || HeaderBackgroundImage;
  const hasBrandsCount = typeof brandsCount === 'number';

  return (
    <div
      className={styles.shopHeaderBlock}
      style={{
        backgroundImage: `url(${effectiveBackgroundImage})`
      }}
    >
      <HomepageNavbar />
      <div className={styles.shopHeaderBlockContent}>

        {!hasBrandsCount ? (
          <div className={styles.bestsellerHeaderAdviceContainer}>
            <p className={styles.bestsellerHeaderAdvice}>
              BESTSELLERS
            </p>
          </div>
        ) : (
          <div className={styles.bestsellerHeaderAdviceContainer}>
            <p className={styles.bestsellerHeaderAdvice}>
              HOT BRANDS
            </p>
          </div>
        )}
        {!hasBrandsCount ? (
          <div className={styles.bestsellerHeaderRightTextContainer}>
            <p className={styles.bestsellerHeaderRightText}>
              {hasBrandsCount ? ` ${brandsCount} Brands` : "Finest Selection"}
            </p>
          </div>
        ) : (
          <div className={styles.hotbrandcounter}>
            <p className={styles.hotbrandcountertext}>
              {hasBrandsCount ? ` ${brandsCount} Brands` : "Finest Selection"}
            </p>
          </div>
        )}
        {hasBrandsCount ? (
          <div className={styles.hotBrandsHeaderAdviceContainer}>
            <p className={styles.hotBrandsHeaderAdvice}>Available now</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};
