import React, { useState, useEffect, useCallback } from 'react';
import userService from '../../../../../service/UserService';
import { userAtom } from '../../../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { DeliveryService } from '../../../../../types/TInterfaces';
import deliveryApi from '../../../../../service/API/deliveryApi';
import styles from './AddressForm.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from 'pages/ProfilePage/ProfileMenu/ProfileMenu';
import { AddressBook } from '../AddressBook/AddressBook';
import { debounce } from 'utils/debounceUtil';
import MapboxApi from 'service/API/mapboxApi';

export const AddressForm: React.FC = () => {
    const { addressId } = useParams<{ addressId?: string }>();
    const [user, setUser] = useAtom(userAtom);
    const [address, setAddress] = useState<Record<string, any>>({
        addressName: '',
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        city: '',
        street: '',
        streetNumber: '',
        postalCode: '',
        addressComplement: '',
        asDefault: false
    });
    const [deliveryCountries, setDeliveryCountries] = useState<Pick<DeliveryService, 'country'>[]>([]);
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;
    const [addClicked, setAddClicked] = useState(false);

    useEffect(() => {
        const getDeliveryCountries = async () => {
            const { uniqueCountries } = await deliveryApi.fetchDeliveryServices();
            setDeliveryCountries(uniqueCountries);
        };

        getDeliveryCountries();
    }, []);

    useEffect(() => {
        if (addressId && user) {
            const addressToEdit = user.addresses.find((addr) => addr._id === addressId);
            if (addressToEdit) {
                const isFirstAddress = user.addresses.indexOf(addressToEdit) === 0;

                setAddress({
                    ...addressToEdit,
                    phone: addressToEdit.phoneNumber?.number,
                    asDefault: isFirstAddress ? true : addressToEdit.asDefault
                });
            }
        }
    }, [addressId, user]);

    const mapCountryFromDeliveryCountries = async (countryCode: string | null): Promise<string | null> => {
        if (!countryCode) {
            return null;
        }

        if (deliveryCountries.length === 0) {
            console.warn("Delivery countries are empty, fetching again...");
            const { uniqueCountries } = await deliveryApi.fetchDeliveryServices();
            setDeliveryCountries(uniqueCountries);
        }

        const uppercaseCountryCode = countryCode.toUpperCase();

        const matchedCountry = deliveryCountries.find((country) =>
            country.country.startsWith(uppercaseCountryCode)
        );

        return matchedCountry ? matchedCountry.country : null;
    };

    const debouncedFetchZipCodeAndCountry = useCallback(
        debounce(async (street: string, streetNumber: string, city: string) => {
            if (street && streetNumber && city) {
                try {
                    const { country, zipCode } = await MapboxApi.predictCountryAndZipCode(street, streetNumber, city);

                    const resolvedCountry = await mapCountryFromDeliveryCountries(country);

                    setAddress((prev) => ({
                        ...prev,
                        postalCode: zipCode || prev.postalCode,
                        country: resolvedCountry || prev.country, // Use the resolved value
                    }));
                } catch (error) {
                    console.error('Error fetching address from the provided details:', error);
                }
            }
        }, 1000),
        [deliveryCountries]
    );

    const debouncedFetchCityAndCountry = useCallback(
        debounce(async (street: string, zipCode: string) => {
            if (street && zipCode) {
                try {
                    const { city, countryCode } = await MapboxApi.predictCityAndCountryFromZipCode(street, zipCode);

                    const resolvedCountry = await mapCountryFromDeliveryCountries(countryCode);

                    setAddress((prev) => ({
                        ...prev,
                        city: city || prev.city,
                        country: resolvedCountry || prev.country,
                    }));
                } catch (error) {
                    console.error('Error fetching address from the provided details:', error);
                }
            }
        }, 1000),
        [deliveryCountries]
    );

    const debouncedFetchCountry = useCallback(
        debounce(async (street: string, city: string) => {
            if (street && city) {
                try {
                    const { country } = await MapboxApi.predictCountryCode(street, city);

                    const resolvedCountry = await mapCountryFromDeliveryCountries(country);

                    setAddress((prev) => ({
                        ...prev,
                        country: resolvedCountry || prev.country,
                    }));
                } catch (error) {
                    console.error('Error fetching address from the provided details:', error);
                }
            }
        }, 1000),
        [deliveryCountries]
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const isCheckbox = type === 'checkbox';

        let updatedAddress = {
            ...address,
            [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value,
        };

        setAddress(updatedAddress);

        if (
            (name === 'street' || name === 'streetNumber' || name === 'city') &&
            updatedAddress.street && updatedAddress.streetNumber && updatedAddress.city
        ) {
            debouncedFetchZipCodeAndCountry(
                updatedAddress.street,
                updatedAddress.streetNumber,
                updatedAddress.city
            );
        } else if ((name === 'street' || name === 'city') &&
            updatedAddress.street && updatedAddress.city) {
            debouncedFetchCountry(
                updatedAddress.street,
                updatedAddress.city
            );
        } else if ((name === 'street' || name === 'postalCode') &&
            updatedAddress.street, updatedAddress.postalCode) {
            debouncedFetchCityAndCountry(
                updatedAddress.street,
                updatedAddress.postalCode
            )
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!user) {
            console.error('User not found. Please log in to add an address.');
            return;
        }

        const requiredFields = [
            'addressName', 'firstName', 'lastName',
            'phone', 'country', 'city', 'street', 'streetNumber',
            'postalCode'
        ];

        for (const field of requiredFields) {
            const value = address[field];
            if (!value) {
                alert(`${field.replace(/([A-Z])/g, ' $1').toUpperCase()} is required.`);
                return;
            }
        }

        try {
            if (addressId) {
                const result = await userService.updateDeliveryAddress(user, setUser, address, addressId, navigate);
                if (result?.success) {
                    const userDataResult = await userService.getUserData(user, setUser);

                    if (!userDataResult.success) {
                        console.error('Failed to fetch updated user data');
                    }

                    clearFields();
                    navigate(-1);
                } else {
                    console.error(`Failed to update address: ${result?.message}`);
                    alert(`Failed to update address: ${result?.message}`);
                }
            } else {
                const result = await userService.addDeliveryAddress(user, setUser, address, navigate);
                if (result.success) {
                    const userDataResult = await userService.getUserData(user, setUser);

                    if (!userDataResult.success) {
                        console.error('Failed to fetch updated user data');
                    }

                    clearFields();
                    navigate(-1);
                } else {
                    console.error(`Failed to add address: ${result.message}`);
                    alert(`Failed to add address: ${result.message}`);
                }
            }
        } catch (error) {
            console.error('An error occurred while saving the address:', error);
            alert('An error occurred while saving the address. Please try again.');
        }
    };

    const handleDelete = async (id: string) => {
        if (!user) {
            console.error('User not found. Please log in to delete an address.');
            return;
        }
        const result = await userService.deleteDeliveryAddress(user, setUser, id, navigate)
        if (result.success) {
            navigate(-1)
        } else {
            alert(result.message)
        }
    }
    const clearFields = () => {
        setAddress({
            addressName: '',
            firstName: '',
            lastName: '',
            phone: '',
            country: '',
            city: '',
            street: '',
            streetNumber: '',
            postalCode: '',
            addressComplement: '',
            asDefault: false
        });
    };

    const handleCancel = () => {
        navigate('/profile')
    }

    const handleBack = () => {
        navigate(-1);
    };

    const handleAdd = () => {
        setAddClicked(true)
    }

    const renderCountrySelect = () => (
        <div >
            <select className={styles.selectCountry} name="country" value={address.country || ''} onChange={handleChange} required>
                <option value="">SELECT A COUNTRY</option>
                {deliveryCountries.map((countryObj, index) => (
                    <option key={index} value={countryObj.country}>{countryObj.country}</option>
                ))}
            </select>
        </div>
    );

    const mobileLayout = (
        <div className={styles.mobileContainer}>
            <HomepageNavbar />

            <div className={styles.mobileHeaderSection}>
                <h3 className={styles.mobileTitle}>Address Book</h3>
                {(!addClicked && !addressId) && (
                    <button className={styles.mobileAddBtn} onClick={handleAdd}>
                        + ADD
                    </button>
                )}
            </div>

            {(addClicked || addressId) ? (
                <>
                    <h3 className={styles.mobileSubTitle}>Add ADDRESS</h3>
                    <form onSubmit={handleSubmit} className={styles.mobileForm}>
                        <input
                            className={styles.mobileFields}
                            type="text"
                            name="addressName"
                            placeholder="ADDRESS NAME"
                            value={address.addressName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className={styles.mobileFields}
                            type="text"
                            name="firstName"
                            placeholder="FIRST NAME"
                            value={address.firstName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className={styles.mobileFields}
                            type="text"
                            name="lastName"
                            placeholder="LAST NAME"
                            value={address.lastName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className={styles.mobileFields}
                            type="text"
                            name="phone"
                            placeholder="PHONE NUMBER"
                            value={address.phone}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className={styles.mobileFields}
                            type="text"
                            name="street"
                            placeholder="STREET"
                            value={address.street}
                            onChange={handleChange}
                            required
                        />
                        <input
                            className={styles.mobileFields}
                            type="text"
                            name="streetNumber"
                            placeholder="STREET NUMBER"
                            value={address.streetNumber}
                            onChange={handleChange}
                        />
                        <input
                            className={styles.mobileFields}
                            type="text"
                            name="addressComplement"
                            placeholder="ADDRESS EXTRA DETAILS"
                            value={address.addressComplement}
                            onChange={handleChange}
                        />
                        <input
                            className={styles.shortFields}
                            type="text"
                            name="city"
                            placeholder="STATE"
                            value={address.city}
                            onChange={handleChange}
                            required
                        />
                        {renderCountrySelect()}
                        <input
                            className={styles.shortFields}
                            type="text"
                            name="postalCode"
                            placeholder="ZIP CODE"
                            value={address.postalCode}
                            onChange={handleChange}
                            required
                        />
                        <div className={styles.checkboxWrapper48}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="asDefault"
                                    checked={address.asDefault}
                                    onChange={handleChange}
                                />
                                SAVE AS MY DEFAULT ADDRESS
                            </label>
                        </div>

                        <div className={styles.btnsContainer}>
                            <button type="submit" className={styles.submitButton}>
                                Save Changes
                            </button>
                            <button type="button" className={styles.cancelBtn} onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <AddressBook />
            )}
        </div>

    )


    const desktopLayout = (
        <div className={styles.desktopContainer}>
            <HomepageNavbar />

            <div className={styles.topSection}>
                <div className={styles.menuTopSection}>
                    {/* PLACE HOLDER DONT REMOVE */}
                </div>
                <div className={styles.contentTopSection}>
                    <h3 className={styles.userName}>{user?.name}</h3>
                    <h3 className={styles.userName}>{user?.lastName}</h3>
                </div>
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.menuSection}>
                    <ProfileMenu />
                </div>
                <div className={styles.contentSection}>
                    <button className={styles.btnBack} onClick={handleBack}>{'< BACK'}</button>
                    <div className={styles.formHeader}>
                        <h5>{addressId ? 'EDIT ADDRESS' : 'ADD ADDRESS'}</h5>
                        <button className={styles.btnDelete} onClick={() => handleDelete(addressId || '')}>DELETE</button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.doubleFields}>
                            <input
                                type="text"
                                name="addressName"
                                placeholder="ADDRESS NAME"
                                value={address.addressName}
                                onChange={handleChange}
                                required
                            />
                            <input style={{ border: 'none' }} /> {/* place holder */}
                        </div>
                        <div className={styles.doubleFields}>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="FIRST NAME"
                                value={address.firstName}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="text"
                                name="lastName"
                                placeholder="LAST NAME"
                                value={address.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.doubleFields}>
                            <input
                                type="text"
                                name="phone"
                                placeholder="PHONE NUMBER"
                                value={address.phone}
                                onChange={handleChange}
                                required
                            />
                            <input style={{ border: 'none' }} /> {/* place holder */}
                        </div>
                        <div className={styles.doubleFields}>
                            <input
                                type="text"
                                name="street"
                                placeholder="STREET"
                                value={address.street}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="text"
                                name="streetNumber"
                                placeholder="STREET NUMBER"
                                value={address.streetNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.doubleFields}>
                            <input
                                type="text"
                                name="addressComplement"
                                placeholder="ADDRESS EXTRA DETAILS"
                                value={address.addressComplement}
                                onChange={handleChange}
                            />
                            <input style={{ border: 'none' }} /> {/* place holder */}
                        </div>
                        <div>
                            <input
                                className={styles.shortFields}
                                type="text"
                                name="city"
                                placeholder="STATE"
                                value={address.city}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {renderCountrySelect()}
                        <div>
                            <input
                                className={styles.shortFields}
                                type="text"
                                name="postalCode"
                                placeholder="ZIP CODE"
                                value={address.postalCode}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.checkboxWrapper48}>
                            <label>
                                <input
                                    type="checkbox"
                                    name="asDefault"
                                    checked={address.asDefault}
                                    onChange={handleChange}
                                />
                                SAVE AS MY DEFAULT ADDRESS
                            </label>
                        </div>
                        <button className={styles.submitBtn} type="submit">
                            {addressId ? 'SAVE' : 'ADD ADDRESS'}
                        </button>
                    </form>
                </div>
            </div >
        </div >
    );

    return isMobile ? mobileLayout : desktopLayout;
};
