import React from 'react';
import { useNavigate } from 'react-router-dom';
import Image from 'assets/images/86.jpg';
import styles from './BrownBox.module.scss';
import img1 from 'assets/images/comingSoonImg1.png'
import img2 from 'assets/images/comingSoonImg2.png'
import img3 from 'assets/images/comingSoonImg3.png'

export const BrownBox: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className={styles.largeContainer}>
                {/* First container */}
                <div className={styles.saferIngredientsContainer}>
                    <div className={styles.textContent}>
                        <p className={styles.saferIngredientsHeaderDescription}>Revolutionize your retail experience</p>
                        <p className={styles.saferIngredientsHeaderHeader}>
                            (re)define ingredients that are right for your skin
                        </p>
                    </div>
                    <div className={styles.saferIngredientsContainerIMG} style={{ backgroundImage: `url(${img1})` }} />
                </div>

                {/* Second container */}
                <div className={styles.saferIngredientsContainer}>
                    <div className={styles.textContent}>
                        <p className={styles.saferIngredientsHeaderDescription}>Revolutionize your retail experience</p>
                        <p className={styles.saferIngredientsHeaderHeader}>
                            Get inspired by our curated collection of clean brands
                        </p>
                    </div>
                    <div className={styles.saferIngredientsContainerIMG} style={{ backgroundImage: `url(${img2})` }} />
                </div>

                {/* Third container */}
                <div className={styles.saferIngredientsContainer}>
                    <div className={styles.textContent}>
                        <p className={styles.saferIngredientsHeaderDescription} style={{ color: '#5D5246' }}>Revolutionize your retail experience</p>
                        <p className={styles.saferIngredientsHeaderHeader} style={{ color: '#5D5246' }}>
                            personalise your unique skincare experience
                        </p>
                    </div>
                    <div className={styles.saferIngredientsContainerIMG} style={{ backgroundImage: `url(${img3})` }} />
                </div>
            </div>
        </>
    );
};
