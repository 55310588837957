import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useNotionActives } from 'hooks/useNotionArticles';
import ahas from '../../../assets/images/ahas.jpg';
import allantoin from '../../../assets/images/allantoin.jpg';
import { Active } from '../../../types/TInterfaces';
import { ShopProduct } from 'types/ShopInterfaces';
import aloevera from '../../../assets/images/aloevera.jpg';
import activesApi from '../../../service/API/activesApi';
import './style.scss';
import oneActiveBg from '../../../assets/images/OneActiveBg.png';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import productsApi from 'service/API/ProductsApi';
import { ActiveRelatedProducts } from 'components/carousels/ActiveRelatedProducts';
import activesBg from '../../../assets/images/activesListBg.png';

export const OneActive: React.FC = () => {
    const [relatedProducts, setRelatedProducts] = useState<ShopProduct[]>([]);
    const { activeId } = useParams<{ activeId: string }>();
    const navigate = useNavigate();
    const [selectedMenuItems, setSelectedMenuItems] = useState<Set<number>>(new Set());
    const [active, setActive] = useState<Active | null>(null);



    const fetchActiveById = async (activeId: string) => {
        try {

            const response = await activesApi.getActiveById(activeId);
            if (response.success && response.data) {
                const activeData = response.data;

                if (activeData.element_of_group && activeData.element_of_group.length > 0) {
                    const elementOfGroupIds = activeData.element_of_group.map((element: any) => element.id);

                    const relatedActivesResponse = await fetchActivesByElementOfGroupId(elementOfGroupIds);
                    if (!relatedActivesResponse?.data) return;

                    const relatedActives = relatedActivesResponse.data;
                    const updatedElementOfGroup = activeData.element_of_group.map((element: any) => {
                        const relatedActive = relatedActives.find((active: any) => active.notion_id === element.id);

                        if (relatedActive) {
                            return {
                                ...element,
                                group_elements: relatedActive.group_elements || [],
                                synonyms: relatedActive.synonyms || [],
                            };
                        }
                        return element;
                    });


                    activeData.element_of_group = updatedElementOfGroup;
                }


                setActive(activeData);

            } else {
                console.log(response.message || 'Failed to fetch active');
            }
        } catch (err) {
            console.log('Error fetching active:', err);
        }
    };


    useEffect(() => {
        if (activeId) {
            fetchActiveById(activeId);
        }
    }, [activeId]);

    const fetchProductsByActiveName = async () => {
        if (!active) return;

        const groupElementsSynonyms = active.group_elements?.flatMap(element =>
            element.synonyms?.map(syn => syn.active_name) || []
        ) || [];

        try {
            const response = await productsApi.getProductsByActiveName(
                active.active_name,
                active.group_elements?.map((el) => el.active_name) || [],
                [...(active.synonyms?.map((syn) => syn.active_name) || []), ...groupElementsSynonyms]
            );

            if (response.success && response.data) {
                setRelatedProducts(response.data);
            } else {
                console.error(response.message || 'Failed to fetch products');
            }
        } catch (err) {
            console.error('Error fetching products by active name:', err);
        }
    };

    useEffect(() => {
        if (active) {
            fetchProductsByActiveName();
        }
    }, [active]);

    const fetchActivesByElementOfGroupId = async (elementOfGroupIds: string[]) => {
        try {
            const response = await activesApi.getActivesByElementOfGroupId(elementOfGroupIds);
            if (response.success && response.data) {
                return { success: true, data: response.data as Active[] };
            } else {
                console.log(response.message || 'Failed to fetch actives');
                return { success: false, data: null };
            }
        } catch (err) {
            console.log('Failed to fetch actives by elementOfGroupId');
            return { success: false, data: null };
        }
    };


    const getContentForSection = (index: number) => {
        if (!active) return '';

        switch (index) {
            case 0:
                return active.description;
            case 1:
                return active.benefits;
            case 2:
                return active.how_to_use;
            case 3:
                return active.add_to_routine;
            case 4:
                return '';
            default:
                return '';
        }
    };

    const handleMenuClick = (index: number) => {
        const newSelectedMenuItems = new Set(selectedMenuItems);
        if (newSelectedMenuItems.has(index)) {
            newSelectedMenuItems.delete(index);
        } else {
            newSelectedMenuItems.add(index);
        }
        setSelectedMenuItems(newSelectedMenuItems);
    };

    return (
        <div className="article-detail-container">
            <HomepageNavbar />
            <div className="article-image-section">
                <img
                    src={active?.image || activesBg}
                    alt={active?.active_name}
                    className="article-image"
                />
                <div className="article-image-overlay"></div>
                <div className="article-meta">
                    <div className="article-header">
                        <h1 className="article-title">{active?.active_name}</h1>
                        <div className="article-category">
                            {active?.functions_tags?.map((func) => (
                                <span key={func.id} className="function-badge">
                                    {func.name}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="article-content-section">
                <div className="article-middle-text">
                    <h2 className='active-desc-header'>What is {active?.active_name}</h2>
                    <h2 className='active-desc'>
                        {active?.description}
                    </h2>
                </div>
                <div className="menu-container">
                    {['description', 'benefits', 'how to use', 'add to my routine', 'products'].map((item, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <div className="menu-item" onClick={() => handleMenuClick(index)}>
                                <span className="menu-number">{String(index + 1).padStart(2, '0')}</span> {item} <span className="menu-plus">{selectedMenuItems.has(index) ? '-' : '+'}</span>
                            </div>

                            {selectedMenuItems.has(index) && (
                                <div className="menu-item-text-section">
                                    {index === 4 ? (
                                        relatedProducts.length > 0 ? (
                                            <ActiveRelatedProducts
                                                relatedProducts={relatedProducts.map(product => ({
                                                    _id: product._id,
                                                    imgUrl: product.imgUrl,
                                                    title: product.title,
                                                    brand: product.brand,
                                                }))}
                                            />
                                        ) : (
                                            <div className="no-products-message">
                                                <p>Products with {active?.active_name} are coming soon.</p>
                                                <p>Meanwhile discover our <Link to="/bestsellers" className="bestsellers-link">bestsellers</Link></p>
                                            </div>
                                        )
                                    ) : (
                                        getContentForSection(index)
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>


            </div>
        </div>
    );
};

