import axios from 'axios';
import { config } from 'config';

const MAPBOX_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const MAPBOX_ACCESS_TOKEN = config.REACT_APP_MAPBOX_ACCESS_TOKEN;

class MapboxApi {
    // Predicting the country and the zipcode using the street number, street, and city.
    static async predictCountryAndZipCode(
        street: string,
        streetNumber: string,
        city: string
    ): Promise<{ country: string | null; zipCode: string | null }> {
        try {
            // Construct the query for the Mapbox API
            const query = `${streetNumber} ${street}, ${city}`;

            const response = await axios.get(`${MAPBOX_BASE_URL}/${encodeURIComponent(query)}.json`, {
                params: {
                    access_token: MAPBOX_ACCESS_TOKEN,
                    autocomplete: true,
                },
            });

            const features = response.data.features;
            if (features && features.length > 0) {
                const context = features[0].context || [];

                const zipCode = context.find((item: { id: string; text: string }) =>
                    item.id.startsWith('postcode')
                )?.text || null;

                const country = context.find((item: { id: string; short_code: string }) =>
                    item.id.startsWith('country')
                )?.short_code || null;

                return { country, zipCode };
            }

            return { country: null, zipCode: null };
        } catch (error) {
            console.error('Error predicting country and ZIP code:', error);
            throw error;
        }
    }

    static async predictCountryCode(
        street: string,
        city: string
    ): Promise<{ country: string | null }> {
        try {
            // Construct the query for the Mapbox API
            const query = `${street}, ${city}`;

            const response = await axios.get(`${MAPBOX_BASE_URL}/${encodeURIComponent(query)}.json`, {
                params: {
                    access_token: MAPBOX_ACCESS_TOKEN,
                    autocomplete: true,
                },
            });

            const features = response.data.features;
            if (features && features.length > 0) {
                const context = features[0].context || [];
                const country = context.find((item: { id: string; short_code: string }) =>
                    item.id.startsWith('country')
                )?.short_code || null;

                return { country: country };
            }

            return { country: null };
        } catch (error) {
            console.error('Error predicting country and ZIP code:', error);
            throw error;
        }
    }

    static async predictCityAndCountryFromZipCode(
        street: string,
        zipCode: string
    ): Promise<{ city: string | null; countryCode: string | null }> {
        try {
            const query = `${street}, ${zipCode}`;

            const response = await axios.get(`${MAPBOX_BASE_URL}/${encodeURIComponent(query)}.json`, {
                params: {
                    access_token: MAPBOX_ACCESS_TOKEN,
                    autocomplete: true,
                },
            });

            const features = response.data.features;
            if (features && features.length > 0) {
                const context = features[0].context || [];
                const city = context.find((item: { id: string }) => item.id.startsWith('place'))?.text || null;
                const countryCode = context.find((item: { id: string; short_code: string }) =>
                    item.id.startsWith('country')
                )?.short_code || null;

                return { city: city, countryCode: countryCode };
            }

            return { city: null, countryCode: null };
        } catch (error) {
            console.error('Error predicting city and country from ZIP code:', error);
            throw error;
        }
    }

}

export default MapboxApi;
