import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.scss';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import { useAtom } from 'jotai';
import { componentLoadingStatusAtom } from 'stores/UserProfile';

export const ArticlesCarousel: React.FC = () => {
  const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);
  const { data: articles, isLoading, error } = useNotionArticles();
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (!isLoading) {
      console.log("Loading complete, setting ProductsCarousel status to true.");
      setComponentStatuses((prev: any) => ({ ...prev, articlesCarousel: true }));
    }
  }, [isLoading, setComponentStatuses]);

  if (isLoading) {
    return null;
  }


  if (error) {
    console.error('failed to load carousel:', error);
    return null;
  }

  if (!articles || articles.length === 0) {
    return <div>No articles found.</div>;
  }

  const handleReadMore = (articleId: string) => {
    window.location.href = `/feed/article/${articleId}`;
  };
  return (
    <div className="ArticlesCarouselContainer">
      <Swiper
        spaceBetween={20}
        slidesPerView={isMobile ? 1.2 : 3}
        centeredSlides={false}
        loop={true}
        grabCursor={true}
        pagination={{ clickable: true }}
      >
        {articles.map((item) => {
          // Pick a random dossier name if there are multiple
          const randomDossierName =
            item.dossierNames && item.dossierNames.length > 0
              ? item.dossierNames[Math.floor(Math.random() * item.dossierNames.length)]
              : null;

          return (
            <SwiperSlide key={item.id}>
              <div
                className="artilceSliderItemContainer"
                style={{
                  backgroundImage: `url(${item.properties.Image.files[0]?.file.url})`,
                  width: window.innerWidth < 360 ? '97%' : '100%',
                  height: !isMobile ? "50svh" : 500,
                  borderRadius: 20,
                  marginTop: 40,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  paddingBottom: 30,
                  alignItems: 'center',
                }}
              >
                <div
                  className="ArticleCarouselItemReadMore"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReadMore(item.id);
                  }}
                >
                  <p className="ArticleCarouselItemTitle">{item.properties.Title.title[0]?.plain_text}</p>
                  {randomDossierName && (
                    <p className="ArticleCarouselItemDescription">
                      <span className="dossierName">{randomDossierName}</span>
                    </p>
                  )}
                  <DefaultButton buttonColor="lightYellow" text="READ MORE" />
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
