import React, { useRef, useState } from 'react';
import styles from './style.module.scss';
import { BrandProductsCarousel } from 'components/carousels/BrandProductsCarousel';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../config';

export const BestSellersBrandSection: React.FC = () => {
    const sliderRef = useRef<any>(null); // Reference to the Swiper instance
    const navigate = useNavigate();
    const [activeGroup, setActiveGroup] = useState(1);

    const handleSlideChange = (groupIndex: number) => {
        setActiveGroup(groupIndex); // Update the active group when slides change
    };

    const handleNumberClick = (groupIndex: number) => {
        setActiveGroup(groupIndex); // Update active group
        if (sliderRef.current?.swiper) { // Check if swiper instance exists
            const slidesPerGroup = 4; // Define how many slides per group
            const targetSlide = (groupIndex - 1) * slidesPerGroup; // Calculate target slide index
            sliderRef.current.swiper.slideTo(targetSlide); // Move to the target slide
        }
    };

    return (
        <div className={styles.bestsellersContainer} style={{ backgroundColor: '#F7F6E7' }}>

            <div className={styles.headerBrandingContainer}>
                <p className={styles.headerBranding}>{config.REACT_APP_BRAND_NAME}</p>
            </div>

            <div className={styles.indicatorContainer}>
                <ScrollNumberIndicator
                    count={2} // Total number of groups
                    activeNumber={activeGroup} // Current active group
                    fontSize={12}
                    onNumberClick={handleNumberClick} // Function to handle clicks on numbers
                    color="black"
                />
            </div>

            {/* Pass the sliderRef to BrandProductsCarousel */}
            <div className={styles.BrandCarouselContainer}>
                <BrandProductsCarousel ref={sliderRef} onSlideChange={handleSlideChange} />
            </div>
            <div className={styles.BrandShopButtonContainer}>
                <div className={styles.BrandShopButton}>
                    <DefaultButton
                        text="Shop products"
                        onClick={() => navigate(`/brand/${config.REACT_APP_BRAND_NAME}`)}
                    />
                </div>
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <DefaultButton
                    text="Shop products"
                    onClick={() => navigate(`/brand/${config.REACT_APP_BRAND_NAME}`)}
                />
            </div> */}
        </div>
    );
};
