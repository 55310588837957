import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import './style.scss';
import { RecommandedProducts } from 'components/homepage/Recommended/RecommandedProducts';
import { RecentlyViewed } from 'components/homepage/RecentlyViewed/RecentlyViewed';
import { SimmilarProducts } from 'components/homepage/SimilarProducts/SimilarProducts';
import productsApi from '../../../src/service/API/ProductsApi';
import { IProduct, IProductInCart } from '../../types/TInterfaces';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { userAtom } from '../../stores/UserProfile';
import { useAtom } from 'jotai';
import userService from '../../service/UserService';
import { useNavigate } from 'react-router-dom';
import { loadLocalCart, addToLocalCart } from '../../service/localCartService';
import { cartItemsAtom } from '../../stores/UserProfile';
import advicesImg from '../../assets/icons/117.svg'
import blackLogo from '../../assets/icons/blackLogo.svg';
import { ProductLoader } from './ProductLoader';
import whiteBG from '../../assets/icons/whiteBGproduct.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // core Swiper
import 'swiper/css/navigation';  // if you need navigation buttons
import 'swiper/css/pagination';  // if you need pagination dots

//Olis design numbers
import slash from '../../assets/numbers/slash.png';
import num0 from '../../assets/numbers/10.png';
import num1 from '../../assets/numbers/01.png';
import num2 from '../../assets/numbers/02.png';
import num3 from '../../assets/numbers/03.png';
import num4 from '../../assets/numbers/04.png';
import num5 from '../../assets/numbers/05.png';
import num6 from '../../assets/numbers/06.png';
import num7 from '../../assets/numbers/07.png';
import num8 from '../../assets/numbers/08.png';
import num9 from '../../assets/numbers/09.png';

import currentDot from '../../assets/icons/currentdot.svg'
import lightdot from '../../assets/icons/lightdot.svg'


export const ProductDetail = () => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [tempSelectedSize, setTempSelectedSize] = useState<string>('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<IProduct | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isIngredientsOpen, setIsIngredientsOpen] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [inCart, setInCart] = useState<boolean>(false);
  const [selectedSize, setSelectedSize] = useState<string>('');
  const navigate = useNavigate();
  const [localCart, setLocalCart] = useState<IProductInCart[]>([]);
  const [cartItems, setCartItems] = useAtom(cartItemsAtom);
  const [mainImage, setMainImage] = useState('');
  const [inStock, setInStock] = useState<boolean>(false);
  const [brandName, setBrandName] = useState<string>(product?.brand || '');
  const [isMobile, setIsMobile] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [isLongDescription, setIsLongDescription] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const swiperRef = useRef<any>(null); // Swiper instance reference



  useEffect(() => {
    const galleryElement = galleryRef.current;

    const stopScrollPropagation = (event: WheelEvent) => {
      if (galleryElement) {
        const { scrollTop, scrollHeight, clientHeight } = galleryElement;
        const isAtTop = scrollTop === 0;
        const isAtBottom = scrollTop + clientHeight === scrollHeight;

        if ((isAtTop && event.deltaY < 0) || (isAtBottom && event.deltaY > 0)) {
          event.preventDefault();
        }
      }
    };

    if (galleryElement) {
      galleryElement.addEventListener('wheel', stopScrollPropagation, { passive: false });
    }

    return () => {
      if (galleryElement) {
        galleryElement.removeEventListener('wheel', stopScrollPropagation);
      }
    };
  }, []);


  //dynamicly create score image according to the score number 
  const getNumberImage = (digit: string) => {
    const numberImages: { [key: string]: string } = {
      '0': num0, '1': num1, '2': num2, '3': num3, '4': num4,
      '5': num5, '6': num6, '7': num7, '8': num8, '9': num9
    };
    return numberImages[digit];
  };


  const handleDotClick = (index: number) => {
    setActiveImageIndex(index); // Update the active dot
    if (swiperRef.current) {
      swiperRef.current.slideTo(index); // Navigate to the corresponding slide
    }
  };


  useEffect(() => {
    if (descriptionRef.current) {
      const descriptionElement = descriptionRef.current;
      let lineHeight = getComputedStyle(descriptionElement).lineHeight;
      if (lineHeight === 'normal') {
        lineHeight = getComputedStyle(descriptionElement).fontSize;
      }

      const parsedLineHeight = parseFloat(lineHeight);
      const finalLineHeight = isNaN(parsedLineHeight) ? 24 : parsedLineHeight;

      const descriptionHeight = descriptionElement.scrollHeight;
      const maxHeight = finalLineHeight * 7;

      setIsLongDescription(descriptionHeight > maxHeight);
    }
  }, [product?.description]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (productId) {
      fetchProduct(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (product?.brand) {
      setBrandName(product?.brand);
    }
  }, [product?.brand]);

  useEffect(() => {
    const storedCart = loadLocalCart();
    if (storedCart) setLocalCart(storedCart);
    else console.error('Error loading local cart');
  }, [cartItems]);

  useEffect(() => {
    if (user?.cart && product?._id) {
      setInCart(user.cart.some(item => item.product?._id === product._id));
    } else if (localCart && product?._id) {
      setInCart(localCart.some(item => item.product._id === product._id));
    } else {
      setInCart(false);
    }
  }, [user?.cart, localCart, product?._id]);

  useEffect(() => {
    const checkAvailability = async () => {
      try {
        if (product?.sku) {
          const result = await productsApi.checkProductAvailability(product.sku);

          setInStock(result.available);
        } else {
          setInStock(false);
        }
      } catch (error) {
        console.error("Error checking product availability:", error);
        setInStock(false);
      }
    };

    checkAvailability();

  }, [product?.sku]);


  const fetchProduct = async (id: string) => {
    try {
      setLoading(true);
      const result = await productsApi.getProductById(id);

      if (result.success && result.data) {
        setProduct(result.data as IProduct);
        setMainImage(result?.data?.imgUrl || '');
      } else {
        setProduct(null);
        setError(result.message || 'Failed to fetch product');
      }
    } catch (err) {
      setProduct(null);
      setError('An error occurred while fetching the product');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!inCart) {
      setSelectedSize('');
      setTempSelectedSize('');
    }
  }, [inCart]);


  const saveToBasket = async () => {
    try {
      if (user && product) {
        const result = await userService.addtocart(user, setUser, product, navigate);

        if (result?.success) {
          setInCart(true);
        } else if (result.message === 'Session expired. Please login again.') {
          navigateToLogin('Please login to add items to your cart');
        } else {
          console.error("Error adding product to cart", result.message);
          alert("Error adding product to cart")
          // setError(result.message);
        }
      } else if (product) {
        const updatedCart = addToLocalCart({ product, quantity: 1 });
        setLocalCart(updatedCart);
        setCartItems(updatedCart);
        setInCart(true);
      }
    } catch (e) {
      console.error('Saving product to basket on ProductPage.tsx failed', e);
    }
  };

  const navigateToLogin = (errorMessage?: string) => {
    navigate('/login', {
      state: {
        errorMessage,
        returnPath: `/product/${productId}`
      }
    });
  };

  const toggleIngredientsList = () => {
    setIsIngredientsOpen(!isIngredientsOpen);
  };

  const navigateToBrand = (brandName: string) => {
    navigate(`/brand/${brandName.replace(/ /g, '%20')}`);
  };

  if (error) {
    // TODO - Error page
    // navigate('/shop');
    setError(null);
    fetchProduct(productId || '');
    return <div className="brand-loader">
      <ProductLoader />
    </div>
    //return <div>Error: {error}</div>;
  }

  if (!product) {
    return <div className="brand-loader">
      <ProductLoader />
    </div>
  }

  return (
    <div className="productDetailsContainer">
      <HomepageNavbar />
      <div className='product-box'>
        <div className="product-detail-container">
          <div className="side-menu">


            <div className="advice-container">
              {product?.score && (
                <div className="advice-container">
                  <img src={blackLogo} alt="Logo" className="advice-logo" />
                  {
                    isMobile ? (
                      <>
                        <span className='advice-text'>oli advices</span>
                      </>
                    ) : (
                      <div className='advice-image-container'>
                        <img src={advicesImg} alt="Logo" className="advice-image" />
                      </div>
                    )
                  }
                  <div>
                    <div className="score-display">
                      {product.score.toString().padStart(2, '0').split('').map((digit, index) => (
                        <img
                          key={index}
                          src={getNumberImage(digit)}
                          alt={digit}
                          className="score-digit"
                        />
                      ))}
                      <img src={slash} alt="/" className="score-digit" />
                      {['1', '0', '0'].map((digit, index) => (
                        <img
                          key={`total-${index}`}
                          src={getNumberImage(digit)}
                          alt={digit}
                          className="score-digit"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!isMobile && (
              // <div
              //   className="risk-status-bg"
              //   style={{
              //     backgroundColor: '#D6CBBF',
              //     color: '#000000',
              //     cursor: 'default',
              //   }}
              // >
              //   <p style={{ color: '#000000' }}>
              //     POTENTIAL RISK
              //   </p>
              //   <p style={{ color: '#000000' }}>
              //     <img src={RiskIcon} alt="Risk Icon" className="risk-icon" />
              //     NO RISK
              //   </p>
              // </div>
              <div></div>
            )}

            {product?.inci && product.inci.length > 0 && (
              <div className={`ingredients-list ${isIngredientsOpen ? 'active' : ''}`}>
                <div className="toggle-header-Ingredients">
                  <h3 className='ingredients-title' onClick={toggleIngredientsList}>Ingredients List</h3>
                  <button onClick={toggleIngredientsList} className="toggle-icon-IngredientsOpen">
                    {isIngredientsOpen ? '−' : '+'}
                  </button>
                </div>
                {isIngredientsOpen && (
                  <>
                    <ul className='ingredients-ul'>
                      {product?.inci?.map((ingredient, index) => (
                        <li key={`found-${index}`}>
                          <div className="ingredient-item">
                            <span className="ingredient-title">{ingredient}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            )}
          </div>

          <div className="product-image-section">
            <div className="product-image">
              {loading ? (
                <ProductLoader />
              ) : (
                <>
                  {product?.imgUrl && product.textureImgUrl && (
                    <div className="dots-container">
                      <img
                        src={activeImageIndex === 0 ? currentDot : lightdot} // Check active index for first dot
                        alt="dot 1"
                        className="dot"
                        onClick={() => handleDotClick(0)}
                      // Navigate to the first slide
                      />
                      <img
                        src={activeImageIndex === 1 ? currentDot : lightdot} // Check active index for second dot
                        alt="dot 2"
                        className="dot"
                        onClick={() => handleDotClick(1)}
                        onTouchStart={() => handleDotClick(1)} // Corrected to ensure it works on mobile
                      // Navigate to the second slide
                      />
                    </div>
                  )}

                  <Swiper
                    direction="vertical"
                    spaceBetween={10}
                    slidesPerView={1}
                    onSlideChange={(swiper: any) => setActiveImageIndex(swiper.activeIndex)} // Update active index on slide change
                    onSwiper={(swiper: any) => (swiperRef.current = swiper)} // Set Swiper instance reference
                    style={{ height: '500px' }}
                  >
                    {product?.imgUrl && (
                      <SwiperSlide>
                        <img
                          src={product.imgUrl}
                          alt={product.title}
                          className="scrollable-image"
                        />
                      </SwiperSlide>
                    )}
                    {product?.textureImgUrl && (
                      <SwiperSlide>
                        <img
                          src={product.textureImgUrl}
                          alt={`${product.title} texture`}
                          className="scrollable-image"
                        />
                      </SwiperSlide>
                    )}
                  </Swiper>

                  {isMobile && (
                    <>
                      <p
                        className="productpage-brand"
                        onClick={() => navigateToBrand(product?.brand || 'Untitled-Brand')}
                      >
                        {product?.brand ? product?.brand : 'Brand not available'}
                      </p>
                      <div className="title-price-container">
                        <p className="productpage-title">{product?.title}</p>
                        <p className="product-price">{product?.price ? `€${product?.price}` : 'Price not available'}</p>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

          </div>
          {isMobile && (
            // <div
            //   className="risk-status-bg"
            //   style={{
            //     backgroundColor: '#D6CBBF',
            //     color: '#000000',
            //     cursor: 'default',
            //   }}
            // >
            //   <p style={{ color: '#000000' }}>
            //     POTENTIAL RISK
            //   </p>
            //   <p style={{ color: '#000000' }}>
            //     <img src={RiskIcon} alt="Risk Icon" className="risk-icon" />
            //     NO RISK
            //   </p>
            // </div>
            <div></div>
          )}

          <div className="product-info">
            {!isMobile && (
              <div className="brand-name">
                <h1 onClick={() => navigateToBrand(product?.brand || 'Untitled-Brand')}>
                  {product?.brand || 'Untitled Product'}
                </h1>

                <p className="productpage-brand" style={{ fontSize: '15px' }}>
                  {product?.price ? `€${product?.price}` : 'Price not available'}
                </p>
              </div>
            )}
            {!isMobile && (
              <p className="product-brand">{product?.title || 'Product title not available'}</p>
            )}

            <div className="description-container" style={{ position: 'relative' }}>
              {isMobile && (
                <div>
                  <div className="all-brands-products-container">
                    <RecommandedProducts brandName={brandName || 'Untitled-Brand'} isMiniature={true} />
                    <hr style={{ border: '1px solid #CCB9A7', opacity: 0.2, width: '100%', marginBottom: '1rem', position: 'absolute', top: window.innerWidth < 360 ? 150 : 130 }} />
                  </div>
                  <div style={{ position: 'relative' }}>
                    {isMobile && product?.description && product?.description.trim() !== '' && (
                      <div className="toggle-header-Ingredients">
                        <h3 className='description-title' onClick={() => setShowDescription(!showDescription)}>Description</h3>
                        <button
                          onClick={() => setShowDescription(!showDescription)}
                          className="toggle-icon-IngredientsOpen"
                        >
                          {showDescription ? '-' : '+'}
                        </button>
                      </div>
                    )}
                    {!isMobile && (
                      <button
                        className="product-description-toggle"
                        onClick={() => setShowDescription(!showDescription)}
                      >
                        description {showDescription ? '-' : '+'}
                      </button>
                    )}
                  </div>
                </div>
              )}
              <p
                ref={descriptionRef}
                className="product-desc"
                style={{
                  display: isMobile ? (showDescription ? 'block' : 'none') : '-webkit-box',
                  fontSize: '18px',
                  overflow: 'hidden',
                  WebkitLineClamp: showDescription ? 'none' : 7,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {product?.description || 'Product description not available'}
              </p>

              {!isMobile && isLongDescription && (
                <button
                  onClick={() => setShowDescription(!showDescription)}
                  className="show-more-btn"
                  style={{ marginTop: '10px' }}
                >
                  {showDescription ? 'SHOW LESS' : 'SHOW MORE'}
                </button>
              )}
            </div>
            <div className="certificates">
              {product?.certificates &&
                product.certificates
                  .filter((cert) => cert !== 'Unknown')
                  .map((cert, index) => (
                    <span key={index} className="certificate-badge">
                      {cert}
                    </span>
                  ))}
            </div>

            {!isMobile && (
              <>

                <div className="product-sizes">
                  <h3>CHOOSE A SIZE</h3>
                  <div className="size-options">
                    <button
                      className={`size-button ${selectedSize === '250ml' ? 'active' : ''}`}
                      onClick={() => setSelectedSize('250ml')}
                    >
                      {product?.quantity || '250'} ml <span>{product?.price ? `€${product?.price}` : 'Price not available'}</span>
                    </button>

                    {/* 
              TODO: when we will have different sizes for product we support different sizes and prices
              <button
                className={`size-button ${selectedSize === '500ml' ? 'active' : ''}`}
                onClick={() => setSelectedSize('500ml')}
              >
                500ml <span>${product.price}</span>
              </button>
              <button
                className={`size-button ${selectedSize === '750ml' ? 'active' : ''}`}
                disabled
              >
                750ml <span>${product.price}</span>
              </button> */}
                  </div>
                </div>
                <button
                  className="add-to-cart"
                  disabled={inCart || !inStock}
                  onClick={() => saveToBasket()}
                >
                  {inStock ? (inCart ? 'IN BASKET' : 'ADD TO BASKET') : 'OUT OF STOCK'}
                </button>
              </>
            )}

            {isMobile && (
              <>
                <button
                  className="add-to-cart"
                  onClick={() => {
                    if (!inCart && (!selectedSize || !tempSelectedSize)) {
                      setTempSelectedSize('');
                      setSelectedSize('');
                      setIsPopupOpen(true);
                    } else if (tempSelectedSize) {
                      setSelectedSize(tempSelectedSize);
                      saveToBasket();
                      setIsPopupOpen(false);
                    }
                  }}
                  disabled={inCart || (isPopupOpen && !tempSelectedSize) || !inStock}
                >
                  {inStock ? (inCart ? 'IN BASKET' : 'ADD TO BASKET') : 'OUT OF STOCK'}
                </button>
                {isPopupOpen && (
                  <div className="size-popup-overlay">
                    <div className="size-popup">
                      <div className='header-popup'>
                        <h3>CHOOSE A SIZE</h3>
                        <button className="close-button" onClick={() => setIsPopupOpen(false)}>CLOSE</button>
                      </div>
                      <div className="size-options">
                        <button
                          className={`size-button ${tempSelectedSize === '250ml' ? 'active' : ''}`}
                          onClick={() => {
                            setTempSelectedSize('250ml');
                            setSelectedSize('250ml');
                          }}
                        >
                          {product?.quantity || '250'} ml
                          <span>{product?.price ? `€${product?.price}` : 'Price not available'}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className='miniature-carousel'>
            <RecommandedProducts brandName={brandName || 'Untitled-Brand'} isMiniature={true} />
          </div>
        )}
      </div>

      {
        !isMobile && (
          <RecommandedProducts brandName={brandName || 'Untitled-Brand'} />
        )
      }
      {
        isMobile ? (
          <>
            <SimmilarProducts />
            <hr className="mobile-divider" />
            <RecentlyViewed />
          </>
        ) : (
          <>
            <SimmilarProducts />
            <RecentlyViewed />
          </>
        )
      }
    </div >
  );
};
