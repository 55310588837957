import React, { useState } from 'react';
import './style.scss';
import FooterBack from 'assets/images/homePageFooterBackground.jpg';
import WhiteLogo from 'assets/icons/whiteLogo.svg';
import AppleLogo from 'assets/icons/appleLogo.svg';
import GoogleLogo from 'assets/icons/googleLogo.svg';
import Dot from 'assets/icons/blackDot.svg';
import { useNavigate, Link } from 'react-router-dom';
import userApi from 'service/API/UserApi';

interface LatestTrendsProps {
  isComingSoonPage?: boolean;
}

export const FooterSection: React.FC<LatestTrendsProps> = ({ isComingSoonPage = false }) => {
  const navigate = useNavigate();
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [showNewsletterForm, setShowNewsletterForm] = useState(false);
  const [email, setEmail] = useState('');


  const toggleSection = (section: string) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };
  const handleNewsletter = () => {
    setShowNewsletterForm(!showNewsletterForm);
  };
  const handleSubscribe = (Email: string) => {
    userApi.newsletterSubscribe(Email);
    setShowNewsletterForm(false);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className="footerContainer" style={{ backgroundImage: `url(${FooterBack})` }}>
      {!isComingSoonPage && (<div className="footerMenu">

        <div className="topContainer">
          <p className="footerHeader">Take better care of yourself with Oli news & updates</p>

          <div className="newsletterContainer">
            <div className="dotIcon" style={{ backgroundImage: `url(${Dot})` }} />
            {!showNewsletterForm ? (
              <p onClick={handleNewsletter} className="regNews">
                register Newsletter
              </p>
            ) : (
              <div className="newsletterForm">
                <input
                  className="newsletterInput"
                  type="text"
                  placeholder="enter your email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange} // Update the state when input changes
                // Set the value of the input to the state
                />
                <button onClick={() => handleSubscribe(email)} className="subscribeBtn">
                  Subscribe
                </button>
              </div>
            )}
          </div>
          <div className="onlineStoresButtonsBlock">
            <div className="storeButtonContainer">
              <button className="storeButton">
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${AppleLogo})` }}
                />
                <p>App store</p>
              </button>
              <button className="storeButton">
                <div
                  className="icon"
                  style={{ backgroundImage: `url(${GoogleLogo})` }}
                />
                <p>Google play</p>
              </button>

            </div>
          </div>
        </div>

        <div className="menuLinks">

          <div className="menuSection">
            <div className='header-mobile-section' onClick={() => toggleSection('shop')}>
              <p className="footerMenuHeader">SHOP</p>
              <span className="toggleIcon">
                {expandedSection === 'shop' ? '-' : '+'}
              </span>
            </div>
            <span className={`arrow ${expandedSection === 'shop' ? 'up' : 'down'}`}></span>
            <ul className={`footerMenuList ${expandedSection === 'shop' ? 'expanded' : ''}`}>
              <li onClick={() => navigate('/shop')} className="footerMenuItem">shop</li>
              <li onClick={() => navigate('/bestsellers')} className="footerMenuItem">BestSellers</li>
              <li className="footerMenuItem">Just Drops</li>
            </ul>

          </div>

          <div className="menuSection">
            <div className='header-mobile-section' onClick={() => toggleSection('other')}>
              <p className="footerMenuHeader">OTHER</p>
              <span className="toggleIcon">
                {expandedSection === 'other' ? '-' : '+'}
              </span>
            </div>
            <span className={`arrow ${expandedSection === 'other' ? 'up' : 'down'}`}></span>
            <ul className={`footerMenuList ${expandedSection === 'other' ? 'expanded' : ''}`}>
              <li onClick={() => navigate('/feed')} className="footerMenuItem">Feed</li>
              <li className="footerMenuItem">Partners</li>
              <li className="footerMenuItem">Contact</li>
            </ul>
          </div>
          <div className="menuSection">
            <div className='header-mobile-section' onClick={() => toggleSection('social')}>
              <p className="footerMenuHeader">SOCIAL</p>
              <span className="toggleIcon">
                {expandedSection === 'social' ? '-' : '+'}
              </span>
            </div>
            <span className={`arrow ${expandedSection === 'social' ? 'up' : 'down'}`}></span>
            <ul className={`footerMenuList ${expandedSection === 'social' ? 'expanded' : ''}`}>
              <li className="footerMenuItem">Instagram</li>
              <li className="footerMenuItem">Linkedin</li>
              <li className="footerMenuItem">Tiktok</li>
            </ul>
          </div>
        </div>

        <div className="policyBlock">
          <p className="allRights">@allrightreserved OLI</p>
          <div className="policies">
            <p className="policyBtn">Terms of use</p>
            <p className="policyBtn">Privacy policy</p>
          </div>
        </div>
      </div>)}

      <div className="footerBottom">
        <div
          className="footerLogo"
          style={{ backgroundImage: `url(${WhiteLogo})` }}
        />
        <p className="footerAdvice">Take better care of yourself</p>
        {isComingSoonPage && (
          <div className="mediaLinksContainer">
            <Link className="mediaLinks" to="https://www.instagram.com/olis.lab" target="_blank" rel="noopener noreferrer">Instagram</Link>
            <Link className="mediaLinks" to="https://www.linkedin.com/company/olis-lab" target="_blank" rel="noopener noreferrer">LinkedIn</Link>
            <Link className="mediaLinks" to="https://www.tiktok.com/@olis_lab" target="_blank" rel="noopener noreferrer">TikTok</Link>
          </div>
        )}
      </div>

      {(isComingSoonPage && (
        <p className="allRightsComingSoon">© 2024 | OLi’s lab SARL</p>
      ))}
    </div>
  );
};
