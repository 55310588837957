import React, { useState, useEffect } from 'react';
import styles from './ProfileHomePage.module.scss';
import { SkinResume } from '../SkinResume/SkinResume';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { SkinData } from '../SkinResume/components/SkinData/SkinData';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import { ProfileSettings } from '../ProfileSettings/ProfileSettings';
import { ContactBox } from '../ProfileSettings/components/ContactBox/ContactBox';
import { CautionBox } from '../ProfileSettings/components/CautionBox/CautionBox';
import { OrderDisplay } from '../MyOrders/components/OrderDisplay/OrderDisplay';
import { getLocalStorageItem } from 'utils/localStoragUtil';
import { IOrder } from 'types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import userApi from 'service/API/UserApi';

export const ProfileHomePage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [user] = useAtom(userAtom);

    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const [orders, setOrders] = useState<IOrder[]>([]);
    const navigate = useNavigate()

    useEffect(() => {
        const storedOrders = getLocalStorageItem('userOrders');

        if (storedOrders) {
            setOrders(storedOrders as IOrder[]);
            setIsEmpty(storedOrders.length === 0);
        } else {
            setIsEmpty(true);
        }
    }, []);

    const currentOrders = orders.filter((order) => {
        const lastStatus = order.statusHistory && Array.isArray(order.statusHistory) && order.statusHistory.length > 0
            ? order.statusHistory[order.statusHistory.length - 1]
            : null;

        return lastStatus && (lastStatus.status === 'RECEIVED' || lastStatus.status === 'PROCESSED' || lastStatus.status === 'SHIPPED');
    }).reverse();

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    useEffect(() => {
        const fetchUserOrders = async () => {
            if (user) {
                await userApi.getUserOrders(user._id as string);
            }
        };

        fetchUserOrders();
    }, [user]);

    const handleSeeAll = () => {
        navigate('/profile/my-orders')
    }

    const handleStartToShop = () => {
        navigate('/shop');
    }

    return (
        <div>
            {!isMobile ?
                <SkinResume />
                :
                <div className={styles.mobileContainer}>
                    <HomepageNavbar />

                    <div className={styles.headSection}>
                        <h3 className={styles.userName}>{user?.name}</h3>
                        <h3 className={styles.userName}>{user?.lastName}</h3>
                    </div>

                    <div className={styles.sectionBottom}>
                        < SkinData />
                        {/* HERE SHOULD BE THE FAVORITES PRODUCTS */}
                        {/* HERE SHOULD BE THE SAVED ARTICLES */}

                        <div className={`${styles.mobileFunctionContainer} ${currentOrders.length === 0 ? styles.mobileFunctionContainerNoBorder : ''}`}>
                            <h3 className={styles.mobileTitle}>My order</h3>
                            <button className={styles.seeAllBtn} onClick={handleSeeAll}>See all</button>
                        </div>

                        {currentOrders.length > 0 ? (
                            <>
                                <div className={styles.mobileSubTitleContainer}>
                                    <h3 className={styles.mobileSubTitle}>Current</h3>
                                </div>
                                {currentOrders.map((order, index) => (
                                    <OrderDisplay key={`current-${index}`} order={order} />
                                ))}
                            </>
                        ) : (
                            <>
                                <div className={styles.noOrdersContainer}>
                                    <h3 className={styles.mobileCenterSubTitle} >
                                        Lorem ipsum dolores magna
                                    </h3>
                                    <p className={styles.mobileCenterText}>
                                        Lorem ipsum dolores magna segue dolores magne
                                        lorem ipsum dolores magna segue
                                    </p>
                                </div>
                                <button className={styles.mobileShopButton} onClick={handleStartToShop}>Start to shop</button>
                            </>
                        )}

                        <ProfileSettings />
                        <ContactBox />
                        <CautionBox />
                    </div>
                </div>
            }
        </div >
    );
};
