import React from 'react';
import styles from './EmptyOrders.module.scss';
import eggShape from '../../../../../assets/icons/blackVector.svg';
import { useNavigate } from 'react-router-dom';

export const EmptyOrders = () => {
    const navigate = useNavigate();

    const handleShopClick = () => {
        navigate('/shop');
    }

    return (
        <div className={styles.emptyOrdersContainer}>
            <div className={styles.contentContainer}>
                <div className={styles.imageContainer}>
                    <img src={eggShape} alt="Egg Shape" className={styles.eggShapeImage} />
                    <div className={styles.textContainer}>
                        <h4 className={styles.title}>No orders history</h4>
                        <p className={styles.subTitle}>Ready to reveal your beauty with OLI product, start shopping</p>
                        <button className={styles.btn} onClick={handleShopClick}>Start to shop</button>
                        <p className={styles.favoritesBtn}>View your favorites</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

