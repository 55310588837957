import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { userAtom } from 'stores/UserProfile';
import { HeadSection } from 'components/homepage/HeadSection/HeadSection';
import styles from './style.module.scss';
import { ArticlesCarousel } from 'components/homepage/ArticlesCarousel/ArticlesCarousel';
import HeaderBackgroundImage from 'assets/images/82.jpg';
import { BestSellersSection } from 'components/homepage/BestSellersSection/BestSellersSection';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import { ArticlesPreview } from 'components/homepage/ArticlesPreview/ArticlesPreview';
import { OliAdviceSection } from 'components/homepage/OliAdviceSection/oliAdviceSection';
import backgroundImage from 'assets/images/backgroundHomePageLogin.png';
import HomepageLoader from '../../components/animation/homepageloader';
import { componentLoadingStatusAtom } from 'stores/UserProfile';

export const LoginUserHomePage = () => {
    const [user] = useAtom(userAtom);
    const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (Object.values(componentStatuses).every((status) => status)) {
            setComponentStatuses((prev) => ({ ...prev, loader: false }));
        }
        console.log('componentStatuses', componentStatuses);
    }, [componentStatuses, setComponentStatuses]);

    return (
        <>
            {componentStatuses.loader && <HomepageLoader />}
            <div
                className={styles.loginUserHomePage}
                style={{
                    display: componentStatuses.loader ? 'none' : 'block',
                }}
            >
                <HeadSection backgroundImage={backgroundImage} isLoginPage={!!user} />
                <BestSellersSection isLoggedIn={!!user} />
                <OliAdviceSection />
                <ArticlesCarousel />
                <ArticlesPreview isLoggedIn={!!user} />
                <FooterSection />
            </div>
        </>
    );
};
