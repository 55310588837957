import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlackLogo from '../../../../../assets/icons/blackLogo.svg';
import BlackDot from '../../../../../assets/icons/blackDot.svg';
import styles from './TermsOfUse.module.scss';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { useAtom } from 'jotai';
import { userAtom } from '../../../../../stores/UserProfile';
import { ProfileMenu } from 'pages/ProfilePage/ProfileMenu/ProfileMenu';

export const TermsOfUse: React.FC = () => {
    const [user] = useAtom(userAtom);
    const isMobile = window.innerWidth < 768;

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const mobileLayout = (
        <>
            <HomepageNavbar />
            <div className={styles.mobileContainer}>
                <div className={styles.termsContainer}>
                    <div className={styles.termsHeaderContainer}>
                        <img src={BlackLogo} alt="Logo" className={styles.termsBlackLogo} />
                        <h5 className={styles.termsHeaderTitle}>Terms & Conditions / Privacy Policy</h5>
                        <span className={styles.termsHeaderSpan}>Last updated 4th December, 2023</span>
                    </div>
                    <div className={styles.termsContent}>
                        <div className={styles.dotTitleWrapper}>
                            <img src={BlackDot} alt="Dot" className={styles.termsBlackDot} />
                            <h3 className={styles.termsTitle}>Welcome to OLI</h3>
                        </div>
                    </div>
                    <br />
                    <span>
                        Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                    </span>
                    <br />
                    <br />
                    <br />
                    <div className={styles.termsParagraph}>
                        <h5 className={styles.termsParagraphMainTitle}>I. General terms</h5>
                        <br />
                        <span>
                            Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                        </span>
                        <br />
                        <br />
                        <span>
                            nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                        </span>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className={styles.termsParagraph}>
                        <h5 className={styles.termsParagraphSubTitle}>I. General terms</h5>
                        <br />
                        <span>
                            Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                        </span>
                        <br />
                        <br />
                        <span>
                            nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                        </span>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className={styles.termsParagraph}>
                        <h5 className={styles.termsParagraphSubTitle}>I. General terms</h5>
                        <br />
                        <span>
                            Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                        </span>
                        <br />
                        <br />
                        <span>
                            nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                        </span>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className={styles.termsParagraph}>
                        <h5 className={styles.termsParagraphSubTitle}>I. General terms</h5>
                        <br />
                        <span>
                            Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                        </span>
                        <br />
                        <br />
                        <span>
                            nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                        </span>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className={styles.termsParagraph}>
                        <h5 className={styles.termsParagraphMainTitle}>I. General terms</h5>
                        <br />
                        <span>
                            Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                        </span>
                        <br />
                        <br />
                        <span>
                            nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                        </span>
                    </div>
                </div>
            </div>
        </>
    )

    const desktopLayout = (
        <div className={styles.desktopContainer}>
            <HomepageNavbar />

            <div className={styles.topSection}>
                <div className={styles.menuTopSection}>
                    {/* PLACE HOLDER DONT REMOVE */}
                </div>
                <div className={styles.contentTopSection}>
                    <h3 className={styles.userName}>{user?.name}</h3>
                    <h3 className={styles.userName}>{user?.lastName}</h3>
                </div>
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.menuSection}>
                    <ProfileMenu />
                </div>
                <div className={styles.contentSection}>
                    <div className={styles.termsContainer}>
                        <button className={styles.termsBtnBack} onClick={handleBack}>{'< BACK'}</button>
                        <div className={styles.termsHeaderContainer}>
                            <img src={BlackLogo} alt="Logo" className={styles.termsBlackLogo} />
                            <h5 className={styles.termsHeaderTitle}>Terms & Conditions / Privacy Policy</h5>
                            <span className={styles.termsHeaderSpan}>Last updated 4th December, 2023</span>
                        </div>
                        <div className={styles.termsContent}>
                            <div className={styles.dotTitleWrapper}>
                                <img src={BlackDot} alt="Dot" className={styles.termsBlackDot} />
                                <h3 className={styles.termsTitle}>Welcome to OLI</h3>
                            </div>
                        </div>
                        <br />
                        <span>
                            Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                        </span>
                        <br />
                        <br />
                        <br />
                        <div className={styles.termsParagraph}>
                            <h5 className={styles.termsParagraphMainTitle}>I. General terms</h5>
                            <br />
                            <span>
                                Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                            </span>
                            <br />
                            <br />
                            <span>
                                nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                            </span>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className={styles.termsParagraph}>
                            <h5 className={styles.termsParagraphSubTitle}>I. General terms</h5>
                            <br />
                            <span>
                                Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                            </span>
                            <br />
                            <br />
                            <span>
                                nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                            </span>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className={styles.termsParagraph}>
                            <h5 className={styles.termsParagraphSubTitle}>I. General terms</h5>
                            <br />
                            <span>
                                Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                            </span>
                            <br />
                            <br />
                            <span>
                                nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                            </span>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className={styles.termsParagraph}>
                            <h5 className={styles.termsParagraphSubTitle}>I. General terms</h5>
                            <br />
                            <span>
                                Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                            </span>
                            <br />
                            <br />
                            <span>
                                nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                            </span>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className={styles.termsParagraph}>
                            <h5 className={styles.termsParagraphMainTitle}>I. General terms</h5>
                            <br />
                            <span>
                                Lorem ipsum dolor sit amet. Et libero quia qui earum voluptatem aut totam molestias. Est architecto voluptatem et iusto earum sit omnis enim qui quam ducimus non exercitationem velit et quidem sint ea magnam delectus. Est pariatur voluptas qui eveniet animi non error velit. Et galisum explicabo et debitis quaerat vel officiis asperiores est unde dolorem.
                            </span>
                            <br />
                            <br />
                            <span>
                                nihil non placeat voluptates ut alias exercitationem et suscipit quas. Sit ipsam provident non perspiciatis magnam eos accusamus sequi ut nulla culpa id deserunt necessitatibus? Sed architecto voluptatum et consequatur cupiditate qui excepturi consequatur aut velit inventore.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return isMobile ? mobileLayout : desktopLayout;
};
