import './style.scss';
import { Link } from "react-router-dom";
import useAllProducts from "hooks/allProducts";
import { useEffect, useState } from "react";
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { ShopHeadBestsellers } from "components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers";
import { ProductLoader } from "pages/ProductDetails/ProductLoader";
import single from 'assets/icons/single.svg'
import multiple from 'assets/icons/double.svg'
import translations from 'translations/index'

export const ShopBestsellers = () => {
  const { allProducts } = useAllProducts();
  const [allProductsCount, setAllProductsCount] = useState(0);
  const [brands, setBrands] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [showBrands, setShowBrands] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [showDon, setShowDon] = useState(false);
  const [hiddenBrands, setHiddenBrands] = useState<string[]>([]);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [gridColumns, setGridColumns] = useState(2);

  useEffect(() => {
    if (allProducts) {
      setAllProductsCount(allProducts.length);

      const uniqueBrands = [
        ...new Set(
          allProducts
            .map((product) => product.brand)
            .filter((brand): brand is string => brand !== undefined)
        ),
      ];
      setBrands(uniqueBrands);

      const uniqueSubcategories = [
        ...new Set(
          allProducts
            .map((product) => product.subcategories?.[0])
            .filter(
              (subcategory): subcategory is string => subcategory !== undefined
            )
        ),
      ];
      setCategories(uniqueSubcategories);
    }
  }, [allProducts]);

  const handleCategoryChange = (category: string) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleBrandChange = (brand: string) => {
    setSelectedBrands((prev) =>
      prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
    );
  };

  const toggleHideBrand = (brand: string) => {
    setHiddenBrands((prev) =>
      prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
    );
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const removeCategory = (category: string) => {
    setSelectedCategories((prev) => prev.filter((c) => c !== category));
  };

  const removeBrand = (brand: string) => {
    setSelectedBrands((prev) => prev.filter((b) => b !== brand));
  };

  const removeHiddenBrand = (brand: string) => {
    setHiddenBrands((prev) => prev.filter((b) => b !== brand));
  };

  const toggleBrands = () => {
    setShowBrands((prevState) => !prevState);
  };

  const toggleCategories = () => {
    setShowCategories((prevState) => !prevState);
  };

  const toggleDon = () => {
    setShowDon((prevState) => !prevState);
  };

  const clearAllTags = () => {
    setSelectedCategories([]);
    setSelectedBrands([]);
    setHiddenBrands([]);
  };
  const handleGridChange = (columns: number) => {
    setGridColumns(columns);
  };

  const filteredProducts = allProducts?.filter((product) => {
    const matchesCategory =
      selectedCategories.length === 0 ||
      selectedCategories.includes(product.subcategories?.[0] || "");
    const matchesBrand =
      selectedBrands.length === 0 ||
      selectedBrands.includes(product.brand || "");
    const isBrandHidden = hiddenBrands.includes(product.brand || "");
    return matchesCategory && matchesBrand && !isBrandHidden;
  });

  if (!allProducts || allProducts.length === 0) {
    return <div className="brand-loader">
      <ProductLoader />
    </div>
  }

  return (
    <>
      <ShopHeadBestsellers />
      <div className="bestseller-shop-bestsellers-container">
        <div className={isMobile ? "bestseller-recommended-section-mobile" : "bestseller-recommended-section"}>
          <div className="bestseller-left-column">
            <h2 className="bestseller-recommended-title">
              {translations.shop.headers.ALL_PRODUCTS}
              <div className="bestseller-icons">
                {isMobile && (
                  <>
                    <img src={multiple} alt="multiple" className="bestseller-recommended-icon" onClick={() => handleGridChange(2)} />
                    <img src={single} alt="single" className="bestseller-recommended-icon" onClick={() => handleGridChange(1)} />
                  </>
                )}
              </div>
            </h2>
            {selectedCategories.map((category) => (
              <div key={category} className="bestseller-selected-tag">
                {category}
                <span
                  className="bestseller-remove-tag"
                  onClick={() => removeCategory(category)}
                >
                  {translations.shop.buttons.REMOVE}
                </span>
              </div>
            ))}

            {selectedBrands.map((brand) => (
              <div key={brand} className="selected-tag">
                {brand}
                <span className="bestseller-remove-tag" onClick={() => removeBrand(brand)}>
                  {translations.shop.buttons.REMOVE}
                </span>
              </div>
            ))}

            <div className="bestseller-filters">
              <p onClick={toggleCategories} style={{ cursor: "pointer" }} className='title-filter'>
                CATEGORIES <span>{showCategories ? "−" : "+"}</span>
              </p>

              {showCategories && (
                <ul className='filter-list'>
                  {categories.map((category, index) => (
                    <li key={index} className='filter-elements'>
                      <label className="bestseller-checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectedCategories.includes(category)}
                          onChange={() => handleCategoryChange(category)}
                        />
                        <span className="checkmark"></span>
                        {category}
                      </label>
                    </li>
                  ))}
                </ul>
              )}

              <p onClick={toggleBrands} style={{ cursor: "pointer" }} className='title-filter'>
                BRANDS <span>{showBrands ? "−" : "+"}</span>
              </p>

              {showBrands && (
                <ul className='filter-list'>
                  {brands.map((brand, index) => (
                    <li key={index} className='filter-elements'>
                      <label className="bestseller-checkbox-container">
                        <input
                          type="checkbox"
                          checked={selectedBrands.includes(brand)}
                          onChange={() => handleBrandChange(brand)}
                        />
                        <span className="checkmark"></span>
                        {brand}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
              <div className="bestseller-dont-show-container">
                <p
                  onClick={toggleDon}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      height: "6px",
                      width: "6px",
                      backgroundColor: "black",
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  />
                  DON'T SHOW ME <span>{showDon ? "−" : "+"}</span>
                </p>
                {hiddenBrands.length > 0 && (
                  <div className="hidden-brands-tags">
                    {hiddenBrands.map((brand) => (
                      <div key={brand} className="selected-tag">
                        {brand}
                        <span
                          className="bestseller-remove-tag"
                          onClick={() => removeHiddenBrand(brand)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {showDon && (
                  <ul>
                    {brands.map((brand, index) => (
                      <li className="bestseller-dont-show-li" key={index}>
                        <label className="bestseller-hide-show-container">
                          <span
                            className={`bestseller-brand-text ${hiddenBrands.includes(brand) ? "bestseller-hidden-brand" : ""
                              }`}
                          >
                            {brand}
                          </span>
                          <button
                            className={`bestseller-hide-show-button ${hiddenBrands.includes(brand) ? "bestseller-bold-text" : ""
                              }`}
                            onClick={() => toggleHideBrand(brand)}
                          >
                            {hiddenBrands.includes(brand) ? "SHOW" : "HIDE"}
                          </button>
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {(selectedCategories.length > 0 || selectedBrands.length > 0 || hiddenBrands.length > 0) && (
                <p className="bestseller-clear-all-text" onClick={clearAllTags}>
                  CLEAR ALL
                </p>
              )}
            </div>
          </div>

          {window.innerWidth > 768 ? (
            <div className="bestseller-all-products-grid">

              {filteredProducts?.map((item, index) => (

                <Link
                  to={`/product/${item._id}`}
                  key={index}
                  className="bestseller-product-container"
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                  onTouchStart={() => setHoveredItem(index)}
                  onTouchEnd={() => setHoveredItem(null)}

                >
                  <p className="bestseller-just-dropped" >just dropped</p>
                  <div className='product-img_container'>
                    <img

                      src={hoveredItem === index && item.textureImgUrl ? item.textureImgUrl : item.imgUrl}
                      alt={`${item.title} product`}
                      className="bestseller-product-image"
                    />
                  </div>
                  <div className='product-details'>
                    <p className="bestseller-product-brand product-text">{item.brand}</p>
                    <p className="bestseller-product-title product-text">{item.title}</p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className={`mobile-all-products-grid grid-columns-${gridColumns}`}>
              {filteredProducts?.map((item, index) => (
                <Link
                  to={`/product/${item._id}`}
                  key={index}
                  className={`mobile-product-container grid-container-${gridColumns}`}
                  onTouchStart={() => setHoveredItem(index)}
                  onTouchEnd={() => setHoveredItem(null)}
                >
                  <p className="bestseller-just-dropped" >just dropped</p>
                  <div className='product-img_container'>
                    <img
                      src={hoveredItem === index && item.textureImgUrl ? item.textureImgUrl : item.imgUrl}
                      alt={`${item.title} product`}
                      className={`mobile-product-image ${gridColumns === 1 ? 'grid-image-1' : ''}`}
                    />
                  </div>
                  <div className='product-details'>
                    <p className="mobile-product-brand product-text">{item.brand}</p>
                    <p className="mobile-product-title product-text">{item.title}</p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>

        {/* <div className="all-products-section">
          <div className="prod-left-column">
            <h2>ALL PRODUCTS</h2>
            <p className="all-products-count">{allProductsCount} products</p>
          </div>
          <div className="products-grid all-products-grid">
            {allProducts?.slice(0, 6).map((item, index) => (
              <Link
                to={`/product/${item._id}`}
                key={index}
                className="product-container"
              >
                <div
                  className="product-image"
                  style={{ backgroundImage: `url(${item.imgUrl})` }}
                />
                <p className="product-brand">{item.brand}</p>
                <p className="product-title">{item.title}</p>
              </Link>
            ))}
          </div> */}
        {/* </div> */}
      </div>
      <FooterSection />
    </>
  );
};
