import React from 'react';
import styles from './CautionBox.module.scss';
import infoImg from '../../../../../assets/images/infoCircle3.png';

export const CautionBox: React.FC = () => {
    return (
        <div className={styles.cautionBox}>
            <div className={styles.imageSection}>
                <img src={infoImg} alt="Caution" className={styles.cautionImage} />
            </div>
            <div className={styles.textSection}>
                <p className={styles.cautionText}>We are not responsible for the misuse or adverse effects of the products used. Each routine is based on your skin at every stage.</p>
            </div>
        </div>
    );
};
