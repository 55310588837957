import styles from './style.module.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../stores/UserProfile';
import { setLocalStorageItem } from '../../utils/localStoragUtil';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUpBackgroudImage from '../../assets/images/headSectionBackground.jpg';
import blackLogo from '../../assets/icons/blackLogo.svg';
import globalService from '../../service/GlobalService';
import { loadLocalCart, deleteLocalCart } from '../../service/localCartService';
import UserApi from 'service/API/UserApi';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import userApi from 'service/API/UserApi';

export const LogInPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [, setUser] = useAtom(userAtom);

  useEffect(() => {
    const state = location.state as { errorMessage?: string, returnPath?: string };
    if (state?.errorMessage) {
      setError(state.errorMessage);
    }
  }, [location]);

  const resetError = (error: string | null, setError: (error: null) => void) => {
    // Resets the error state by setting it to null if there is an existing error.
    if (error) {
      setError(null);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    resetError(error, setError);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    resetError(error, setError);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await UserApi.userLogin(email, password);
      if (response.success && response.data) {
        const { user, accessToken, refreshToken } = response.data;
        // First save user data and tokens
        await globalService.saveUserAndTokens(accessToken, refreshToken, user, setUser);
        // Handle cart merging
        const localCart = loadLocalCart();

        if (localCart.length > 0) {
          const newUser = { ...user };

          // Only add items that don't exist in the user's cart
          for (const localItem of localCart) {
            const existingItem = newUser.cart.find((item: any) =>
              item.product._id === localItem.product._id
            );

            if (!existingItem) {
              // Add new item to user's cart
              newUser.cart.push(localItem);
              // Update database
              await UserApi.addProductToCart(setUser, localItem);
            }
            // If item exists, keep the user's existing quantity
          }

          await globalService.saveUser(newUser, setUser);
          // Only delete local cart after successful merge
          deleteLocalCart();
        }
        // Handle navigation
        const state = location.state as { returnPath?: string };
        navigate(state?.returnPath || '/shop');
      } else {
        console.error('err: ', response);
        setError(response?.message || 'Invalid email or password');
      }
    } catch (err: any) {
      console.error('Login error: ', err);
      setError('Invalid email or password');
    }
  };

  const inputErrorClass = error ? 'error' : '';

  return (
    <>
      <div className={styles.loginHeaderBlock} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
        <HomepageNavbar />
        <div className={styles.loginContainer}>
          <img className={styles.olisLogo} alt="Group" src={blackLogo} />
          <h3 className={styles.signInTitle}>SIGN IN</h3>
          <p className={styles.signInSubtitle}>WELCOME, SIGN IN OR CREATE AN ACCOUNT TO CONTINUE YOUR SKINCARE ROUTINE</p>


          <form className={styles.loginForm} onSubmit={handleLogin}>
            <div className={styles.loginInputGroup}>
              <input
                type="email"
                id="email"
                placeholder="EMAIL ADDRESS"
                value={email}
                onChange={handleEmailChange}
                className={`login-input ${styles[inputErrorClass]}`}
                required
              />
            </div>

            <div className={styles.loginInputGroup}>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="PASSWORD"
                value={password}
                onChange={handlePasswordChange}
                className={`login-input ${styles[inputErrorClass]}`}
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className={`login-show-btn ${styles[inputErrorClass]}`}
              >
                {showPassword ? 'HIDE' : 'SEE'}
              </button>
              {error && <p className={styles.errorMessage}>{error}</p>}
            </div>
            <button type="submit" className={styles.loginButton} disabled={!email || !password}>
              SIGN IN
            </button>
          </form>
          <div className={styles.loginForgotPasswordLinkContainer}>
            <a href="login/forgot-password" className={styles.loginForgotPasswordLink}>
              FORGOT PASSWORD?
            </a>
          </div>

          <p className={styles.loginText}>
            Need an account?{' '}
            <a href="/signup" className={styles.loginLink}>
              Sign up
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
