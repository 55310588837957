import React, { useRef, useEffect } from 'react';
import './style.scss';
import novemberBG from 'assets/images/november-edit-bg.png';
import justDroppedBG from 'assets/images/just-dropped-bg.png';
import ourSelectionBG from 'assets/images/our-selection-bg.png';
import CardBackground1 from 'assets/images/november-edit.png';
import CardBackground2 from 'assets/images/PopularCard2.png';
import CardBackground3 from 'assets/images/PopularCard3.png';
import november from 'assets/textFont/november_edit.svg';
import JustDropped from 'assets/textFont/just_dropped.svg';
import OurSelection from 'assets/textFont/our_selection.svg';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // basic Swiper styles
import 'swiper/css/navigation'; // if you need navigation buttons
import 'swiper/css/pagination'; // if you need pagination


export const PopularCategories: React.FC = () => {
  const cardsContainerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate(); // useNavigate hook to navigate on click
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const container = cardsContainerRef.current;
    if (container) {
      const firstChild = container.firstChild as Element;
      const cardWidth = firstChild?.getBoundingClientRect().width;
      if (cardWidth) {
        const offset = (container.scrollWidth - container.clientWidth) / 2;
        container.scrollLeft = offset;
      }
    }
  }, []); // Empty dependency array ensures this runs once after initial render

  const cards = [
    {
      background: CardBackground1,
      title: november,
      text: 'Explore our',
      route: '/bestsellers',
      pageTitle: 'NOVEMBER EDIT',
      headerBackground: isMobile ? CardBackground1 : novemberBG
    },
    {
      background: CardBackground2,
      title: JustDropped,
      text: 'Fresh and news',
      route: '/bestsellers',
      pageTitle: 'JUST DROPPED',
      headerBackground: isMobile ? CardBackground2 : justDroppedBG
    },
    {
      background: CardBackground3,
      title: OurSelection,
      text: 'Discover',
      route: '/bestsellers',
      pageTitle: 'OUR SELECTION',
      headerBackground: isMobile ? CardBackground3 : ourSelectionBG
    },
  ];

  return (
    <div className="PopularCategoriesContainer">
      <div className="PopularCategoriesHeader">
        <p className='PopularCategoriesHeaderText'>Popular categories</p>
      </div>
      <Swiper
        spaceBetween={0}
        slidesPerView={isMobile ? 1.2 : 3} // Adjust number of slides per view based on screen size
        className='PopularCategoriesCardsContainer'
      >
        {cards.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className='PopularCategoriesCard'
              style={{ backgroundImage: `url(${item.background})`, cursor: 'pointer' }}
              onClick={() => {
                if (item.route) {
                  navigate(item.route, {
                    state: {
                      customTitle: item.pageTitle,
                      headerBackground: item.headerBackground
                    }
                  });
                }
              }}
            >
              <p className='PopularCategoriesCardText'>{item.text}</p>
              <div className='PopularCategoriesCardTitle' style={{ backgroundImage: `url(${item.title})` }} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
