import api from './apiConfig';

export default class authApi {
    static async forgotPassword(email: string): Promise<{ success: boolean; message: string }> {
        try {
            const response = await api.post('/auth/forgot', { email });
            return { success: response.status === 200, message: response.data.result || 'Password reset email sent.' };
        } catch (error) {
            if (error instanceof Error && 'response' in error) {
                const apiError = error as { response?: { status: number; data: any } };
                if (apiError.response && apiError.response.status === 404) {
                    return { success: false, message: 'User does not exist' };
                }
            }
            return { success: false, message: 'An error occurred while processing your request.' };
        }
    }

    static async checkIsValidLink(token: string): Promise<{ email: string; isValid: boolean } | { success: boolean; message: string }> {
        try {
            const response = await api.post('/auth/verifytoken', { data: { token } });

            if (response.data.email && response.data.isValid) {
                return {
                    email: response.data.email,
                    isValid: response.data.isValid,
                };
            }

            throw new Error('Something went wrong. Please try again.');
        } catch (e: any) {
            if (e?.response?.status === 401) {
                const errorMessage = e?.response?.data?.error || 'Unauthorized request.';
                return { success: false, message: errorMessage };
            }

            if (e?.response?.status === 404) {
                return { success: false, message: 'Invalid or expired link' };
            }

            return { success: false, message: 'Something went wrong.' };
        }
    }

    static async updateNewPassword(token: string, password: string) {
        try {
            const response = await api.post('/auth/changepassword', {
                data: { token, password },
            });

            if (response?.data?.message) {
                return { success: true, message: response.data.message };
            } else {
                return { success: false, message: response?.data?.error || 'An error occurred' };
            }
        } catch (error: any) {
            return { success: false, message: error.response?.data?.error || 'Something went wrong' };
        }
    }
}
