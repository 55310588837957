import React, { useEffect, useState, forwardRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link, useLocation } from 'react-router-dom';
import useRandomProducts from 'hooks/randomProducts';
import './style.scss';
import { FreeMode } from 'swiper/modules';
import whitebg from '../../assets/icons/whiteBGproduct.svg';
import { componentLoadingStatusAtom } from 'stores/UserProfile';
import { useAtom } from 'jotai';

interface ProductsCarouselProps {
  isMiniature?: boolean;
  onSlideChange: (groupIndex: number) => void;
  carouselId: number;
  brandName?: string;
  isComingSoon?: boolean;
}

export const ProductsCarousel = forwardRef<any, ProductsCarouselProps>(
  ({ isMiniature = false, onSlideChange, carouselId, brandName, isComingSoon = false }, ref) => {
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const isProductPage = location.pathname.startsWith('/product/');
    const { randomProducts, loading } = useRandomProducts(carouselId, brandName);
    const [hoveredItem, setHoveredItem] = useState<number | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const [clickableIndex, setClickableIndex] = useState<number | null>(null);
    const [productCount, setProductCount] = useState(0);
    const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      if (!loading) {
        console.log("Loading complete, setting ProductsCarousel status to true.");
        setComponentStatuses((prev: any) => ({ ...prev, ProductsCarousel: true }));
      }
    }, [loading, setComponentStatuses]);


    useEffect(() => {
      // Set the product count when the component mounts or when products change
      setProductCount(randomProducts.length);
    }, [randomProducts]);

    useEffect(() => {
      const pathParts = location.pathname.split('/');
      const currentProductId = pathParts[pathParts.length - 1];
      setSelectedProduct(currentProductId);
    }, [location.pathname]);

    const handleMouseEnter = (index: number) => setHoveredItem(index);
    const handleMouseLeave = () => setHoveredItem(null);

    const handleProductClick = (item: any) => {
      if (isMiniature) {
        setSelectedProduct(item._id);
      }
    };

    return (
      <Swiper
        spaceBetween={10}
        modules={[FreeMode]}
        slidesPerView={randomProducts.length < 4 ? 3 : (isMobile ? 1.2 : 4)}
        onSlideChange={(swiper: any) => {
          const groupIndex = Math.floor(swiper.activeIndex / (isMiniature ? 1 : 3)) + 1;
          onSlideChange(groupIndex);
        }}
        loop={false}
        className="productsCarouselSwiperBlock"
        ref={ref}
        style={{ marginLeft: productCount > 4 ? 0 : 60 }}
      >
        {randomProducts?.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className={`productContainer`}
              {...(isMiniature ? { onClick: () => handleProductClick(item) } : {})}
            >
              {(!isMiniature && (isMobile || clickableIndex === index)) || isMiniature ? (
                <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <div
                    className="clickableImage"
                    {...(!isMobile && {
                      onMouseEnter: () => handleMouseEnter(index),
                      onMouseLeave: handleMouseLeave,
                      onTouchStart: () => setHoveredItem(index),
                      onTouchEnd: () => setHoveredItem(null),
                    })}
                  >
                    {isMiniature ? (
                      <div className="miniature-carousel-image-container">
                        <div
                          className="carousel-image-container"
                          style={{
                            backgroundImage: selectedProduct === item._id ? `url(${whitebg})` : 'none',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                          }}
                        >
                          <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <img
                              className="carousel-image"
                              src={item.imgUrl}
                              alt={item.title}
                            />
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={
                          isMobile
                            ? item.imgUrl
                            : hoveredItem === index && item.textureImgUrl
                              ? item.textureImgUrl
                              : item.imgUrl
                        }
                        className="carousel-image"
                        alt={item.title}
                      />
                    )}
                  </div>
                </Link>
              ) : (
                <div
                  className={`${!isMiniature ? 'non-clickable' : ''}`}
                  {...(!isMobile && {
                    onMouseEnter: () => handleMouseEnter(index),
                    onMouseLeave: handleMouseLeave,
                    onTouchStart: () => setHoveredItem(index),
                    onTouchEnd: () => setHoveredItem(null),
                  })}
                >
                  {isMiniature ? (
                    <div className="carousel-image-container">
                      <img
                        className="carousel-image"
                        src={item.imgUrl}
                        alt={item.title}
                      />
                    </div>
                  ) : isComingSoon ? (
                    <div>
                      <img
                        src={
                          isMobile
                            ? item.imgUrl
                            : hoveredItem === index && item.textureImgUrl
                              ? item.textureImgUrl
                              : item.imgUrl
                        }
                        className="carousel-image"
                        alt={item.title}
                      />
                    </div>
                  ) : (
                    <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <img
                        src={
                          isMobile
                            ? item.imgUrl
                            : hoveredItem === index && item.textureImgUrl
                              ? item.textureImgUrl
                              : item.imgUrl
                        }
                        className="carousel-image"
                        alt={item.title}
                      />
                    </Link>
                  )}
                </div>
              )}
              <div className="productInfo" style={{ padding: 20, textAlign: 'center' }}>
                <p className="productBrand">{item.brand}</p>
                <p className="productTitle">{item.title}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper >
    );
  }
);
