import Background from 'assets/images/scanningFeatureBackground.jpg'
import './style.scss'
import hyejaBackground from 'assets/brands/Hyeja/heyja_desktop.png'
import hyejaMobile from '../../../assets/brands/Hyeja/hyeja_mobile.png';
import hyejaTitle from '../../../assets/brands/Hyeja/hyeja_title.png';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton'
import { Link, useNavigate } from 'react-router-dom'
import { config } from '../../../config'
export const ScanningFeature = () => {
    const navigate = useNavigate()
    const isMobile = window.innerWidth < 768;

    return (
        <Link to={`/brand/${config.REACT_APP_BRAND_NAME}`} style={{ textDecoration: 'none' }}>
            <div className='ScanningFeatureContainerBlock' style={{ backgroundImage: `url(${isMobile ? hyejaMobile : hyejaBackground})`, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100svh' }}>
                <div className='scanningFeatureTitle'>
                    <img src={hyejaTitle} alt="Hyeja Title" className='scanningFeatureTitleImage' />
                    <p className='left-scanningFeatureTitle'>Discover beauty essentials from</p>
                    <p className='right-scanningFeatureTitle'>Uncover Oli’s selection</p>
                </div>
                <div className='scanningFeatureShopButtonContainer'>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '20px' }}>
                        <DefaultButton text="Shop products" onClick={(e) => { e.stopPropagation(); navigate(`/brand/${config.REACT_APP_BRAND_NAME}`); }} />
                    </div>
                </div>
            </div>
        </Link>
    );
}