import React from 'react';
import styles from './checkout.module.scss';
import { AddressCardProps } from '../../types/TInterfaces';
import SelectedAddressIcon from '../../assets/icons/SelectedAddress.svg'; // Ensure the correct import

export const AddressCard: React.FC<AddressCardProps> = ({ shippingAddress, isSelected, onEdit }) => {
    return (
        <div className={`${styles.addressCard} ${isSelected ? styles.selectedAddressCard : ''}`}>
            <div className={styles.cardHeader}>
                <h3 style={{ textTransform: 'uppercase' }}>{shippingAddress.addressName || 'ADDRESS'}</h3>
                {isSelected && (
                    <div className={styles.selectedIcon}>
                        <img src={SelectedAddressIcon} alt="Selected Address" />
                    </div>
                )}
            </div>
            <div className={styles.addressDetails}>
                <p>{`${shippingAddress.first_name} ${shippingAddress.last_name}`}</p>
                <p>{`${shippingAddress.street} ${shippingAddress.streetNumber}`}</p>
                <p>{`${shippingAddress.postalCode} ${shippingAddress.city}, ${shippingAddress.country}`}</p>
            </div>
            <button className={styles.editButton} onClick={onEdit}>EDIT</button>
        </div>
    );
};
