// ShopHeadBestsellers.js
import styles from './style.module.scss';
import { useLocation } from 'react-router-dom';
import HeaderBackgroundImage from 'assets/images/101.jpg';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import ScrollVector from 'assets/icons/scrollVector.svg';

interface ShopBrandBestsellerProps {
    backgroundImage?: string;
    brandname?: string;
}

export const ShopBrandBestseller = ({ backgroundImage = HeaderBackgroundImage, brandname }: ShopBrandBestsellerProps) => {
    const location = useLocation();
    const newBrand = location.pathname.includes('/brand/');
    const customState = location.state as { customTitle?: string; headerBackground?: string } | null;
    const isShopPageHeader = location.pathname.includes('/shop');
    return (
        <div
            className={isShopPageHeader ? styles.shopHeaderBlockPage : styles.shopHeaderBlock}
            style={{
                backgroundImage: `url(${customState?.headerBackground || backgroundImage})`,
            }}
        >
            {newBrand ? (
                <>
                    <img className={styles.vectorBrand} src={ScrollVector} alt="vector" />
                    <span className={styles.scrollVectorTextBrand}>Scroll down</span>
                </>
            ) : (
                <>
                    <div className={styles.vectorBrandContainer}>
                        <img className={styles.mobileVectorBrand} src={ScrollVector} alt="vector" />
                        <span className={styles.mobileScrollVectorTextBrand}>Scroll down</span>
                    </div>
                </>
            )}
            <HomepageNavbar />
            <div className={styles.brandShopHeaderBlockContent}>
                <div className={styles.shopLogoContainer}>
                    {newBrand ? (
                        <div className={styles.BrandHeaderAdviceContainer}>
                            <p className={styles.BrandHeaderAdvice}>
                                {customState?.customTitle || "Discover beauty essentials from"}
                            </p>
                        </div>
                    ) : null}
                </div>
                <div>
                    {newBrand ? (
                        <div className={styles.brandHeaderRightTextContainer}>
                            <p className={styles.brandHeaderRightText}>{"Uncover Oli’s selection"}</p>
                        </div>
                    ) : (
                        <p className={styles.HeaderRightText}>{"Finest Selection"}</p>
                    )}
                </div>
                <div className={styles.ShopHeaderTitleContainer}>
                    <img className={styles.ShopHeaderTitle} src={brandname} alt="brandname" />
                </div>
            </div>
        </div>
    );
};
