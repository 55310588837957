import React from 'react';
import { useState, useRef } from 'react';
import './style.scss';
import { ProductsCarousel } from 'components/carousels/ProductsCarousel';
import { MobileCarousel } from 'components/carousels/mobileCarousel';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import Slider from 'react-slick';

interface Props {
  isMiniature?: boolean;
  brandName: string;
}

export const RecommandedProducts: React.FC<Props> = ({ brandName, isMiniature = false }) => {
  const [activeGroup, setActiveGroup] = useState(1);
  const isMobile = window.innerWidth <= 768;
  const sliderRef = useRef<any>(null);

  const handleNumberClick = (groupIndex: number) => {
    setActiveGroup(groupIndex);
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideTo((groupIndex - 1) * 4);
    }
  };

  const handleSlideChange = (groupIndex: number) => {
    setActiveGroup(groupIndex);
  };

  return (
    <div className={`recommandedContainer ${isMiniature ? 'miniature' : ''}`} style={{ backgroundColor: '#F7F6E7' }}>
      <div className="BSheaderBlock">
        {!isMobile && <p className="header">{brandName} Products</p>}
      </div>
      {!isMobile ? (
        <ProductsCarousel onSlideChange={handleSlideChange} carouselId={11} brandName={brandName} isMiniature={isMiniature} ref={sliderRef} />
      ) : (
        <MobileCarousel onSlideChange={handleSlideChange} carouselId={10} brandName={brandName} isRecommanded={true} isMiniature={isMiniature} />
      )}

      <div className="indicatorContainer">
        <ScrollNumberIndicator count={3} activeNumber={activeGroup} fontSize={12} onNumberClick={handleNumberClick} color="black" />
      </div>
    </div>
  );
};
