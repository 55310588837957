import React, { useState, useRef } from 'react';
import styles from './style.module.scss';
import { ProductsCarousel } from 'components/carousels/ProductsCarousel';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { useNavigate, useLocation } from 'react-router-dom';


interface BestSellersSectionProps {
  isLoggedIn: boolean;
  isComingSoonPage?: boolean;
}

export const BestSellersSection: React.FC<BestSellersSectionProps> = ({ isLoggedIn, isComingSoonPage = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeGroup, setActiveGroup] = useState(1);
  const isShopPage = location.pathname.includes('/shop');
  const sliderRef = useRef<any>(null);
  const isMobile = window.innerWidth < 768;



  const handleSlideChange = (groupIndex: number) => {
    setActiveGroup(groupIndex);
  };

  const handleNumberClick = (groupIndex: number) => {
    setActiveGroup(groupIndex);
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideTo((groupIndex - 1) * 4);
    }
  };

  const renderIndicator = (
    <div className={isLoggedIn ? `${styles.indicatorContainer} ${styles.indicatorContainerShopPage}` : `${styles.indicatorContainer}`}>
      <ScrollNumberIndicator
        count={3}
        activeNumber={activeGroup}
        fontSize={12}
        color="black"
        onNumberClick={handleNumberClick}
      />
    </div>
  );

  const renderShopButton = (
    <div className={`${styles.shopButtonContainer}`} style={{ display: 'flex', justifyContent: 'center' }}>
      <DefaultButton marginBottom={6} marginTop={40} text="Shop products" onClick={() => navigate('/shop')} />
    </div>
  );

  return (
    <div
      className={isShopPage ? `${styles.shopbestsellersContainer}` : `${styles.bestsellersContainer}`}
    >
      {/* Conditionally render the header block if isComingSoonPage is false */}
      {!isComingSoonPage && (
        <div className={styles.BSheaderBlock}
          style={{
            marginBottom: `${isShopPage ? '10px' : '120px'}`
          }}
        >
          {!isShopPage && <p className={styles.header}>bestsellers</p>}
          {!isShopPage && !isLoggedIn && (
            <p className={styles.description}>
              Discover the world of Oli for a perfect routine. Our products are carefully selected to best meet the needs of your skin.
            </p>
          )}
          {!isShopPage && isLoggedIn && (
            <p className={styles.scrollToExplore}>
              Scroll to explore
            </p>
          )}
        </div>
      )}
      <div className={styles.containerCarouselHomePage} style={(isComingSoonPage && isMobile) ? { height: '70%' } : {}}>
        {/* Always render the indicator */}
        {/* Conditionally render the shop button if isComingSoonPage is false */}
        {!isComingSoonPage && (isShopPage || isLoggedIn ? '' : renderShopButton)}
        {!isShopPage && !isLoggedIn && !isComingSoonPage ? renderIndicator : ""}
        <ProductsCarousel onSlideChange={handleSlideChange} carouselId={14} ref={sliderRef} isComingSoon={isComingSoonPage} />
        <div className={styles.middleIndicatorContainer} style={{ display: 'flex', justifyContent: 'center' }}>
          {isShopPage || isLoggedIn ? renderIndicator : ""}
        </div>
      </div>
    </div>
  );
};