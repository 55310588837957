import { atom } from "jotai";
import { IUser, IProductInCart } from "../types/TInterfaces";

export const userAtom = atom<IUser | null>(null);

export const cartItemsAtom = atom<IProductInCart[]>([]);

export const componentLoadingStatusAtom = atom({
    ProductsCarousel: false,
    // brandProductsCarousel: false,
    articlesCarousel: false,
    articlesPreview: false,
    loader: true, // Initially true to show loader
});