import React from 'react';
import { Link } from 'react-router-dom';
import activesCard from '../../../assets/images/activesCard.png';


interface ActiveArticleCardProps {
    active: {
        _id: string;
        active_name: string;
        image?: string;
    };
    className?: string;
}

export const ActiveArticleCard: React.FC<ActiveArticleCardProps> = ({ active, className = '' }) => {
    const imageUrl = active.image || activesCard;

    return (
        <Link to={`/feed/actives/${active._id}`} className={`actives-list__article-item ${className}`}>
            <div
                className="actives-list__article-image"
                style={{ backgroundImage: `url(${imageUrl})` }}
            >
                <div className="actives-list__article-info">
                    <h3 className="actives-list__article-title">{active.active_name.toUpperCase()}</h3>
                    <h3 className="actives-list__article-subtitle">Organic Component</h3>
                </div>
            </div>
        </Link>
    );
};





