import React, { useState, useEffect } from 'react';
import styles from './ProfileMenu.module.scss';  // Importing the styles object
import { Link, useLocation } from 'react-router-dom';

export const ProfileMenu = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('MY SKIN RESUME');
    const location = useLocation();

    // Update selected menu item based on the current route
    useEffect(() => {
        const path = location.pathname;

        if (path.includes('favorites')) {
            setSelectedMenuItem('FAVORITES');
        } else if (path.includes('saved-articles')) {
            setSelectedMenuItem('SAVED ARTICLES');
        } else if (path.includes('my-orders')) {
            setSelectedMenuItem('MY ORDERS');
        } else if (path.includes('settings')) {
            setSelectedMenuItem('SETTINGS');
        } else {
            setSelectedMenuItem('MY SKIN RESUME');
        }
    }, [location.pathname]);

    const handleMenuClick = (item: string) => {
        setSelectedMenuItem(item);
    };

    return (
        <div className={styles.menu}>  {/* Applying class via styles */}
            <ul>
                <li onClick={() => handleMenuClick('MY SKIN RESUME')} className={selectedMenuItem === 'MY SKIN RESUME' ? styles.selected : ''}>
                    <Link to="/profile">MY SKIN RESUME</Link>
                </li>

                <li className={selectedMenuItem === 'FAVORITES' ? styles.selected : ''}>
                    <span>FAVORITES</span>
                </li>
                <li className={selectedMenuItem === 'SAVED ARTICLES' ? styles.selected : ''}>
                    <span>SAVED ARTICLES</span>
                </li>
                <li onClick={() => handleMenuClick('MY ORDERS')} className={selectedMenuItem === 'MY ORDERS' ? styles.selected : ''}>
                    <Link to="/profile/my-orders">MY ORDERS</Link>
                </li>

                <li onClick={() => handleMenuClick('SETTINGS')} className={selectedMenuItem === 'SETTINGS' ? styles.selected : ''}>
                    <Link to="/profile/settings">SETTINGS</Link>
                </li>
            </ul>
        </div>
    );
};
