import React, { useEffect, useState } from 'react';
import styles from './MyOrders.module.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import { OrderDisplay } from './components/OrderDisplay/OrderDisplay';
import { EmptyOrders } from './components/EmptyOrders/EmptyOrders';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { getLocalStorageItem } from '../../../utils/localStoragUtil';
import { IOrder } from 'types/TInterfaces';
import userApi from 'service/API/UserApi';

export const MyOrders: React.FC = () => {
    const [user] = useAtom(userAtom);
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const isMobile = window.innerWidth < 768;

    // Fetching the users orders from the local storage every 16 secs
    useEffect(() => {
        const fetchStoredOrders = () => {
            const storedOrders = getLocalStorageItem("userOrders");

            if (storedOrders) {
                setOrders(storedOrders as IOrder[]);
                setIsEmpty(storedOrders.length === 0);
            } else {
                setOrders([]);
                setIsEmpty(true);
            }
        };

        fetchStoredOrders();

        const intervalId = setInterval(() => {
            fetchStoredOrders();
        }, 16000);

        return () => clearInterval(intervalId);
    }, []);

    // Fetching the users orders from the database every 15 secs
    useEffect(() => {
        const fetchUserOrders = async () => {
            if (user) {
                await userApi.getUserOrders(user._id as string);
            }
        };

        fetchUserOrders();

        const intervalId = setInterval(() => {
            fetchUserOrders();
        }, 15000);

        return () => clearInterval(intervalId);
    }, [user]);

    const currentOrders = orders.filter((order) => {
        const lastStatus = order.statusHistory && Array.isArray(order.statusHistory) && order.statusHistory.length > 0
            ? order.statusHistory[order.statusHistory.length - 1]
            : null;

        return lastStatus && (lastStatus.status === 'RECEIVED' || lastStatus.status === 'PROCESSED' || lastStatus.status === 'SHIPPED');
    }).reverse();

    console.log(currentOrders);

    const pastOrders = orders.filter((order) => {
        const lastStatus = order.statusHistory && Array.isArray(order.statusHistory) && order.statusHistory.length > 0
            ? order.statusHistory[order.statusHistory.length - 1]
            : null;
        console.log(order);

        return lastStatus && (lastStatus.status === 'DELIVERED' || lastStatus.status === 'CANCELLED' || lastStatus.status === 'FAILED' || lastStatus.status === 'RETURNED');
    }).reverse();

    const mobileLayout = (
        <div className={styles.mobileContainer}>
            <HomepageNavbar />


            <div className={styles.mobileHeaderSection}>
                <h3 className={styles.mobileTitle}>My orders
                </h3>
            </div>
            {/* Display Current Orders */}
            <div className={styles.myOrderSubTitleContainer}>
                <h5 className={styles.myOrderSubTitle}>Current</h5>
            </div>
            {(currentOrders.length > 0) ? (
                currentOrders.map((order, index) => (
                    <OrderDisplay key={`current-${index}`} order={order} />
                ))
            ) : (
                <p className={styles.noOrdersText}>
                    No current orders available.
                </p>
            )}

            {/* Display Past Orders */}
            <div className={styles.pastOrders}>
                <div className={styles.myOrderSubTitleContainer}>
                    <h5 className={styles.myOrderSubTitle}>
                        Past orders
                    </h5>
                </div>
                {pastOrders.length > 0 ? (
                    pastOrders.map((order, index) => (
                        <OrderDisplay key={`past-${index}`} order={order} />
                    ))
                ) : (
                    <p className={styles.noOrdersText}>
                        No past orders available.
                    </p>
                )}
            </div>
        </div>
    )

    const desktopLayout = (
        <div className={styles.desktopContainer}>
            <HomepageNavbar />

            <div className={styles.topSection}>
                <div className={styles.menuTopSection}>
                    {/* PLACE HOLDER DONT REMOVE */}
                </div>
                <div className={styles.contentTopSection}>
                    <h3 className={styles.userName}>{user?.name}</h3>
                    <h3 className={styles.userName}>{user?.lastName}</h3>
                </div>
            </div>

            <div className={styles.bottomSection}>
                <div className={styles.menuSection}>
                    <ProfileMenu />
                </div>
                <div className={styles.contentSection}>
                    <h3 className={styles.myOrderTitle}>My orders</h3>
                    {!isEmpty ? (
                        <>
                            {/* Display Current Orders */}
                            {currentOrders.length > 0 && (
                                <>
                                    <h5 className={styles.myOrderSubTitle}>Current</h5>
                                    {currentOrders.map((order, index) => (
                                        <OrderDisplay key={`current-${index}`} order={order} />
                                    ))}
                                </>
                            )}

                            {/* Display Past Orders */}
                            {pastOrders.length > 0 && (
                                <div className={styles.pastOrders}>
                                    <h5 className={styles.myOrderSubTitle}>Past orders</h5>
                                    {pastOrders.map((order, index) => (
                                        <OrderDisplay key={`past-${index}`} order={order} />
                                    ))}
                                </div>
                            )}
                        </>
                    ) : (
                        <EmptyOrders />
                    )}
                </div>
            </div>
        </div>
    );
    return isMobile ? mobileLayout : desktopLayout;
};
