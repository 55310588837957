import React, { useState, useEffect } from 'react';
import './style.scss';
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { componentLoadingStatusAtom } from 'stores/UserProfile';

export const ArticlesPreview: React.FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);
  const { data: articles, isLoading, error } = useNotionArticles();
  const [selectedArticleIndex, setSelectedArticleIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    // Automatically find the first article with dossiers when the articles load
    if (articles && articles.length > 0) {
      const firstWithDossierIndex = articles.findIndex(
        (article) => article?.dossierNames?.length
      );
      if (firstWithDossierIndex !== -1) {
        setSelectedArticleIndex(firstWithDossierIndex);
      }
    }
  }, [articles]);

  useEffect(() => {
    if (!isLoading) {
      setComponentStatuses((prev: any) => ({ ...prev, articlesPreview: true }));
    }
  }, [isLoading, setComponentStatuses]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setSelectedArticleIndex(
          (prevIndex) => (prevIndex + 1) % Math.min(3, articles?.length || 0)
        );
        setFade(false);
      }, 500);
    }, 3000);
    return () => clearInterval(interval); // Cleanup to reset the interval
  }, [articles?.length]); // Reset interval on articles change

  if (isLoading || !articles || articles.length === 0) return null; // Do not render until articles are loaded and non-empty
  if (error) {
    console.error('Failed to load articles preview:', error);
    return null;
  }

  const selectedArticle = articles[selectedArticleIndex];
  if (!selectedArticle) return null; // Do not render if the selected article is not valid

  const backgroundImageUrl =
    selectedArticle?.properties?.Image?.files?.[0]?.file?.url || '';

  const handleArticleClick = (articleId: string) => {
    window.location.href = `/feed/article/${articleId}`;
  };

  const handleSeeAllArticles = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation(); // Prevents the click from bubbling up to the parent container
    navigate('/feed');
  };

  const handleStoryClick = (
    index: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation(); // Prevent event from bubbling up to parent elements
    setSelectedArticleIndex(index);
  };

  return (
    <div
      onClick={() => handleArticleClick(selectedArticle?.id)}
      className="articlePriviewContainer"
    >
      {!isMobile && (
        <div
          className="articlePreviewImg"
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            transition: 'background-image 1s ease-in-out',
          }}
          onClick={() => handleArticleClick(selectedArticle?.id)}
        />
      )}
      <div className={`APcontentContainer ${fade ? 'fade' : ''}`}>
        <div className={'storiesContainer-loggedOut'}>
          {articles.slice(0, 3).map((article, index) => (
            <div
              key={article.id}
              onClick={(event) => handleStoryClick(index, event)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                className={
                  index === selectedArticleIndex
                    ? 'storyIMG-container'
                    : 'storyIMG-placeholder'
                }
              >
                <div
                  className="storyIMG"
                  style={{
                    backgroundImage: `url(${article?.properties?.Image?.files?.[0]?.file?.url || ''})`,
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent triggering handleArticleClick
                    handleArticleClick(article?.id);
                  }}
                />
              </div>
              <p className="storyNumber">0{index + 1}</p>
            </div>
          ))}
        </div>
        {isMobile && (
          <div
            className="articlePreviewImg"
            style={{
              backgroundImage: `url(${backgroundImageUrl})`,
              transition: 'background-image 1s ease-in-out',
            }}
            onClick={() => handleArticleClick(selectedArticle?.id)}
          />
        )}

        <div className="textAndButtonContainer">
          <div
            className="textBlock"
            onClick={() => handleArticleClick(selectedArticle?.id)}
          >
            <p className="skinCareHeader">
              {selectedArticle?.dossierNames?.length ?? 0 ? (
                selectedArticle?.dossierNames?.map((name, index, array) => (
                  <React.Fragment key={index}>
                    <span className="dossierName">{name}</span>
                    {index < array.length - 1 && ' | '}
                  </React.Fragment>
                ))
              ) : (
                null
              )}
            </p>

            <p className="skinCareText">
              {selectedArticle?.properties?.Title?.title?.[0]?.plain_text ||
                'Default Title'}
            </p>
          </div>
          <div className="seeAllArticlesContainer">
            <button className="APHomeblackButton" onClick={handleSeeAllArticles}>
              <p>SEE ALL ARTICLES</p>
            </button>
          </div>
        </div>
      </div>
    </div >
  );
};
