import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Link, useLocation } from 'react-router-dom';
import useRandomProducts from 'hooks/randomProducts';
import './mobileCarousel.scss';
import smalleggShape from '../../assets/icons/smalleggShape.svg';
import backdot from '../../assets/icons/blackproductdot.svg';

interface MobileCarouselProps {
    onSlideChange: (groupIndex: number) => void;
    carouselId: number;
    brandName?: string;
    isRecommanded?: boolean;
    isMiniature?: boolean;
}

export const MobileCarousel: React.FC<MobileCarouselProps> = ({
    onSlideChange,
    carouselId,
    brandName,
    isRecommanded,
    isMiniature = false,
}) => {
    const { randomProducts } = useRandomProducts(carouselId, brandName);
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);

    const location = useLocation();

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const currentProductId = pathParts[pathParts.length - 1];
        setSelectedProduct(currentProductId);
    }, [location.pathname]);

    const handleSlideChange = (swiper: any) => {
        const groupIndex = Math.floor(swiper.activeIndex / 3) + 1;
        onSlideChange(groupIndex);
    };

    return (
        <Swiper
            modules={[FreeMode]}
            slidesPerView={3} // Show 3 slides at a time
            spaceBetween={10} // Spacing between slides
            freeMode={{ enabled: false }} // Disable free mode for strict slide boundaries
            onSlideChange={handleSlideChange}
            className="product-carousel-swiper"
        >
            {randomProducts?.map((item, index) => (
                <SwiperSlide key={index} className="product-slide">
                    <div
                        className={`product-container ${isMiniature && selectedProduct === item._id ? 'selected' : ''}`}
                    >
                        <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className="carousel-image-container">
                                <img
                                    className="carousel-image"
                                    src={item.imgUrl}
                                    alt={item.title}
                                />
                            </div>
                        </Link>
                        {isMiniature && selectedProduct === item._id && (
                            <div className="egg-shape-container">
                                <img src={smalleggShape} alt="egg shape" className="egg-shape" />
                                <div
                                    className="black-dot"
                                    style={{
                                        position: 'absolute',
                                        bottom: '80px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        width: '10px',
                                        height: '10px',
                                        backgroundImage: `url(${backdot})`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                ></div>
                            </div>
                        )}
                        {!isMiniature && (
                            <>
                                <p className="mobile-productBrand">{item.brand}</p>
                                <p className="mobile-productTitle">{item.title}</p>
                            </>
                        )}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
