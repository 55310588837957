import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import backgroundImage from 'assets/images/backgroundHomePageLogin.png';
import { useAtom } from 'jotai';
import { userAtom } from 'stores/UserProfile';

export default function HomepageLoader() {
    const [user] = useAtom(userAtom);
    const [animationClass, setAnimationClass] = useState(styles.fadeIn);

    // Function to determine the greeting based on the time
    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour >= 6 && hour < 12) return "Good Morning";
        if (hour >= 12 && hour < 18) return "Good Afternoon";
        if (hour >= 18 && hour < 22) return "Good Evening";
        return "Good Night";
    };

    const greeting = getGreeting(); // Call the function to get the current greeting

    useEffect(() => {
        const fadeTimeout = setTimeout(() => {
            // Removed setting the fadeOut class to keep the background static
        }, 1500); // Start fade-out after 1.5 seconds

        const removeLoaderTimeout = setTimeout(() => {
        }, 2500); // Total duration of 3 seconds

        return () => {
            clearTimeout(fadeTimeout);
            clearTimeout(removeLoaderTimeout);
        };
    }, []);

    return (
        <div
            className={`${styles.homepageLoader} ${animationClass}`}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
            }}
        >
            <div className={styles.loaderContainer}>
                <div className={styles.goodMorningContainer}>
                    <h1 className={styles.greeting}>{greeting}</h1>
                    <h1 className={styles.name}>{user?.name?.toUpperCase()}</h1>
                </div>
                <div className={styles.letTakeCareContainer}>
                    <p className={styles.letTakeCare}>
                        Let’s take care of your skin!
                    </p>
                </div>
            </div>
        </div>
    );
}
