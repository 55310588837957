import api from './apiConfig';
import { getErrorMessage } from 'service/serviceFunctions';
import { ShopProduct } from '../../types/ShopInterfaces';

export interface Brand {
  brand: string;
}

export default class productsApi {

  static async getProductsByActiveName(activeName: string, groupElements?: string[], synonyms?: string[]): Promise<{ success: boolean; data?: ShopProduct[]; message?: string }> {
    console.log('Search product by -', 'Group elements:', groupElements, 'Acitve name:', activeName, 'Synonyms:', synonyms)
    try {
      const response = await api.post(`/products/by-active-name`, {
        activeName,
        groupElements: groupElements || [],
        synonyms: synonyms || [],
      });

      if (!response.data.products) {
        return { success: false, message: 'No products found for the given active name' };
      }
      return { success: true, data: response.data.products };
    } catch (error) {
      console.error('API error while fetching products by active name:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }


  static async getAllBrands(categories?: string[]): Promise<{ success: boolean; data?: Brand[]; message?: string }> {
    try {
      // Prepare the query string
      const query = categories ? `?categories=${categories.join(',')}` : '';

      // Make the API call with the query string
      const response = await api.get(`/products/brands${query}`);

      return { success: true, data: response.data.brands };
    } catch (error) {
      console.error('API JS error while fetching all brands:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }


  static async getRandomProducts(quantity?: number, subcategory?: string): Promise<{ success: boolean, data?: ShopProduct[], message?: any }> {
    try {
      let url = `/products/random/${quantity}`;
      if (subcategory) { url += `/${subcategory}`; }
      const response = await api.get(url);
      if (response.data.products.length === 0) {
        return { success: false, message: "No products found" };
      }
      // Simplified filter to only check if imgUrl exists
      const validProducts = response.data.products.filter((product: ShopProduct) =>
        product.imgUrl
      );
      return { success: true, data: validProducts };
    } catch (error) {
      console.error('API JS error while fetching random products:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductById(productId: string): Promise<{ success: boolean, data?: ShopProduct, message?: string }> {
    try {
      const response = await api.get(`/products/${productId}`);
      if (!response.data.product) {
        return { success: false, message: "Product not found" };
      }
      return { success: true, data: response.data.product };
    } catch (error) {
      console.error('API JS error while fetching product by ID:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getAllProducts(subcategory?: string): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      let url = `/products/category`;

      if (subcategory) { url += `/${subcategory}`; }
      else { url += `/undefined`; }

      const response = await api.get(url);
      // Simplified filter to only check if imgUrl exists
      const validProducts = response.data.products.filter((product: ShopProduct) =>
        product.imgUrl
      );
      return { success: true, data: validProducts };
    } catch (error) {
      console.error('API JS error while fetching all products:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async getProductsByBrand(brandName: string): Promise<{ success: boolean, data?: ShopProduct[], message?: string }> {
    try {
      const response = await api.get(`/products/brand/${brandName}`);
      // Simplified filter to only check if imgUrl exists
      const validProducts = response.data.products.filter((product: ShopProduct) =>
        product.imgUrl
      );
      return { success: true, data: validProducts };
    } catch (error) {
      console.error('API JS error while fetching products by brand:', getErrorMessage(error));
      return { success: false, message: getErrorMessage(error) };
    }
  }

  static async checkProductAvailability(sku: string): Promise<{ success: boolean, available: boolean, message?: string }> {
    try {
      if (!sku) {
        throw new Error("Product ID is required");
      }

      const payload = {
        product: sku
      };

      const response = await api.post(`/products/checkProductAvailability`, payload);

      const isAvailable = response.data.available > 0;
      return { success: true, available: isAvailable };
    } catch (error) {
      console.error('Error checking product availability:', getErrorMessage(error));
      return { success: false, available: false, message: getErrorMessage(error) };
    }
  }
}