import React, { useState, useRef, useEffect } from 'react';
import styles from './ComingSoonCarouselShell.module.scss';
import { ComingSoonProductsCarousel } from './ComingSoonProductsCarousel';
import { useLocation } from 'react-router-dom';

export const ComingSoonCarousel: React.FC = () => {
    const location = useLocation();
    const isShopPage = location.pathname.includes('/shop');
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [activeGroup, setActiveGroup] = useState(1);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSlideChange = (groupIndex: number) => {
        setActiveGroup(groupIndex);
    };

    return (
        <div className={styles.bestsellersContainer}>
            <div className={styles.containerCarouselHomePage} style={isMobile ? { height: '70%' } : undefined}>
                <ComingSoonProductsCarousel onSlideChange={handleSlideChange} carouselId={14} ref={sliderRef} />
            </div>
        </div >
    );
};
