import { Link, useNavigate } from 'react-router-dom';
import './style.scss';
import { useAtom } from 'jotai';
import { userAtom } from 'stores/UserProfile';
import useRandomProducts from 'hooks/randomProducts';
import { ShopHeadSection } from 'components/shopPage/ShopHeadSection/ShopHeadSection';
import { PopularCategories } from 'components/shopPage/PopularCategories/PopularCategories';
import { LargePhotoSection } from 'components/shopPage/LargePhotoSection/LargePhotoSection';
import { BestSellersCarousel } from 'shared/elements/BestSellersCarousel/BestSellersCarousel';
import { ValentineDays } from 'components/shopPage/ValentineDays/ValentineDays';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import Dot from 'assets/icons/blackDot.svg';
import { useState } from 'react';
import { BestSellersBrandSection } from 'components/homepage/BestSellersSection/BestSellersBrandSection';
import { BestSellersSection } from 'components/homepage/BestSellersSection/BestSellersSection';

export const ShopPage = () => {
  const navigate = useNavigate();
  const { randomProducts } = useRandomProducts(6);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [user] = useAtom(userAtom);
  return (
    <>
      <div className='shopPageContainer'>

        <ShopHeadSection />
        <PopularCategories />

        <div className="recommendedBlock">
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <div className="flexContainer" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Dot} alt="dot" className="dotIcon" />
                <p className="recTextMain">Selected By olis lab</p>
              </div>
            </div>
            <p className="discover">Discover the world of Oli for a perfect routine</p>
            <button className="APhomeblackButton" style={{ cursor: 'pointer' }} onClick={() => navigate('/bestsellers')}>
              SEE MORE
            </button>
          </div>

          <div className="cardsContainer">

            {
              randomProducts?.map((item, index) => {
                return (
                  <Link to={`/product/${item._id}`} className="productContainer" key={index}
                    onMouseEnter={() => setHoveredItem(index)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <div>
                      <p className="recText">Just Dropped</p>
                    </div>
                    <div className="productImage" style={{
                      backgroundImage: `url(${window.innerWidth < 768
                        ? item.imgUrl
                        : hoveredItem === index && item.textureImgUrl
                          ? item.textureImgUrl
                          : item.imgUrl
                        })`,
                    }} />
                    <div className="productShopInfo">
                      <p className="productShopBrand">{item.brand}</p>
                      <p className="productShopTitle">{item.title}</p>
                    </div>
                  </Link>
                )
              })}
          </div>
        </div>
        <LargePhotoSection />
        <div className='spaceSection'>
          <span className='spaceText'>
            bestsellers
          </span>
        </div>
        <BestSellersSection isLoggedIn={!!user} />
        <ValentineDays />
        <FooterSection />
      </div>
    </>

  );
};
