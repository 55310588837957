import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { skinProfileAtom } from '../../../stores/SkinProfiles';
import { TRisk } from '../../../types/TInterfaces';
import { useNavigate } from 'react-router-dom';
import SignUpBackgroudImage from '../../../assets/images/formBackground.png';
import { userAtom } from 'stores/UserProfile';
import { HomepageNavbar } from '../../../components/navbars/HomepageNavbar/HomepageNavbar';
import { StepIndicator } from 'components/counter/counter';
import styles from '../style.module.scss';
export const SPF_Risks: React.FC = () => {
  const [skinProfile, setSkinProfile] = useAtom(skinProfileAtom);
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const [selectedRisks, setSelectedRisks] = useState<TRisk[]>([]);
  const risks: TRisk[] = [
    'Allergy or Allergen Sensitivity',
    'Cancers',
    'Immunotoxicity',
    'Skin Sensitivity',
    'Endocrine Disruptor',
    'To Define'
  ];

  const handleRiskSelect = (risk: TRisk) => {
    setSelectedRisks((prev) => (prev.includes(risk) ? prev.filter((r) => r !== risk) : [...prev, risk]));
  };

  useEffect(() => {
    if (user && user.SPF && Array.isArray(user.SPF.risks)) {
      setSelectedRisks(user.SPF.risks);
    } else {
      console.warn('No valid risks found or user is null.');
    }
  }, [user]);

  const goToNext = () => {
    if (selectedRisks.length) {
      setSkinProfile({ ...skinProfile, risks: selectedRisks });
      navigate('/spf-form/spf-resume');
    }
  };

  return (
    <div className={styles.headerBlockFormsSkin} style={{ backgroundImage: `url(${SignUpBackgroudImage})` }}>
      <HomepageNavbar />
      <div className={styles.formContainer}>
        <div className={styles.formContentContainer}>
          <StepIndicator currentStep={4} />
          <div className={styles.titleContainer}>
            <h1 className={styles.TitlesSpf}>DEFINE YOUR ATTENTION TO RISK?</h1>
          </div>
          <div className={styles.subtitleContainer}>
            <h5 className={styles.SubtitleSpf}>Time is beautiful and unique, your age allows us to best meet your needs.</h5>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.concernsInputGroup}>
              {risks.map((risk) => (
                <button
                  key={risk}
                  className={`${styles.inputConcernsButtons} ${selectedRisks.includes(risk) ? styles.selected : ''}`}
                  onClick={() => handleRiskSelect(risk)}
                >
                  {risk}
                </button>
              ))}
            </div>
            <div className={styles.concernsButtonContainer}>
              <button className={styles.nextButtonForm} disabled={!selectedRisks.length} onClick={goToNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
