import axios from 'axios';
import { config } from 'config';
import { DeliveryService as DeliveryServiceType } from '../../types/TInterfaces';

class deliveryApi {
  async fetchDeliveryServices(selectedCountry?: string): Promise<{
    uniqueCountries: Pick<DeliveryServiceType, 'country'>[],
    allServices: DeliveryServiceType[],
    filteredServices: DeliveryServiceType[]
  }> {
    try {
      const response = await axios.get(`${config.REACT_APP_API_URL}/delivery-service/services`);
      const allServices = response.data;

      const uniqueCountries = Array.from(new Set(allServices.map((service: DeliveryServiceType) => service.country)))
        .filter(Boolean)
        .map(country => ({ country: country as string } as Pick<DeliveryServiceType, 'country'>));

      const filteredServices = selectedCountry
        ? allServices.filter((service: DeliveryServiceType) => service.country === selectedCountry)
        : allServices;

      return { uniqueCountries, allServices, filteredServices };
    } catch (error) {
      console.error('Error fetching delivery services:', error);
      return { uniqueCountries: [], allServices: [], filteredServices: [] };
    }
  }

  async fetchRelayPoints(postalCode: string, countryCode: string): Promise<any[]> {
    try {
      const response = await axios.post(`${config.REACT_APP_API_URL}/mondialRelay/relay-points`, {
        postalCode,
        countryCode,
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching relay points:', error);
      return [];
    }
  }
}

export default new deliveryApi();