import React, { useState } from 'react';
import Slider from 'react-slick';
import './style.scss';
import { useNotionDossiers, useNotionArticles } from 'hooks/useNotionArticles';
import { useNavigate } from 'react-router-dom';

export const Dossiers: React.FC = () => {
  const { data: dossiers, isLoading, error } = useNotionDossiers();
  const { data: articles } = useNotionArticles();
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading dossiers</div>;

  const filteredDossiersWithCount = dossiers
    ?.map((dossier) => {
      const relatedArticleIds = dossier.properties['📰 Articles'].relation.map(rel => rel.id);
      const dossierArticles = articles?.filter(article => relatedArticleIds.includes(article.id));
      return {
        ...dossier,
        articleCount: dossierArticles?.length || 0,
      };
    })
    .filter((dossier) => dossier.articleCount > 0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipeToSlide: true,
    arrows: false,
    variableWidth: true,
    draggable: true,
    touchMove: true,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const handleDossierClick = (dossierId: string) => {
    if (!isDragging) {
      navigate(`/feed/dossier/${dossierId}`);
    }
  };

  return (
    <section className="dossiers-section">
      <div className="dossiers-header">
        <h2>DOSSIERS</h2>
        <div className="dossiers-count">{filteredDossiersWithCount?.length || 0} TOPICS</div>
      </div>
      <Slider {...settings}>
        {filteredDossiersWithCount?.map((dossier) => (
          <div key={dossier.id} className="dossier-item" onClick={() => handleDossierClick(dossier.id)}>
            <div
              className="dossier-background"
              style={{ backgroundImage: `url(${dossier.properties.Image.files[0]?.file.url})` }}
            >
              <div className="dossier-content">
                <h3>{dossier.properties.Name.title[0]?.plain_text}</h3>
                <div className="article-count">{dossier.articleCount} Articles</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};
