import React from 'react';
import styles from './ProfileSettings.module.scss';
import { useAtom } from 'jotai';
import { userAtom } from '../../../stores/UserProfile';
import { AccountInfo } from './components/AccountInfo/AccountInfo';
import { AddressBook } from './components/AddressBook/AddressBook';
import { Security } from './components/Security/Security';
import { ContactBox } from './components/ContactBox/ContactBox';
import { CautionBox } from './components/CautionBox/CautionBox';
import { HomepageNavbar } from 'components/navbars/HomepageNavbar/HomepageNavbar';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { useNavigate } from 'react-router-dom';

export const ProfileSettings: React.FC = () => {
    const isMobile = window.innerWidth < 768;
    const [user] = useAtom(userAtom);
    const navigate = useNavigate();

    const handleEditInfo = () => {
        navigate('/profile/settings/edit-account');
    }

    const handleAddress = () => {
        navigate('/profile/settings/add-address');
    }

    const handleEditSec = () => {
        navigate('/profile/settings/change-password');
    }

    return (
        <>
            {!isMobile ? (
                <div className={styles.desktopContainer}>
                    <HomepageNavbar />

                    <div className={styles.topSection}>
                        <div className={styles.menuTopSection}>
                            {/* PLACE HOLDER DONT REMOVE */}
                        </div>
                        <div className={styles.contentTopSection}>
                            <h3 className={styles.userName}>{user?.name}</h3>
                            <h3 className={styles.userName}>{user?.lastName}</h3>
                        </div>
                    </div>

                    <div className={styles.bottomSection}>
                        <div className={styles.menuSection}>
                            <ProfileMenu />
                        </div>
                        <div className={styles.contentSection}>
                            <CautionBox />
                            <AccountInfo />
                            <AddressBook />
                            <Security />
                            <ContactBox />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div>
                        <h1 className={styles.mobileTitle}>Settings</h1>
                    </div>
                    <div>
                        <div className={styles.mobileFunctionContainer}>
                            <h1 className={styles.mobileFunctionTitle}>Account information</h1>
                            <button className={styles.editButton} onClick={handleEditInfo}>edit</button>
                        </div>
                        <div className={styles.mobileFunctionContainer}>
                            <h1 className={styles.mobileFunctionTitle}>Address book</h1>
                            <button className={styles.editButton} onClick={handleAddress}>edit</button>
                        </div>
                        <div className={styles.mobileFunctionContainer}>
                            <h1 className={styles.mobileFunctionTitle}>Security</h1>
                            <button className={styles.editButton} onClick={handleEditSec}>edit</button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
