import { useQuery, UseQueryResult } from '@tanstack/react-query';
import activesApi from '../service/API/activesApi'
import { Active } from '../types/TInterfaces';

const fetchAllActives = async (): Promise<Active[]> => {
    const response = await activesApi.getAllActives();
    if (!response.success) {
        throw new Error(response.message || 'Failed to fetch actives');
    }
    return response.data || [];
};

interface UseAllActivesResult {
    allActives: Active[] | undefined;
    allActivesIsLoading: boolean;
    allActivesError: unknown;
}

export const useAllActives = (): UseAllActivesResult => {
    const { data, isLoading, error }: UseQueryResult<Active[], Error> = useQuery({
        queryKey: ['allActives'],
        queryFn: fetchAllActives,
        staleTime: 5 * 24 * 60 * 60 * 1000,
        gcTime: 5 * 24 * 60 * 60 * 1000,
    });

    return { allActives: data, allActivesIsLoading: isLoading, allActivesError: error };
};

export default useAllActives;
