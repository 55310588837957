import { useState, useEffect, useRef } from 'react';

const useResizeObserver = () => {
    const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            if (entries.length) {
                const { contentRect } = entries[0];
                setDimensions({ width: contentRect.width, height: contentRect.height });
            }
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return [ref, dimensions] as const;
};

export default useResizeObserver;
