import styles from "./style.module.scss";
import { useAllBrands } from 'hooks/useAllBrands';
import { useEffect, useRef, useState } from "react";
import { FooterSection } from "components/homepage/FooterSection/FooterSection";
import { ShopHeadBestsellers } from "components/shopPage/ShopHeadBestsellers/ShopHeadBestsellers";
import hotBrandsImage from 'assets/images/hotBrands.png';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import checkboxIcon from 'assets/icons/Checkbox.svg';
import { backgroundBrands as allBackgroundBrands } from 'assets/brands/backgrounds/backgroundBrands'; // Adjust the path as necessary
import { Brand } from "service/API/ProductsApi";
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';

// Install Swiper modules

// Define or extend the Brand type to include categories
type BrandWithCategories = Brand & {
    categories?: string[];
};

// Define the type for the grouped brands object
type GroupedBrands = {
    [key: string]: any[];
};

export const HotBrands = () => {
    const { data, error, isLoading } = useAllBrands();
    const brandsCount = data?.length || 0;
    const [activeLetter, setActiveLetter] = useState<string | null>(null);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [activeGroup, setActiveGroup] = useState(1);
    const sliderRef = useRef<any>(null);

    // Filter background brands to exclude "default"
    const backgroundBrands = allBackgroundBrands.filter(
        (brand) => brand.name.toLowerCase() !== "default"
    );

    // Extract unique categories from the data
    const uniqueCategories = [
        'ALL',
        ...new Set(
            data
                ?.flatMap((brand: BrandWithCategories) => brand.categories || [])
                .map((category) => category.toLowerCase()) || []
        )
    ].sort();

    // Filtered brands based on selected categories
    const filteredBrands = selectedCategories.length > 0
        ? data?.filter((brand: BrandWithCategories) =>
            brand.categories?.some((category) => selectedCategories.includes(category.toLowerCase()))
        )
        : data;

    // Group filtered brands by the first letter of each brand name
    const groupedBrands = filteredBrands?.reduce((acc: GroupedBrands, brand) => {
        if (brand.brand && typeof brand.brand === 'string') {
            const firstLetter = brand.brand[0].toUpperCase();
            if (!acc[firstLetter]) {
                acc[firstLetter] = [];
            }
            acc[firstLetter].push(brand);
        }
        return acc;
    }, {} as GroupedBrands);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const container = event.currentTarget;
        const elements = container.querySelectorAll(`.${styles.hotbrandsletter}`);
        elements.forEach((elem: Element) => {
            if ((elem as HTMLElement).offsetTop - container.scrollTop < 20) {
                setActiveLetter((elem as HTMLElement).innerText);
            }
        });
    };

    const handleCategoryChange = (category: string) => {
        setSelectedCategories((prev) =>
            category === 'ALL'
                ? []
                : prev.includes(category)
                    ? prev.filter((c) => c !== category)
                    : [...prev, category]
        );
    };

    const handleSlideChange = (swiper: any) => {
        const groupIndex = Math.ceil((swiper.activeIndex + 1) / 4);
        setActiveGroup(groupIndex);
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleNumberClick = (groupIndex: number) => {
        setActiveGroup(groupIndex);
        if (sliderRef.current?.swiper) {
            sliderRef.current.swiper.slideTo((groupIndex - 1) * 4);
        }
    };

    return (
        <>
            <ShopHeadBestsellers backgroundImage={hotBrandsImage} brandsCount={brandsCount} />
            <div className={styles.hotbrandscontainer}>
                <h1 className={styles.hotbrandspopularbrandstitle}>Popular brands</h1>
                <div className={styles.swiperpaginationcontainer}>
                    <ScrollNumberIndicator
                        count={Math.ceil(backgroundBrands.length / 4)}
                        activeNumber={activeGroup}
                        fontSize={12}
                        onNumberClick={handleNumberClick}
                        color="black"
                    />
                </div>
                <Swiper
                    slidesPerView={isMobile ? 1.4 : 4}
                    spaceBetween={10}
                    onSlideChange={handleSlideChange}
                    loop={false}
                    ref={sliderRef}
                >

                    {backgroundBrands.map((brand, index) => (
                        <SwiperSlide key={index}>
                            <Link to={`/brand/${encodeURIComponent(brand.name)}`}>
                                <div className={styles.hotbrandsimagecontainer}>
                                    <span className={styles.hotbrandspopularoverlay}>popular</span>
                                    <img className={styles.hotbrandsimage} src={brand.image} alt={brand.name} />
                                    <span className={styles.hotbrandsnameoverlay}>{brand.name}</span>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={styles.categoriesandbrandscontainer}>
                    <h2 className={styles.hotbrandstitle}>All Brands</h2>
                    <div className={styles.hotbrandscategoriescontainer}>
                        {uniqueCategories.map((category, index) => (
                            <label key={index} className={styles.hotbrandscategory}>
                                <input
                                    type="checkbox"
                                    className={styles.hiddencheckbox}
                                    checked={selectedCategories.includes(category)}
                                    onChange={() => handleCategoryChange(category)}
                                />
                                <img className={styles.checkboxcustom} src={checkboxIcon} alt="checkbox" />
                                <span>{category}</span>
                            </label>
                        ))}
                    </div>
                    <hr className={styles.hotbrandsseparator}></hr>
                    <div className={styles.hotbrandslistcontainer} onScroll={handleScroll}>
                        {Object.entries(groupedBrands || {}).map(([letter, brands]) => (
                            <div key={letter}>
                                <h3
                                    className={`${styles.hotbrandsletter} ${activeLetter === letter ? 'active' : ''
                                        }`}
                                >
                                    {letter}
                                </h3>
                                <div className={styles.hotbrandslist}>
                                    {brands.map((brand, index) => (
                                        <div key={index} className={styles.hotbranditem}>
                                            <Link
                                                to={`/brand/${encodeURIComponent(brand.brand)}`}
                                                className={styles.hotbrandlink}
                                            >
                                                <span className={styles.numberinlist}>
                                                    {index + 1}
                                                </span>
                                                <span className={styles.brandname}>{brand.brand}</span>
                                                <span className={styles.hotbrandproductcount}>
                                                    ({brand.productCount})
                                                </span>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.hotbrandsfootercontainer}>
                <FooterSection />
            </div>
        </>
    );
};
