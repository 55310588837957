import React, { useState, useEffect, forwardRef } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import ProductsApi from 'service/API/ProductsApi';
import './style.scss';
import { config } from '../../config';
import { useAtom } from 'jotai';
import { componentLoadingStatusAtom } from 'stores/UserProfile';

interface ProductsCarouselProps {
    onSlideChange: (groupIndex: number) => void;
}

export const BrandProductsCarousel = forwardRef<SwiperRef, ProductsCarouselProps>((props, ref) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [brandInfo, setBrandInfo] = useState<any[]>([]);
    const [hoveredItem, setHoveredItem] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);

    const isMobile = window.innerWidth < 768;
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await ProductsApi.getProductsByBrand(config.REACT_APP_BRAND_NAME);
                if (response.success && response.data) {
                    setBrandInfo(response.data);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Failed to fetch products:', error);
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        if (!loading) {
            console.log("Loading complete, setting BrandProductsCarousel status to true.");
            setComponentStatuses((prev) => ({ ...prev, brandProductsCarousel: true }));
        }
    }, [loading, setComponentStatuses]);

    return (
        <Swiper
            {...props} ref={ref}
            spaceBetween={10}
            slidesPerView={isMobile ? 1.2 : 4}
            onSlideChange={(swiper: any) => {
                setCurrentSlide(swiper.activeIndex);
                const groupIndex = Math.floor(swiper.activeIndex / 2) + 1;
                props.onSlideChange(groupIndex);
            }}
            className="productsCarouselSwiperBlock"
        >
            {brandInfo.map((item, index) => (
                <SwiperSlide key={index}>
                    <div className="productContainer">
                        <Link
                            to={`/product/${item._id}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                            onMouseEnter={() => setHoveredItem(index)}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            <img
                                className="clickableImage"
                                src={hoveredItem === index && item.textureImgUrl ? item.textureImgUrl : item.imgUrl}
                                alt={item.title}
                            />
                        </Link>
                        <Link to={`/product/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div style={{ padding: 20, textAlign: 'center' }}>
                                <p className="productBrand">{item.brand}</p>
                                <p className="productTitle">{item.title}</p>
                            </div>
                        </Link>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
});
