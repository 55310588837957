import React, { useEffect, useState, forwardRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';
import styles from './ComingSoonProductsCarousel.module.scss';
import { useAtom } from 'jotai';
import { componentLoadingStatusAtom } from 'stores/UserProfile';
import useRandomProducts from 'hooks/randomProducts';

interface ComingSoonProductsCarouselProps {
    onSlideChange: (groupIndex: number) => void;
    carouselId: number;
}

export const ComingSoonProductsCarousel = forwardRef<any, ComingSoonProductsCarouselProps>(
    ({ onSlideChange, carouselId }, ref) => {
        const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
        const { randomProducts, loading } = useRandomProducts(carouselId);
        const [hoveredItem, setHoveredItem] = useState<number | null>(null);
        const [productCount, setProductCount] = useState(0);
        const [componentStatuses, setComponentStatuses] = useAtom(componentLoadingStatusAtom);

        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth < 768);
            };
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        useEffect(() => {
            if (!loading) {
                console.log("Loading complete, setting ProductsCarousel status to true.");
                setComponentStatuses((prev: any) => ({ ...prev, ProductsCarousel: true }));
            }
        }, [loading, setComponentStatuses]);

        useEffect(() => {
            setProductCount(randomProducts.length);
        }, [randomProducts]);

        const handleMouseEnter = (index: number) => setHoveredItem(index);
        const handleMouseLeave = () => setHoveredItem(null);

        return (
            <Swiper
                spaceBetween={10}
                modules={[FreeMode]}
                slidesPerView={randomProducts.length < 4 ? 3 : (isMobile ? 1.2 : 4)}
                onSlideChange={(swiper: any) => {
                    const groupIndex = Math.floor(swiper.activeIndex / 3) + 1;
                    onSlideChange(groupIndex);
                }}
                loop={false}
                className={styles.productsCarouselSliderBlock}
                ref={ref}
                style={{ marginLeft: productCount > 4 ? 0 : 60 }}
            >
                {randomProducts?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className={styles.productContainer}>
                            <div
                                className={styles.clickableImage}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                onTouchStart={() => setHoveredItem(index)}
                                onTouchEnd={() => setHoveredItem(null)}
                            >
                                <img
                                    src={
                                        isMobile
                                            ? item.imgUrl
                                            : hoveredItem === index && item.textureImgUrl
                                                ? item.textureImgUrl
                                                : item.imgUrl
                                    }
                                    className={styles.carouselImage}
                                    alt={item.title}
                                />
                            </div>
                            <div className={styles.productInfo}>
                                <p className={styles.productBrand}>{item.brand}</p>
                                <p className={styles.productTitle}>{item.title}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }
);
