import React from 'react';
import { Hero } from './components/Hero/Hero';
import { Dossiers } from './components/Dossiers/Dossiers';
import { Guide } from './components/Guide/Guide';
import { MostSearchedProducts } from './components/MostSearchedProducts/MostSearchedProducts';
import { GuideSteps } from './components/GuideSteps/GuideSteps';
import { FooterSection } from '../../components/homepage/FooterSection/FooterSection';
import './style.scss';

export const Feed: React.FC = () => {
  return (
    <div className="feed-container">
      <Hero />
      <Dossiers />
      <Guide />
      {/* <MostSearchedProducts />
      <GuideSteps /> */}
      <FooterSection />
    </div>
  );
};
