import React from 'react';
import styles from './OrderTotal.module.scss';
import { IOrder } from 'types/TInterfaces';
import { ProductLoader } from "pages/ProductDetails/ProductLoader";

interface OrderInfoProps {
    order: IOrder | null;
}

export const OrderTotal: React.FC<OrderInfoProps> = ({ order }) => {
    if (!order) {
        return <ProductLoader />;
    }

    const getFormattedShippingCost = (cost: number | string): string => {
        const shippingCost = typeof cost === 'number' ? cost : parseFloat(String(cost));
        return !isNaN(shippingCost) ? shippingCost.toFixed(2) : '0.00';
    };

    const getSubtotal = (): string => {
        const subtotal = parseFloat(order.totalPrice.toFixed(2)) - parseFloat(getFormattedShippingCost(order.shipping.totalShippingCost));
        return subtotal.toFixed(2);
    };

    const itemsCount = (order: IOrder): number => {
        return order.products.reduce((total, product) => total + product.quantity, 0);
    };

    return (
        <div className={styles.orderTotalContainer}>
            <div className={styles.orderTotal}>
                <div className={styles.row}>
                    <span className={styles.label}>Subtotal ({itemsCount(order)})</span>
                    <span className={styles.price}>€{getSubtotal()}</span>
                </div>
                <div className={styles.row}>
                    <span className={styles.label}>Processing</span>
                    <span className={styles.price}>€{getFormattedShippingCost(order.shipping.totalShippingCost)}</span>
                </div>
                <div className={`${styles.totalRow}`}>
                    <span className={styles.label}>Total</span>
                    <span className={styles.price}>€{order.totalPrice.toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
};
