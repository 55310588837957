import './style.scss';
import Background from 'assets/images/formBackground2.png';
import FontImg from 'assets/textFont/latestTrends.svg';
import latetTrendsFont from 'assets/textFont/Latest trends1.svg';
import latetTrendsFont2 from 'assets/textFont/Latest trends2.svg';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { useNavigate, Link } from 'react-router-dom';

interface LatestTrendsProps {
  isComingSoonPage?: boolean;
}

export const LatestTrends: React.FC<LatestTrendsProps> = ({ isComingSoonPage = false }) => {
  const navigate = useNavigate();

  const handleSeeAllArticles = () => {
    navigate('/feed');
  };

  return (
    <div className="LatestTrendsContainer" style={{ backgroundImage: `url(${Background})` }}>
      {window.innerWidth > 768 ? (
        <img src={FontImg} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
      ) : (
        <>
          <img src={latetTrendsFont} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
          <img src={latetTrendsFont2} alt="Latest Trends" className="LatestTrendsContainerTextIMG" />
        </>
      )}

      {!isComingSoonPage && (<p className="LatestTrendsContainerHeader">Ensure that you remain up-to-date with the latest trends and discoveries in the skincare industry.</p>)}
      {isComingSoonPage && (<p className="LatestTrendsContainerHeader">With our unique editorials and newsletters, we at Oli's Lab strive to help you with all of your skincare concerns while also keeping you informed about the newest advancements and trends.</p>)}
      {!isComingSoonPage && (<div className="LatestTrendsContainerButton">
        <button className="LatestTrendsContainerButtonText" onClick={handleSeeAllArticles}>See all articles</button>
      </div>)}
    </div>
  );
};
