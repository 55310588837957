//const dotenv = require('dotenv');
//dotenv.config();
export const config = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL || 'https://l2ymsa6gyj.execute-api.us-east-1.amazonaws.com',
  REACT_APP_STRIPE_SECRET: process.env.REACT_APP_STRIPE_SECRET,
  REACT_APP_SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || '',
  ENV: process.env.REACT_APP_ENV || 'local',
  REACT_APP_BRAND_NAME: 'Hyeja',
  REACT_APP_COMING_SOON: process.env.REACT_APP_COMING_SOON,
  REACT_APP_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
};
