import './style.scss';
import ImgBackground from 'assets/images/valentineDaysBack.jpg';
import PerfectVD from 'assets/textFont/PerfectVD.svg';
import november from 'assets/textFont/november_edit.svg';
import background from 'assets/images/PopularCard1.png';
import useRandomProducts from 'hooks/randomProducts';
import { Swiper, SwiperSlide } from 'swiper/react';
import AddToFavorite from 'assets/icons/addToFavorite.svg';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { useState } from 'react';
import novemberBG from 'assets/images/november-edit-bg.png';
import { Link, useNavigate } from 'react-router-dom';
import ourSelectionBG from 'assets/images/our-selection-bg.png';



export const ValentineDays = () => {
  const { randomProducts } = useRandomProducts(4);
  const [activeIndex, setActiveIndex] = useState(1);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  const handleSlideChange = (swiper: any) => {
    setActiveIndex(swiper.activeIndex + 1);
  };


  const handleNumberClick = (groupIndex: number) => {
    setActiveIndex(groupIndex);
    const swiperElement = document.querySelector('.vertical-swiper') as any;
    const swiperInstance = swiperElement?.swiper;
    if (swiperInstance) {
      swiperInstance.slideTo(groupIndex - 1);
    }
  };
  return (
    <div className="ValentineDaysContainer">
      <div
        className="ValentineDaysImageContainer"
        style={{ backgroundImage: `url(${background})` }}
        onClick={() => {
          navigate('/bestsellers', {
            state: {
              customTitle: 'NOVEMBER EDIT',
              headerBackground: isMobile ? background : novemberBG
            }
          });
        }}
      >
        <div className="contentContainer">
          <div className="ValentineDaysProductsCountContainer">
            <p className="ValentineDaysProductsCount">19 products</p>
          </div>
          <div className="ValentineDaysProductsTitle" style={{ backgroundImage: `url(${november})` }} />
          <p className="ValentineDaysDiscover">Discover selection</p>
        </div>
      </div>

      <div className="productsSwiper" style={{ position: 'relative' }}>
        <div className="vertical-swiper-indicator" style={{ position: 'absolute', right: 0, top: 0, cursor: 'default' }}>
          <ScrollNumberIndicator
            direction="vertical"
            activeNumber={activeIndex}
            count={randomProducts?.length}
            fontSize={12}
            color="black"
            onNumberClick={handleNumberClick}
          />
        </div>
        <Swiper
          direction="vertical"
          slidesPerView={1}
          spaceBetween={2}
          mousewheel={true}
          className="vertical-swiper"
          onSlideChange={handleSlideChange}
        >
          {randomProducts?.map((item, index) => (
            <SwiperSlide key={index} className="vertical-slide">
              <div
                onClick={() => navigate(`/product/${item._id}`)}
                className="vertical-carousel-image"
                style={{
                  backgroundImage: `url(${window.innerWidth < 768
                    ? item.imgUrl
                    : hoveredItem === index && item.textureImgUrl
                      ? item.textureImgUrl
                      : item.imgUrl
                    })`,
                }}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
              />
              <p className="ValentineDaysJustDropped">Just dropped</p>
              <div className="vertical-carousel-content">
                <Link to={`/product/${item._id}`}>
                  <p className="vertical-carousel-brand">{item.brand}</p>
                  <p className="vertical-carousel-title">{item.title}</p>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
