import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

export const handleLogOut = (setUser: Dispatch<SetStateAction<any>>, navigate: ReturnType<typeof useNavigate>) => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userOrders');
    navigate('/');
};