import './style.scss';
import { useEffect } from 'react';
import { userAtom } from 'stores/UserProfile';
import { HeadSection } from 'components/homepage/HeadSection/HeadSection';
import { BestSellersSection } from 'components/homepage/BestSellersSection/BestSellersSection';
import { ArticlesCarousel } from 'components/homepage/ArticlesCarousel/ArticlesCarousel';
import { ScanningFeature } from 'components/homepage/ScanningFeature/ScanningFeature';
import { SaferIngredients } from 'components/homepage/SaferIngredients/SaferIngredients';
import { LatestTrends } from 'components/homepage/LatestTrends/LatestTrends';
import { ArticlesPreview } from 'components/homepage/ArticlesPreview/ArticlesPreview';
import { FooterSection } from 'components/homepage/FooterSection/FooterSection';
import { BestSellersBrandSection } from 'components/homepage/BestSellersSection/BestSellersBrandSection';
import HeaderBackgroundImage from 'assets/images/headSectionBackground.jpg';
import { useAtom } from 'jotai';
import globalService from 'service/GlobalService';

export const Homepage = () => {
  const [user, setUser] = useAtom(userAtom);

  useEffect(() => {
    globalService.loadUserFromStorage(setUser);
  }, [setUser]);



  return (
    <>
      <div className='homepageContainer'>
        <HeadSection backgroundImage={HeaderBackgroundImage} />
        <BestSellersSection isLoggedIn={!!user} />
        <ScanningFeature />
        <BestSellersBrandSection />
        <ArticlesCarousel />
        <SaferIngredients />
        <LatestTrends />
        <ArticlesPreview isLoggedIn={!!user} />
        <FooterSection />
      </div>
    </>
  );
};
