import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { userAtom } from '../../../stores/UserProfile';
import { useAtom } from 'jotai';
import { handleLogOut } from '../utils/authUtils';
import blackdot from '../../../assets/icons/blackDot.svg'
import { useNotionArticles } from '../../../hooks/useNotionArticles';
import userService from 'service/UserService';
import './style.scss';

interface MenuProps {
    isVisible: boolean;
    toggleVisibility: () => void;
}

const Menu: React.FC<MenuProps> = ({ isVisible, toggleVisibility }) => {
    const [user, setUser] = useAtom(userAtom);
    const navigate = useNavigate();
    const { data: articles } = useNotionArticles();

    // Effect to toggle body overflow based on menu visibility
    useEffect(() => {
        document.body.style.overflow = isVisible ? 'hidden' : 'auto';
    }, [isVisible]);

    const menuItems = [
        { title: 'Shop +', target: '/shop' },
        { title: 'Hot Brands', target: '/hot-brands' },
        { title: 'Bestsellers', target: '/bestsellers' },
        { title: 'Feed', target: '/feed' },
        ...(user && !userService.isAnonymousUser(user) ? [{ title: 'Profile', target: '/profile' }] : []),
    ];

    const isAnonymousUser = !user || Object.keys(user).length === 0;

    const logout = () => handleLogOut(setUser, navigate);

    return (
        <div className={`menu-overlay ${isVisible ? 'visible' : ''}`}>
            <div className={`menuContainer ${isVisible ? 'visible' : ''}`} style={{ display: isVisible ? 'flex' : 'none' }}>
                <div className="menuHeader">
                    <div className="menuHeaderCloseContainer">
                        <button onClick={toggleVisibility} className="menuCloseButton" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={blackdot} alt="blackdot" className="menuContainerCloseIcon" />
                            <p className="menuContainerCloseText" style={{ marginLeft: '10px' }}>CLOSE</p>
                        </button>
                    </div>
                    <button className="menuContainerCloseButton"
                        onClick={() => {
                            if (isAnonymousUser) {
                                navigate('/login');
                            } else {
                                logout();
                            }
                        }}

                    >
                        <p className="menuContainerLoginText">
                            {isAnonymousUser ? 'SIGN UP' : 'LOG OUT'}
                        </p>
                    </button>

                </div>

                <div className="menuContainerMenuItemsContainer">
                    <ul>
                        {menuItems.map((item, index) => (
                            <li
                                className="menuContainerMenuItem"
                                key={index}
                                onClick={() => navigate(item.target)}
                            >
                                {item.title}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="menuContainerTrendingContainer">
                    <p className="menuContainerTrendingText">TRENDING</p>
                </div>

                {/* Rendering articles */}
                <div className="menuContainerArticlesContainer">
                    {articles?.slice(0, 2).map((article) => (
                        <Link
                            to={`/feed/article/${article.id}`}
                            key={article.id}
                            style={{ textDecoration: 'none', color: 'white' }}
                        >
                            <div
                                className="menuContainerImgBlock"
                                style={{ backgroundImage: `url(${article.properties.Image.files[0]?.file.url})` }}
                            >
                                <div className="dossiersContainer">
                                    {article?.dossierNames?.length ?? 0 > 0 ? (
                                        article?.dossierNames?.map((name, index) => (
                                            <div key={index} className="menuContainerImgCategoryBlock">
                                                <p className="menuContainerImgCategory">
                                                    <span className="dossierName">{name.toUpperCase()}</span>
                                                </p>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="menuContainerImgCategoryBlock">
                                            <p className="menuContainerImgCategory">
                                                <span>NO DOSSIERS</span>
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="menuContainerImgHeaderContainer">
                                    <p className="menuContainerImgHeader">{article.properties.Title.title[0]?.plain_text}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="social-links">
                    <a className="menuContainerSocialLink" href="https://www.instagram.com/olis.lab" target="_blank" rel="noopener noreferrer">
                        Instagram
                    </a>
                    <a className="menuContainerSocialLink" href="https://www.tiktok.com/@olis_lab" target="_blank" rel="noopener noreferrer">
                        Tiktok
                    </a>
                    <a className="menuContainerSocialLink" href="https://www.linkedin.com/company/olis-lab" target="_blank" rel="noopener noreferrer">
                        LinkedIn
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Menu;
