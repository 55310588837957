import React from 'react';
import { useEffect, useState, useRef } from 'react';
import './style.scss';
import { ProductsCarousel } from 'components/carousels/ProductsCarousel';
import { ScrollNumberIndicator } from 'shared/ScrollNumberIndicator/ScrollNumberIndicator';
import { DefaultButton } from 'shared/buttons/DefaultButton/DefaultButton';
import { MobileCarousel } from 'components/carousels/mobileCarousel';

export const RecentlyViewed: React.FC = () => {
  const [activeGroup, setActiveGroup] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const sliderRef = useRef<any>(null);

  const handleNumberClick = (groupIndex: number) => {
    setActiveGroup(groupIndex);
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideTo((groupIndex - 1) * 4);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const handleSlideChange = (groupIndex: number) => {
    setActiveGroup(groupIndex);
  };
  return (
    <>
      {isMobile && (
        <p className="mobile-header">Recently Viewed</p>
      )}

      <div className="recommandedContainer" style={{ backgroundColor: '#F7F6E7' }}>
        <div className="BSheaderBlock">
          {!isMobile && (
            <p className="header">Recently Viewed</p>
          )}
        </div>

        {window.innerWidth > 768 ? (
          <ProductsCarousel onSlideChange={handleSlideChange} carouselId={10} ref={sliderRef} />
        ) : (
          <MobileCarousel onSlideChange={handleSlideChange} carouselId={10} />
        )}
        <div className="indicatorContainer">
          <ScrollNumberIndicator count={3} activeNumber={activeGroup} fontSize={12} onNumberClick={handleNumberClick} color="black" />
        </div>
      </div>
    </>
  );
};
